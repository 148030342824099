import { isAction, Middleware, PayloadAction } from "@reduxjs/toolkit";
import firebase from "firebase";
import { appFirebase } from "../../database";
import { firestoreCollections } from "../../database/collections";
import {
  BaseUser,
  createGuest,
  DBOrganization,
  DBUser,
  UserType,
} from "../../models/users";
import { IDed, unwrapSingularSnapshotForThunk } from "../../utils/database";
import { createSliceWithThunks } from "../../utils/redux";
import type { AppDispatch, RootState } from "../store";
import { metricsSlice } from "./metrics";

export interface AuthState {
  attempts: { count: number; last: number };
  user: BaseUser;
  loading: boolean;
  error?: any;
  userFacingError?: string;
}
const initialState: AuthState = {
  attempts: { count: 0, last: 0 },
  user: createGuest(),
  loading: false,
  error: undefined,
  userFacingError: undefined,
};

export type Credentials = PasswordCredentials | TokenCredentials;
export interface PasswordCredentials {
  username: string;
  password: string;
}
export interface TokenCredentials {
  token: string;
}

const MINIMUM_TIME_BETWEEN_ATTEMPTS = 1000;

export const authSlice = createSliceWithThunks({
  name: "auth",
  initialState,
  reducers: (create) => ({
    classicActionReducer: create.reducer(
      (state, action: PayloadAction<any>) => {
        //
      }
    ),

    /**
     * Log in to Firebase Authentication. Note that this doesn't necessarily
     * encompass all of the steps of logging in one might associate with the
     * client app -- see the "downstream" actions triggered in Auth Middleware
     * for more detail.
     */
    login: create.asyncThunk(
      async (c: Credentials, thunkApi) => {
        let loginResponse: firebase.auth.UserCredential;
        if ("username" in c) {
          const { username, password } = c;
          loginResponse = await appFirebase
            .auth()
            .signInWithEmailAndPassword(username, password);
        } else {
          const { token } = c;
          loginResponse = await appFirebase.auth().signInWithCustomToken(token);
        }
        return loginResponse.user?.toJSON() as firebase.User;
      },
      {
        pending: (state) => {
          if (
            Date.now() - state.attempts.last <
            MINIMUM_TIME_BETWEEN_ATTEMPTS
          ) {
            throw new Error(
              `Minimum time between login attempts must be at least ${
                MINIMUM_TIME_BETWEEN_ATTEMPTS / 1000
              }s`
            );
          }
          state.loading = true;
          state.attempts.count += 1;
          state.attempts.last = Date.now();
        },
        rejected: (state, action) => {
          state.error = action.payload ?? action.error;
        },
        fulfilled: (state, { payload }: PayloadAction<firebase.User>) => {
          if (payload === null) {
            console.error(`Login failed but treated as success.`);
            console.error({ payload });
            state.error = `API returned login fulfilled but no User data`;
          } else {
            // successful login
            state.user = {
              name: "",
              email: payload.email ?? "no server email!",
              id: payload.uid,
              organizationId: "",
              type: UserType.InRetrieval,
            };
          }
        },
        settled: (state, action) => {
          state.loading = false;
        },
      }
    ),

    logout: create.asyncThunk(
      async (arg: { reload?: boolean }, thunkApi) => {
        const logoutResponse = await appFirebase.auth().signOut();
        return logoutResponse;
      },
      {
        fulfilled: (state, action) => {
          state.user = createGuest();
          if (action.meta.arg.reload) {
            // we push the reload onto the task queue because that allows other
            // parts of the system to react to logout first
            window.setTimeout(() => window.location.reload(), 100);
          }
        },
        rejected: (state, action) => {
          state.error = action.error || action.payload;
        },
      }
    ),

    /**
     * Get the user data associated with a particular user ID.
     */
    retrieveUserData: create.asyncThunk(
      async (userId: string, thunkApi) => {
        const userDataResponse = await firestoreCollections.users
          .doc(userId)
          .get();
        return unwrapSingularSnapshotForThunk(userDataResponse, "user");
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          debugger;
          state.error = action.payload ?? action.error;
        },
        fulfilled: (state, { payload }: PayloadAction<DBUser & IDed>) => {
          state.user = payload;
        },
        settled: (state, action) => {
          state.loading = false;
        },
      }
    ),

    /**
     * Get the organization data associated with a particular organization ID.
     */
    retrieveOrganizationData: create.asyncThunk(
      async (orgId: string, thunkApi) => {
        const userDataResponse = await firestoreCollections.organizations
          .doc(orgId)
          .get();
        return unwrapSingularSnapshotForThunk(userDataResponse, "organization");
      },
      {
        pending: (state) => {},
        rejected: (state, action) => {
          state.user.organization = undefined;
        },
        fulfilled: (
          state,
          { payload }: PayloadAction<DBOrganization & IDed>
        ) => {
          state.user.organization = payload;
        },
        settled: (state, action) => {},
      }
    ),

    // This is now simply handled by login!
    // startKioskMode: create.asyncThunk(
    //   async (
    //     {
    //       kioskUserId,
    //       organization,
    //     }: { kioskUserId: string; organization: Organization },
    //     thunkApi
    //   ) => {
    //     // const tokenResponse = await appFirebase.fn.httpsCallable("/start-kiosk")()
    //     // if (tokenResponse.data.token) {
    //     //   const authResponse = await appFirebase.auth().signInWithCustomToken(tokenResponse.data.token);
    //     //   return authResponse.user?.toJSON();
    //     // }
    //     return {
    //       id: kioskUserId,
    //       type: UserType.PatientKiosk,
    //       name: "Kiosk User",
    //       email: "",
    //       organization,
    //       organizationId: organization.id,
    //     } as BaseUser;
    //   },
    //   {
    //     pending: (state, action) => {
    //       if (!state.user.organization) {
    //         throw new Error(
    //           `Cannot start kiosk mode when organization is not properly loaded`
    //         );
    //       }
    //       if (!state.user.organization.allowsKioskMode) {
    //         throw new Error(
    //           `The current organzation ${state.user.organizationId} does not support kiosk mode!`
    //         );
    //       }
    //     },
    //     fulfilled: (state, action) => {
    //       state.user = action.payload;
    //     },
    //     rejected: (state, action) => {
    //       console.error(action.error ?? "Unknown kiosk mode failure");
    //       state.error = action.error;
    //     },
    //   }
    // ),
  }),
});

/**
 * The auth middleware is primarily used to trigger auth actions that should
 * happen immediately before or after other behaviors.
 */
export const authMiddleware: Middleware = ({
  getState,
  dispatch,
}: {
  getState: () => RootState;
  dispatch: AppDispatch;
}) => {
  return (next: any) => (action: any) => {
    const rootState = getState() as RootState;
    const authState = rootState.auth;
    if (isAction(action)) {
      /**
       * If we have succeeded at logging in to Firebase Authentication, we
       * should immediately retrieve the user's information from Firestore.
       * We *could* wait until this is necessary, but it's needed quite quickly
       * for the vast majority of use cases, so it's better to just have ASAP.
       */
      if (authSlice.actions.login.fulfilled.match(action)) {
        if (action.payload) {
          setTimeout(() => {
            dispatch(authSlice.actions.retrieveUserData(action.payload.uid));
          }, 100);
        } else {
          console.warn(
            "Not retrieving user data since login fulfullment had empty user"
          );
        }
      }

      /**
       * Subsequently, one we grab User data, we want to populate it with a few
       * linked documents:
       * - Organization, if any
       */
      if (authSlice.actions.retrieveUserData.fulfilled.match(action)) {
        switch (action.payload.type) {
          case UserType.PatientKiosk:
            dispatch(metricsSlice.actions.startNewMetricsSession({sessionType: "kiosk"}));
            break;
          case UserType.Provider:
          case UserType.Superadmin:
            dispatch(metricsSlice.actions.startNewMetricsSession({sessionType: "provider"}));
            break;
        }

        if (action.payload?.organizationId) {
          dispatch(
            authSlice.actions.retrieveOrganizationData(
              action.payload.organizationId
            )
          );
        } else {
          console.warn(
            "Not retrieving organization data since user fulfullment had empty org ID"
          );
        }
      }
    }

    // default
    next(action);
  };
};
