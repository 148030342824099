// ************************ Renders Report Labels ************************
// Fixed: Some report labels are not rendering, for example: "surgery categories" and "medications or supplements taken" - Fix: Ensure that all questions have a report label defined.
// TODO: Looped questions contain incorrect reportLabels & we want the specific selection to appear after the question Id rather than the "section" symbol and number. Need to think how to represent on report.
// Anatomical model missing answer choices, or has incorrect reportLabels and table entries

import moment from "moment";
import {
  GeneralAnswer,
  MultiValuedAnswer,
  NonconformingValueKind,
  SingleValuedAnswer,
} from "../../models/answers";
import { CoreDataType } from "../../models/core-data-types";
import { ResponseLayout } from "../../models/layouts";
import { NumberAndUnitType } from "../../models/measurements";
import { QuestionDefinition } from "../../models/questions";
import { capitalizeByWord, oneline, snakeToLowercase } from "../../utils";
import { useBooleanState } from "../../utils/hooks";
import { JSObjectDump } from "../../utils/UtilComponents";

const DEFAULT_LABEL_CLASS = "report-label";
const DEFAULT_VALUE_CLASS = "report-entry-text";

// Consider renaming this component to "ReportLabel" for easier understanding.
export const ReportQuestionLabel = ({
  label,
  question,
  style,
  answerId,
  className = DEFAULT_LABEL_CLASS,
}: {
  label?: string;
  question?: QuestionDefinition;
  style?: React.CSSProperties;
  answerId?: string;
  className?: string;
}) => {
  // if a reportlabel is missing due to question defintion not having a report label defined, please console.log the question and answerId but we should resolve this in the JSON spreadsheet converter.
  return (
    <div {...{ className }} style={style} title={answerId}>
      {label ??
        question?.reportLabel ??
        (question ? (
          capitalizeByWord(snakeToLowercase(question?.id))
        ) : answerId ? ( // This is a fallback for when the question is undefined, but we don't want this for most questionnaire items, please fix. Note: Chief Complaint is redundant.
          <span style={{ color: "red" }}>
            {capitalizeByWord(snakeToLowercase(`${answerId}`))}
          </span>
        ) : (
          ""
        ))}
    </div>
  );
};

// ************************ Handles Freeform, Skipped & other Exclusive Response Entries ************************
export function ReportNonconformingValues({
  answer,
  showEmpty = false,
}: {
  answer?: GeneralAnswer;
  showEmpty?: boolean;
}) {
  if (Array.isArray(answer?.nonconformingValues)) {
    return (
      <ul className="nonconforming-list">
        {answer.nonconformingValues.map((ncv, i) =>
          ncv.value || showEmpty ? (
            <li
              key={i}
              className={
                ncv.kind === NonconformingValueKind.UserDefined
                  ? "user-defined"
                  : "ncr-choice"
              }
            >
              {ncv.value ?? "«Empty input»"}
            </li>
          ) : ncv.kind === NonconformingValueKind.InactiveComputation ? (
            <em>N/A</em>
          ) : null
        )}
      </ul>
    );
  } else {
    return null;
  }
}

// ************************ Handles Report Entries by Response Type ************************
export const ReportTimestampAnswer = ({ answer }) => {
  return (
    <>
      {moment(answer?.value?.value).format("YYYY-MM-DD HH:mm")}
      <ReportNonconformingValues answer={answer} />
    </>
  );
};

export const ReportCalendarDateAnswer = ({ answer }) => {
  return (
    <>
      {moment(answer?.value?.value).format("YYYY-MM-DD")}
      <ReportNonconformingValues answer={answer} />
    </>
  );
};

export const ReportMeasurementAnswer = ({ answer }) => {
  return (
    <span className="report-value-measurement">
      {(answer as MultiValuedAnswer<NumberAndUnitType>).values?.map((v, i) => (
        <>
          {i === 0 ? null : <span className="unit-separator">•</span>}
          <span className="unit-joined">
            {v.value} {v.unit}
          </span>
        </>
      ))}
      <ReportNonconformingValues answer={answer} />
    </span>
  );
};

export const ReportPseudoMeasurementAnswer = ({ answer }) => {
  // console.log("PseudoMeasurementAnswerReportEntry", {answer})
  return (
    <span className="report-pseudo-measurement">
      {(answer as MultiValuedAnswer<NumberAndUnitType>).values?.map((v, i) => (
        <>
          {i === 0 ? null : <span className="unit-separator">•</span>}
          <span className="unit-joined">
            {v.value} {v.unit}
          </span>
        </>
      ))}
      {answer.value ? (
        <span className="unit-joined">
          {answer.value.value} {answer.value.unit}
        </span>
      ) : null}
      <ReportNonconformingValues answer={answer} />
    </span>
  );
};

export const ReportMultiChoiceAnswer = ({
  answer,
  children,
}: {
  answer: GeneralAnswer;
  children?: React.ReactNode;
}) => {
  return (
    <span className={`report-multi-choice`}>
      {answer?.values?.map((v) => (
        <p>{v?.value}</p>
      ))}
      <ReportNonconformingValues answer={answer} />
      {children}
    </span>
  );
};
export const ReportSingleTextlikeAnswer = ({
  answer,
  children,
  isUserDefined = false,
}: {
  answer: GeneralAnswer;
  children?: React.ReactNode;
  isUserDefined?: boolean;
}) => {
  return (
    <span
      className={`report-single-textlike ${
        isUserDefined ? "user-defined" : ""
      }`}
    >
      {answer?.value?.value}
      <ReportNonconformingValues answer={answer} />
      {children}
    </span>
  );
};

export const ReportErrorExpander = ({
  title = "Error",
  description = "There was an issue with this answer",
  object = {},
}) => {
  const open = useBooleanState(false);
  return (
    <>
      <span
        title={`Error (${title}): click to expand`}
        style={{
          filter: "saturate(0.5)",
          opacity: 0.5,
          float: "right",
          lineHeight: 0,
          top: -8,
          position: "relative",
        }}
        onClick={open.toggle}
      >
        🪲
      </span>
      {open.value ? (
        <div style={{ color: "rgb(84 15 15)" }}>
          <hr style={{ opacity: 0.2 }} />
          <p>
            <strong>{title}</strong>
            &mdash;
            {description}
          </p>
          <JSObjectDump obj={object} />
        </div>
      ) : null}
    </>
  );
};

// ************************ Renders Every Single Answer Entry ************************
// This function represents the smallest (atomic) unit of an answer entry in a report.
// I suggest rename to "SingleAnswerReportEntry" or "UnaryAnswerReportEntry" for easier understanding.
export const ReportEntryByAnswerType = ({
  answer,
  answerId,
  question,
  label,
  showEmpty,
  labelClass = DEFAULT_LABEL_CLASS,
  valueClass = DEFAULT_VALUE_CLASS,
}: {
  answer?: GeneralAnswer;
  answerId?: string;
  question?: QuestionDefinition;
  label?: string;
  showEmpty?: boolean;
  labelClass?: string;
  valueClass?: string;
}) => {
  // console.log('AtomicAnswerReportEntry props:', { answer, answerId, question, label, showEmpty });
  const hasContent =
    !!answer &&
    ((answer.nonconformingValues ?? []).length > 0 ||
      (answer.isMulti
        ? answer.values?.length > 0
        : !["", null, undefined].includes(answer.value?.value)));

  // This doesn't seem to catch all non-existent entries (e.g., anatomical model selections) - please check if this is working as expected
  if (!hasContent) {
    if (showEmpty) {
      return (
        <>
          <td>
            <ReportQuestionLabel
              className={labelClass}
              label={label}
              question={question}
              answerId={answerId}
            />
          </td>
          <td className={valueClass} style={{ fontStyle: "italic" }}>
            --
          </td>
        </>
      );
    } else {
      console.log(`Skipping ${answerId}, no value`);
      return null;
    }
  }

  const renderValue = () => {
    // TODO: handle the issue of undefined questions - at least so that the reportLabels are pre-defined and not taken from the answerId [mostly loop, refined (e.g., CC), and anatomical model questions]
    // if (!question) {
    //   console.log('Question is undefined for answerId:', answerId);
    // }

    switch (answer?.coreType ?? question?.coreType) {
      case CoreDataType.Timestamp:
        return <ReportTimestampAnswer answer={answer} />;
      case CoreDataType.CalendarDate:
        return <ReportCalendarDateAnswer answer={answer} />;
      case CoreDataType.Measurement:
        return <ReportMeasurementAnswer answer={answer} />;
      default:
        if (question?.layout === ResponseLayout.PseudoMeasurement) {
          return <ReportPseudoMeasurementAnswer answer={answer} />;
        }
        if (question?.isMulti) {
          if (answer?.isMulti) {
            return <ReportMultiChoiceAnswer answer={answer} />;
          } else {
            return (
              <ReportSingleTextlikeAnswer answer={answer}>
                <ReportErrorExpander
                  title="Expected multi-select"
                  description="The questionnaire has changed since these answers were provided and the answer type does not match perfectly."
                  object={{ answer, question }}
                />
              </ReportSingleTextlikeAnswer>
            );
          }
        }
        // Question is not defined for looped questions, we need to fix this so that each report entry has the correct report label.
        if (!question) {
          console.log("Question is undefined for answerId:", answerId, answer);
          // debugger;
          return (
            <span style={{ color: "red" }}>
              {answer?.value?.value}
              {(answer as MultiValuedAnswer<NumberAndUnitType>).values?.map(
                (v, i) => (
                  <>
                    {i === 0 ? null : <span className="unit-separator">•</span>}
                    <span className="unit-joined">
                      {v.value} {v.unit}
                    </span>
                  </>
                )
              )}
            </span>
          );
        }
        if (answer.isMulti) {
          return (
            <ReportMultiChoiceAnswer answer={answer}>
              <ReportErrorExpander
                title="Expected single-select"
                description="The questionnaire has changed since these answers were provided and the answer type does not match perfectly."
                object={{ answer, question }}
              />
            </ReportMultiChoiceAnswer>
          );
        }
        const showAsUserDefined =
          question?.layout === ResponseLayout.ShortAnswer &&
          answer?.value?.value?.length > 0;
        return (
          <ReportSingleTextlikeAnswer
            answer={answer}
            isUserDefined={showAsUserDefined}
          />
        );
    }
  };

  // padding: "5px", borderBottom: "1px solid rgba(0,0,0,0.1)"
  return (
    <>
      <td className={answer?.isComputed ? "report-computed-row" : ""}>
        <ReportQuestionLabel
          className={labelClass}
          label={label}
          question={question}
          answerId={answerId}
        />
      </td>
      <td className={answer?.isComputed ? "report-computed-cell" : ""}>{renderValue()}</td>
    </>
  );
};

export const ReportTextEntry = ({
  text,
  label,
  noLabel,
  usePrimaryColor,
  customStyles,
  children,
}) => {
  if (noLabel) {
    return <div className="report-entry-full">{children ?? text}</div>;
  }
  return (
    <td style={{ padding: "5px", borderBottom: "1px solid rgba(0,0,0,0.1)" }}>
      <ReportQuestionLabel
        label={label}
        style={{
          color: usePrimaryColor ? "var(--evergreen)" : "auto",
          ...customStyles,
        }}
      />
      <div className="report-entry-text">{children ?? text}</div>
    </td>
  );
};
