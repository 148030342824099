import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoginComponent } from "./components/Auth/Login";
import { ProviderDashboard } from "./components/Provider/Dashboard";
import { LanguageSelectorPage } from "./components/Questionnaire/Pages/LanguageSelector";
import { TitlePage } from "./components/Questionnaire/Pages/TitlePage";
import { NavigationalOverlay } from "./components/Questionnaire/Partials/NavigationalOverlay";
import { RedesignedQuestionnaire } from "./components/Questionnaire/QuestionnaireFlow";
import { QuestionnaireManager } from "./components/QuestionnaireManager";
import { SimulatorSettingsPanel } from "./components/SettingsPanel";
import { IS_PROD, IS_QA } from "./constants/app";
import {
  dualName,
  Language,
  LanguageEndonym,
  LanguageISO,
  LanguageList,
} from "./constants/locales";
import {
  generateQuestionnaireKind,
  ScreeningType,
} from "./constants/screenings";
import { PageType, RootPageFlow } from "./models/pages";
import { UserType } from "./models/users";
import { answerSetSlice } from "./store/slices/answerSets";
import { prepareSafari } from "./store/slices/audio";
import { authSlice } from "./store/slices/auth";
import { metricsSlice, useTabActivity } from "./store/slices/metrics";
import { patientFlowSlice } from "./store/slices/patient-flow";
import { requestQuestionnaire } from "./store/slices/questionnaire-old";
import { simulatorSlice } from "./store/slices/simulator";
import "./styles/redesign/admin-console.css";
import "./styles/redesign/bootstrap-highlighter.css";
import "./styles/redesign/button.css";
import "./styles/redesign/colors.css";
import "./styles/redesign/debugging.css";
import "./styles/redesign/flow.css";
import "./styles/redesign/typography.css";
import "./styles/redesign/utility.css";
import { randHexPair } from "./utils";
import {
  useAppDispatch,
  useAppSelector,
  useKeyboardEvents,
  useQuery,
} from "./utils/hooks";

function randomDarkColor(): string {
  const a = randHexPair(0, 60);
  const b = randHexPair(0, 120);
  const c = randHexPair(80, 180);
  return (
    "#" +
    (Math.random() > 0.67
      ? a + b + c
      : Math.random() > 0.5
      ? b + c + a
      : c + a + b)
  );
}

const HARDCODED_BUTTON_STATE_FOR_LANG = {
  next: {
    visible: false,
    active: false,
  },
  back: {
    visible: false,
    active: false,
    icon: "quit",
  },
  audio: {
    lockPlaying: true,
  },
  callToAction: null,
};

const HARDCODED_LANGUAGES_AVAILABLE_ALL = [
  Language.English,
  Language.Kinyarwanda,
  Language.Spanish,
];
const ADDITIONAL_LANGUAGES_FOR_EXPANDED = [Language.Arabic];
const HARDCODED_LANGUAGES_EXPANDED = [
  ...HARDCODED_LANGUAGES_AVAILABLE_ALL,
  ...ADDITIONAL_LANGUAGES_FOR_EXPANDED,
];

const App = (props: any) => {
  const dispatch = useAppDispatch();
  /**
   * Temporary page routing until we commit to full router, which may not be
   * before launch. (Most of our navigation is already handled by the
   * questionnaire state management, so adding another router might just be
   * unnecessary complexity until we architect it.)
   */
  const [currentFlow, setCurrentFlow] = useState<RootPageFlow>(
    RootPageFlow.Guest
  );

  useTabActivity((active) => {
    // Weakly-plausible way to try to prevent the "PWA Keyboard Glitch" on iPad.
    // I don't really think the timing is going to work right, but the goal is
    // to never have a form field focused when the PWA is in the background to
    // prevent any animation rendering from gumming up the UI.
    if (
      navigator.platform.startsWith("iP") ||
      (navigator.platform.startsWith("Mac") && navigator.maxTouchPoints > 4)
    ) {
      if (!active) {
        (document.activeElement as HTMLElement)?.blur();
      }
    }
  });

  useEffect(() => {
    let touchStartCoords: [number, number] = [-1, -1];
    let activeTouchId: number = -1;
    const recordStart = (te: TouchEvent) => {
      if (te.touches.length === 0) {
        return console.error(`Touchless touchstart event`);
      }
      if (activeTouchId === -1) {
        const newTouch = te.touches.item(0)!!;
        touchStartCoords = [newTouch.clientX, newTouch.clientY];
        activeTouchId = newTouch.identifier;
      }
    };
    const detectGesture = (te: TouchEvent) => {
      if (activeTouchId === -1) return;
      for (let i = 0; i < te.changedTouches.length; i++) {
        const endedTouch = te.changedTouches.item(i)!!;
        if (endedTouch.identifier === activeTouchId) {
          const deltaX = endedTouch.clientX - touchStartCoords[0];
          const deltaY = endedTouch.clientY - touchStartCoords[1];
          const vertical8ths = Math.abs(deltaY) > 2 * Math.abs(deltaX);
          const horizonal8ths = Math.abs(deltaX) > 2 * Math.abs(deltaY);
          if (vertical8ths && deltaY > 200) {
            console.log(`Large downward swipe!`);
            // example gesture detection replicating a very naive version of the
            // pull-down-to-refresh logic
          }
          console.log({ deltaX, deltaY });
          activeTouchId = -1;
          return;
        }
      }
    };
    window.addEventListener("touchstart", recordStart);
    window.addEventListener("touchend", detectGesture);
    return () => {
      window.removeEventListener("touchstart", recordStart);
      window.removeEventListener("touchend", detectGesture);
    };
  }, []);

  useEffect(() => {
    function captureClickForSafariAudioHack() {
      console.warn("CLICK CAPTURED");
      prepareSafari();
      window.removeEventListener("click", captureClickForSafariAudioHack);
    }
    window.addEventListener("click", captureClickForSafariAudioHack);
    return () =>
      window.removeEventListener("click", captureClickForSafariAudioHack);
  }, []);

  const currentUser = useAppSelector((s) => s.auth.user);
  const authLoading = useAppSelector((s) => s.auth.loading);
  const showExpandedLanguages =
    useAppSelector((s) => s.simulator.showExpandedLanguages) || IS_QA;

  // const loadedQuestionnaireIDs = useAppSelector((s) => s.definitions.questionnaires.ids);
  const loadedQuestionnaireIDs = useAppSelector(
    (s) => s.questionnaire.questionnaires.ids
  );

  const needsIntroductionPage = useAppSelector(
    (s) => s.patientFlow.needsIntroductionPage
  );
  function dismissIntroduction() {
    dispatch(patientFlowSlice.actions.dismissIntro(false));
  }

  const chosenLanguage = useAppSelector((s) => s.patientFlow.language);
  const qType = generateQuestionnaireKind({
    type: ScreeningType.ED,
    locale: { language: chosenLanguage },
  });
  const qTypeIsReady = loadedQuestionnaireIDs.includes(qType);
  function clearLanguage() {
    dispatch(patientFlowSlice.actions.selectLanguage(Language.UNDETERMINED));
  }

  useEffect(() => {
    if (currentFlow === RootPageFlow.Guest && !authLoading) {
      if (
        currentUser.type === UserType.Provider ||
        currentUser.type === UserType.Superadmin
      ) {
        setCurrentFlow(RootPageFlow.Provider);
      } else if (currentUser.type === UserType.PatientKiosk) {
        setCurrentFlow(RootPageFlow.PatientKiosk);
        const toLoad = window.sessionStorage.getItem("loadAnswerSet");
        if (toLoad) {
          dispatch(answerSetSlice.actions.loadAnswersForResumedSession(toLoad));
          window.sessionStorage.removeItem("loadAnswerSet");
        }
      } else {
        if (currentUser.type === UserType.Unauthenticated) {
          // maybe find a token?
          const token = window.sessionStorage.getItem("authToken");
          if (token) {
            console.log(`token found in session storage, attempting login!`);
            dispatch(authSlice.actions.login({ token }));
            window.sessionStorage.removeItem("authToken");
          }
        }
      }
    } else if (
      currentFlow === RootPageFlow.Provider &&
      currentUser.type === UserType.PatientKiosk
    ) {
      setCurrentFlow(RootPageFlow.PatientKiosk);
    } else if (currentUser.type === UserType.Unauthenticated) {
      setCurrentFlow(RootPageFlow.Guest);
    }
  }, [currentUser, authLoading]);

  useEffect(() => {
    if (currentFlow === RootPageFlow.PatientKiosk) {
      if (!qTypeIsReady && chosenLanguage !== Language.UNDETERMINED) {
        dispatch(
          requestQuestionnaire({
            type: ScreeningType.ED,
            locale: { language: chosenLanguage },
          })
        );
      }
    }
  }, [currentFlow, qTypeIsReady, chosenLanguage]);

  const [showSettingsPanel, setShowSettingsPanel] = useState<boolean>(false);
  function debugButton(e: KeyboardEvent) {
    if (currentFlow === RootPageFlow.PatientKiosk) {
      clearLanguage();
    } else {
      setShowSettingsPanel((x) => !x);
    }
  }
  useKeyboardEvents(["F4", debugButton]);

  function quitKiosk(fullRefresh: boolean = true) {
    console.log("quitting kiosk");
    if (fullRefresh) {
      // for a "full refresh" we just reload the page
      dispatch(patientFlowSlice.actions.allowReload());
      setTimeout(() => window.location.reload(), 50);
    } else {
      dispatch(
        patientFlowSlice.actions.fullReset({ isEarly: false, reason: "flow" })
      );
      dispatch(patientFlowSlice.actions.selectLanguage(Language.UNDETERMINED));
      dispatch(
        metricsSlice.actions.startNewMetricsSession({ sessionType: "kiosk" })
      );
    }
  }

  const s: React.CSSProperties = {
    margin: "4em auto",
    textAlign: "center",
    color: randomDarkColor(),
    fontWeight: "bold",
  };

  const [englishOverride, setEnglishOverride] = useState<boolean>(false);
  function toggleEnglish() {
    if (englishOverride) {
      i18n.changeLanguage(chosenLanguage);
      setEnglishOverride(false);
    } else {
      i18n.changeLanguage(Language.English);
      setEnglishOverride(true);
    }
  }

  const { t, i18n } = useTranslation();

  /**
   * >>>>>>>> BIG HACK FOR LANGUAGE EXPERT QA MODE <<<<<<<<<<<
   */
  const query = useQuery();
  const SPECIAL_REVIEW_PARAMETER = "flatreview";
  const [reviewMode, setReviewMode] = useState<LanguageISO | null>(null);
  useEffect(() => {
    if (IS_PROD) return; // BAIL OUT IF THIS IS PRODUCTION!!

    if (query.has(SPECIAL_REVIEW_PARAMETER)) {
      const reviewLanguage = query.get(
        SPECIAL_REVIEW_PARAMETER
      ) as LanguageISO | null;
      if (reviewLanguage && LanguageList.includes(reviewLanguage)) {
        setReviewMode(reviewLanguage);
        dispatch(patientFlowSlice.actions.selectLanguage(reviewLanguage));
        dispatch(
          simulatorSlice.actions.changeSimulatorSettings({
            exhaustiveLoops: true,
            navLogicEnabled: false,
            noDisableProgress: true,
            conditionalChoicesEnabled: false,
          })
        );
        setCurrentFlow(RootPageFlow.PatientKiosk);
      } else {
        alert(
          !reviewLanguage
            ? `You must specify a 2-letter language code for parameter ${SPECIAL_REVIEW_PARAMETER}`
            : `Language code ${reviewLanguage} not supported! Allowed: [${LanguageList.join(
                ", "
              )}]`
        );
      }
    }
  }, []);

  const pageNumber = useAppSelector((s) => s.patientFlow.pageNumber);

  return (
    <>
      {currentFlow === RootPageFlow.Guest ? (
        <div
          className={`redesign page-login`}
          style={{ background: "var(--mint-green)" }}
        >
          <LoginComponent tokenMode={false} />
        </div>
      ) : null}
      {currentFlow === RootPageFlow.Simulator ? <QuestionnaireManager /> : null}
      {currentFlow === RootPageFlow.Provider ? (
        <div
          className={`redesign page-login`}
          style={{ background: "var(--mint-green)" }}
        >
          {showSettingsPanel ? (
            <div
              className="centered-flex-container force-full-height"
              style={{ justifyContent: "center" }}
            >
              <div
                className="raised-box"
                style={{ maxWidth: "1000px", margin: "3em auto" }}
              >
                <h2>Debug Settings</h2>
                <h4 style={{ opacity: 0.6 }}>Press F4 again to return</h4>
                <SimulatorSettingsPanel showLoadOptions={false} />
              </div>
            </div>
          ) : (
            <ProviderDashboard />
          )}
        </div>
      ) : null}
      {currentFlow === RootPageFlow.PatientKiosk ? (
        needsIntroductionPage ? (
          <div
            className={`redesign page-${PageType.LanguageSelector} has-backdrop`}
            style={{ background: "var(--sky-blue)" }}
          >
            <NavigationalOverlay
              page={{}}
              subpage={{}}
              mode={"no-art"}
              // artOverride={"/images/asking_doctor_questions_fm.png"}
              activeQuestion={undefined}
              activeAnswer={null}
              buttonState={HARDCODED_BUTTON_STATE_FOR_LANG}
              hideFade={false}
              goBackExit={() => {}}
              goForward={() => {}}
              longPress={() => {}}
              useNeutralColor={true}
            />
            {/* disabledLanguages={LanguageList.filter(l => !allows(ScreeningType.ED, l))} */}
            <TitlePage
              type={PageType.KioskStart}
              title="Questionnaire is ready"
              style="cool"
              description="Click start and pass the device to the patient."
              art="/images/general_health_onboarding_get_better_care_fm.png"
              callToAction={pageNumber > 0 ? `Continue with page ${pageNumber}` : "Start"}
              actOnCTA={dismissIntroduction}
            />
          </div>
        ) : chosenLanguage === Language.UNDETERMINED && !reviewMode ? (
          <div
            className={`redesign page-${PageType.LanguageSelector} has-backdrop`}
          >
            <NavigationalOverlay
              page={{}}
              subpage={{}}
              mode={"backdrop"}
              artOverride={"/images/asking_doctor_questions_fm.png"}
              activeQuestion={undefined}
              activeAnswer={null}
              buttonState={HARDCODED_BUTTON_STATE_FOR_LANG}
              hideFade={false}
              goBackExit={() => {}}
              goForward={() => {}}
              longPress={() => {}}
              useNeutralColor={false}
            />
            {/* disabledLanguages={LanguageList.filter(l => !allows(ScreeningType.ED, l))} */}
            <LanguageSelectorPage
              listedLanguages={
                showExpandedLanguages
                  ? HARDCODED_LANGUAGES_EXPANDED
                  : HARDCODED_LANGUAGES_AVAILABLE_ALL
              }
              warningLanguages={ADDITIONAL_LANGUAGES_FOR_EXPANDED}
              // disabledLanguages={LanguageList.filter(l => !HARDCODED_LANGUAGES_AVAILABLE.includes(l))}
            />
          </div>
        ) : !qTypeIsReady ? (
          <div style={s}>
            kiosk is preparing...
            <br />
            <em style={{ opacity: 0.5 }} onClick={clearLanguage}>
              (unselect language)
            </em>
          </div>
        ) : (
          <RedesignedQuestionnaire
            reportType={ScreeningType.ED}
            language={chosenLanguage}
            quitFn={quitKiosk}
          />
        )
      ) : null}
      {/* Components universal across flows go here: */}
      {reviewMode ? (
        <div
          style={{
            height: 22,
            fontSize: 12,
            opacity: 0.5,
            width: "100%",
            position: "absolute",
            bottom: 0,
            textAlign: "center",
          }}
        >
          Currently reviewing language {dualName(reviewMode)} -- page logic
          disabled.
          {chosenLanguage !== Language.English ? (
            <button
              onClick={toggleEnglish}
              style={{ padding: "2px 6px", backgroundColor: "#ecce3070" }}
            >
              Switch to{" "}
              {englishOverride ? LanguageEndonym[chosenLanguage] : "English"}
            </button>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default App;
