import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/analytics";
import { _switch } from "../../utils";

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

const firebaseConfig: Partial<FirebaseConfig> = (
  process.env.REACT_APP_STAGE === "prod" ?
  {
    apiKey: process.env.REACT_APP_PROD_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_PROD_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_PROD_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_PROD_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_PROD_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_PROD_FIREBASE_MSG_SENDER_ID,
    appId: process.env.REACT_APP_PROD_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_PROD_FIREBASE_MEASUREMENT_ID,
  }
  :
  {
    apiKey: process.env.REACT_APP_QA_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_QA_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_QA_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_QA_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_QA_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_QA_FIREBASE_MSG_SENDER_ID,
    appId: process.env.REACT_APP_QA_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_QA_FIREBASE_MEASUREMENT_ID,
  }
);

export const initializeFirebase = () => {
  const app = firebase.initializeApp(firebaseConfig);
  const out = {
    auth: firebase.auth,
    db: firebase.firestore(),
    fn: firebase.functions(),
    storage: firebase.storage(),
    firebase,
    analytics: {}
  };
  firebase.analytics.isSupported().then(supported => {
    if (supported) {
      out.analytics = firebase.analytics();
    } else {
      console.warn("Analytics is not supported in this environment, omitting.");
    }
  });
  return out;
};
