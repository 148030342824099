import { PageType } from "./pages";

export enum MetricsEvent {
  AppLaunch = "app/launch",
  Login = "session/login",
  Logout = "session/logout",
  ViewDashboardReport = "provider/view-report-from-dashboard",
  StartKioskMode = "session/kiosk-init",
  KioskSelectLanguage = "kiosk/language",
  KioskProvideConsent = "kiosk/consent",
  PageForward = "page/advance",
  PageBackward = "page/backtrack",
  PageJump = "page/jump",
  AnswerFirst = "answer/first-interaction",
  AnswerLast = "answer/last-interaction",
  PlayAudio = "question/play-audio",
  ToggleAudio = "question/toggle-audio-autoplay",
  GetMoreInfo = "question/more-info",
  SubmitFeedback = "feedback/submit",
  KioskComplete = "kiosk/complete-patient",
  KioskStartOver = "kiosk/reset-at-end",
  KioskEarlyReset = "kiosk/reset-early",
  EndSession = "session/end",
  TabInactive = "session/tab-inactive",
  TabActive = "session/tab-active"
}

export interface MetricsEventData {
  type: MetricsEvent;
  timestamp: number;
}

export interface MetricsSession {
  initialTimestamp: number;
  sessionId: string;
  error?: any,
  events: MetricsEventData[];
}
// Navigation patterns
export interface PageEventData extends MetricsEventData {
  fromPage: number;
  fromPageType: PageType;
  toPage: number;
  toPageType: PageType;
  navSkipped: number;
  toPageQuestions: string[];
}

export interface AnswerEventData extends MetricsEventData {
  question: string;
}

// ************* OTHER POSSIBLE USER SESSION METRICS *************
// Time spent per question/page
// Drop-off points 
// Completion time
// Edits and changes (number per question)
// Touch/Click Heatmaps
// Scrolling behavior 
// Error rates (such as incomplete required fields or wrong data entry)
// Device and broswer data 
// Skipped questions
// Time taken before selecting an answer 
// Selection of "other", "none", "unsure" options or free form entries
// Load times for pages and components (e.g. audio and image files)
// Multi-session completion rates (how many users complete the questionnaire in one session)
// Network connection status 
// End of session feedback
// In questionnaire feedback (thumbs up/dow)