import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { getAudioLocationFromId } from "../../../models/audio";
import { ResponseChoice } from "../../../models/questions";
import { safeStringify } from "../../../utils";
import { useAppSelector } from "../../../utils/hooks";
import "../questionnaire.css";

function HighlightedKeywords({ text, keywordList, action }) {
  let segments = [text ?? ""];
  for (let [keyword, content] of Object.entries(keywordList)) {
    segments = segments.flatMap((segment) => {
      if (typeof segment === "string") {
        if (segment.includes(keyword)) {
          const start = segment.indexOf(keyword);
          const end = start + keyword.length;
          return [
            segment.slice(0, start),
            { content, label: keyword },
            segment.slice(end),
          ];
        }
      }
      return [segment];
    });
  }
  return (
    <>
      {segments.map((segment, i) =>
        typeof segment === "string" ? (
          <span key={`plaintext:${i}`}>{segment}</span>
        ) : (
          <span
            key={`link:${segment.label}`}
            className="highlight-interactive-text"
            onClick={() => action(segment.label, segment.content)}
          >
            {segment.label}
          </span>
        )
      )}
    </>
  );
}

const GLOBAL_KEYWORDS = { "TEST STRING ": "test complete" };

const SHOW_FORMULAS_DEBUG = false;

function stringifySuperflat(obj, customReplacements) {
  const jsonlikeRemoved = safeStringify(obj, 0).replace(/[[\]"\n,{}:]/g, "");
  if (customReplacements) {
    return jsonlikeRemoved.replace(customReplacements, "");
  } else {
    return jsonlikeRemoved;
  }
}

interface QuestionPromptProps {
  contextualResponse: {
    value: { value: any } & Partial<ResponseChoice>;
  } | null;
}

export default function QuestionPrompt({
  text,
  subtext,
  contextualResponse,
  keywordList,
  id,
  keywordAction,
  translationId,
  subtextTranslationId,
  ...props
}: QuestionPromptProps) {
  const { t } = useTranslation();
  const fullList = { ...GLOBAL_KEYWORDS, ...keywordList };
  const activeAudio = useAppSelector((s) => s.audio.activeAudio);
  function audioIsPlaying(forTitle = true) {
    const location = forTitle
      ? getAudioLocationFromId(translationId ?? "/missing/").filename
      : getAudioLocationFromId(subtextTranslationId ?? "/missing/").filename;
    return !!activeAudio.playing && activeAudio.filename === location;
  }

  /*
   Ideally we can prevent scrolling issues with good structure, but this is our
   backup to ensure we have scrolled to the top as best we can. Since we don't
   know what container is failing, it's easier to ask the browser to put the
   question prompt in the middle of the screen whenever it is rendered. This
   will basically always scroll to the top since the prompt would have to be
   incredibly oversized to fit beyond the vertical halfway point.
  */
  const containerRef = useRef<HTMLDivElement>();
  useEffect(() => {
    if (containerRef.current) {
      console.log("scroll");
      containerRef.current.scrollIntoView({
        block: "center",
        behavior: "instant",
      });
    }
  }, [containerRef.current, id]);

  return (
    <header className="main-column">
      <div
        className={`question ${audioIsPlaying() ? "pulsing" : ""}`}
        ref={containerRef}
      >
        {/* What <span className="highlight-interactive-text">ice-cream flavor</span> is your favorite? */}
        {t(translationId, text ?? "No text available!")}
        {"*" in fullList ? (
          <span
            className="bold-interactive-text"
            onClick={() => keywordAction("*", fullList["*"])}
          >
            {" "}
            Learn more.
          </span>
        ) : null}
        {contextualResponse?.value ? (
          <>
            {"\u2001"}
            <span className="contextual-label">
              {/* <JSObjectDump obj={contextualResponse} style={{fontSize: 12, lineHeight: 1}}/> */}
              {t(
                contextualResponse.value.labelTranslationId,
                contextualResponse.value.value
              ) ?? contextualResponse.value.value}
            </span>
          </>
        ) : null}
      </div>
      {subtext ? (
        <div
          className={`text-muted mb-2 question-subtext ${
            audioIsPlaying(false) ? "pulsing" : ""
          }`}
        >
          {t(subtextTranslationId, subtext ?? "")}
        </div>
      ) : null}
    </header>
  );
}
