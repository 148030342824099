export enum Status {
  // negatives are for error states, generally those that require some sort of
  // fresh start to handle
  InvalidState = -2,
  LoadFailed = -1,

  // the initial state of the app
  Unstarted = 0,

  // Loading & Updated are both states where data is actively being fetched
  Loading = 1, // no existing data
  Updating = 2, // have existing data

  // Ready et all are states where data is usable and current
  Ready = 3,
  ReadyListening = 4, // ready and awaiting live updates
  Complete = 5, // adds the notion of the resource being done (no further updates)
}
