import moment from "moment";
import { KeyboardEvent, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaExclamationTriangle } from "react-icons/fa";
import { PredefinedModels } from "../../constants/anatomical-models";
import { DefaultAudioSequence } from "../../constants/audio";
import { Status } from "../../constants/communication";
import {
  AUTO_ADVANCE_TIMEOUT,
  AUTO_ADVANCE_TYPES,
  COMPLEX_UI_TYPES,
  INACTIVITY_LIMIT,
  INACTIVITY_WARNING_LENGTH,
} from "../../constants/config";
import { Language } from "../../constants/locales";
import { fn } from "../../database";
import { getRootViewOfModel } from "../../models/anatomical-models";
import {
  AnswerSetSubmissionStatus,
  DerivedAnswerKey,
  EMPTY_ANSWER,
  GeneralAnswer,
  isUnanswered,
  MultiValuedAnswer,
  NonconformingValueKind,
  onlyAcceptMultiChoice,
  SingleValuedAnswer,
} from "../../models/answers";
import {
  AudioSequence,
  buildAudioLocationListForQuestion,
} from "../../models/audio";
import {
  CoreDataType,
  IndexedString,
  ValueAtom,
  WrappedOf,
} from "../../models/core-data-types";
import {
  DisplayableEntity,
  DisplayableFormulaInJSON_Simplified,
} from "../../models/formula";
import { ResponseLayout } from "../../models/layouts";
import { SystemOfMeasure } from "../../models/measurements";
import { MetricsEvent, PageEventData } from "../../models/metrics";
import {
  Page,
  PAGE_LOADING,
  PageType,
  QuestionPage,
  TitlePage,
} from "../../models/pages";
import {
  AbstractQuestionDefinition,
  buildAnswerFromComputedFormulaResult,
  ChoiceQuestionDefinition,
  ComputedQuestionDefinition,
  ComputedQuestionFunction,
  isChoiceLike,
  loopIndexedAnswerId,
  QuestionDefinition,
  ResponseChoice,
} from "../../models/questions";
import { answerSetSlice } from "../../store/slices/answerSets";
import { audioSlice, requestOrRegister } from "../../store/slices/audio";
import { QuestionnaireDefinition } from "../../store/slices/definitions";
import { metricsSlice } from "../../store/slices/metrics";
import {
  AnswerUpdatePart,
  patientFlowSlice,
} from "../../store/slices/patient-flow";
import {
  emptyQuestionnaire,
  getQuestionnaireByTypeAndKind,
} from "../../store/slices/questionnaire-old.js";
import { safeStringify, spliceOutOf } from "../../utils";
import { IDed } from "../../utils/database";
import { evaluator } from "../../utils/formulas/evaluator";
import {
  useAppDispatch,
  useAppSelector,
  useKeyboardEvents,
  useSimpleBackGuard,
} from "../../utils/hooks";
import { CTA } from "../UI/buttons/CTA";
import { SmallModalDialog } from "../UI/dialogs/SmallModalDialog";
import { CountdownTimer } from "./CountdownTimer";
import { HybridQuestionSummaryPage } from "./Pages/HybridQuestionSummaryPage";
import { StandardPage } from "./Pages/StandardPage";
import { NavigationalOverlay } from "./Partials/NavigationalOverlay";
import SingleQuestion from "./Partials/SingleQuestion";
import { StandardModalDialog } from "./Partials/StandardModalDialog";
import { HARDCODED_COMPUTATION_FUNCTIONS } from "../../utils/formulas/computed-questions";

/**
 * This is a counter used to give unique labels to otherwise unlabeled formulas
 * so they can be identified and referenced independently.
 */
let unlabeledFormulaCount = 0;

export interface SubpageCount {
  // loopPage: number;
  loopIteration: number;
  loopLength: number;
  loopChoiceIndex: number;
  loopPageCount: number;
  loopPageIndex: number;
  questionStage: number;
  questionStageCount: number;
  infomationStage: number;
}
function subpageless(): SubpageCount {
  return {
    // loopPage: 0,
    loopIteration: -1,
    loopLength: -1,
    loopChoiceIndex: -1,
    loopPageCount: -1,
    loopPageIndex: -1,
    questionStage: 0,
    questionStageCount: 1,
    infomationStage: -1,
  };
}

export interface QuestionnaireFlowModalWindow {
  type: "jump dialog" | "markdown" | "introduction" | "report";
  imgUrl?: string;
  title?: string;
  content?: string | React.ReactNode;
  contentURL?: string | null;
}

// TODO: I attempted to fix this logic in a way that united the contextualArt
// and the contextualResponse values into one place, but the code became a
// mess, so for now I'm just writing the duplicitous code and not worrying
// about it. In the future these two things should be merged. (Note: the most
// important code for contextualResponse is function contextualResponseFor in
// QuestionPage.)
const SpecialCase_SearchByValue: string[] = [
  DerivedAnswerKey.DisambiguatedChiefComplaint,
  DerivedAnswerKey.DetailedChiefComplaint,
  DerivedAnswerKey.DisambiguatedRegionOfProblem,
];

export function contextualChoice(
  contextualKey: string | undefined,
  answers: Record<string, GeneralAnswer>,
  questions: Record<string, QuestionDefinition>,
  loopId: string | undefined,
  loopChoiceIndex: number | undefined
): null | ResponseChoice {
  if (!contextualKey) return null;
  const givenAnswer = answers[contextualKey];
  if (!givenAnswer) return null;
  const relevantValue: ValueAtom | undefined =
    loopId === contextualKey && givenAnswer.isMulti
      ? givenAnswer?.values?.find((v) => v.choiceIndex === loopChoiceIndex)
      : givenAnswer?.value;
  const choiceNum = (relevantValue as IndexedString)?.choiceIndex;
  const questionKey = givenAnswer.questionKey ?? contextualKey;
  const contextualQuestion = questions[questionKey];
  if (!contextualQuestion) {
    console.warn(
      `Found answer for contextualArt, but no matching question '${questionKey}'. Was there a mismatch?`
    );
    return null;
  }
  if (
    !isChoiceLike(contextualQuestion) &&
    !Array.isArray(contextualQuestion.choices)
  ) {
    console.warn(
      `Found question '${questionKey}' for contextualArt, but does not have choices. Was there a mismatch?`
    );
    return null;
  }
  if (relevantValue === undefined) {
    return null;
  }
  if (SpecialCase_SearchByValue.includes(contextualKey)) {
    const found: ResponseChoice | undefined = (
      contextualQuestion as ChoiceQuestionDefinition
    ).choices.find((c) => c.value === relevantValue.value);
    if (!!found) {
      return found;
    } else {
      console.warn(
        `Could not find choice matching value '${relevantValue.value}' in question '${questionKey}' to use for context`
      );
    }
  } else {
    return (
      (contextualQuestion as ChoiceQuestionDefinition).choices[
        choiceNum as number
      ] ?? null
    );
  }
  return null;
}

// todo: update const name
export const RedesignedQuestionnaire = ({ reportType, language, quitFn }) => {
  // TODO: we need a way to toggle whether these values are loaded from the
  // simulator or the real patient flow
  const simulatorLanguage = useAppSelector((s) => s.simulator.language);
  const flowLanguage = useAppSelector((s) => s.patientFlow.language);
  const dualLanguage = useAppSelector((s) => s.simulator.dualLanguage);
  const debugHighlighting = useAppSelector(
    (s) => s.simulator.debugHighlighting
  );
  const showTrackingInfo = useAppSelector((s) => s.simulator.trackingInfo);
  const useNavLogic = useAppSelector((s) => s.simulator.navLogicEnabled);
  const forceExhaustiveLoop = useAppSelector(
    (s) => s.simulator.exhaustiveLoops
  );
  const autoProgression = useAppSelector(
    (s) => s.simulator.autoProgressScalars
  );
  const noDisableProgress = useAppSelector(
    (s) => s.simulator.noDisableProgress
  );

  const { t } = useTranslation();
  function locale() {
    return { language: language ?? simulatorLanguage };
  }

  const dispatch = useAppDispatch();
  const questionnaire: QuestionnaireDefinition = useAppSelector(
    (s) =>
      getQuestionnaireByTypeAndKind(s, reportType, locale()) ??
      emptyQuestionnaire()
  ) as any;
  const globalAnswers = useAppSelector(
    (s) => s.patientFlow.answers.entities ?? {}
  );
  const questionnaireErrors = useAppSelector((s) => s.questionnaire.error);
  const computedQuestions: ComputedQuestionDefinition[] = useMemo(() => {
    if (questionnaire?.questions) {
      return Object.entries(questionnaire.questions)
        .map(([k, q]) => ({ id: k, ...q }))
        .filter((q) => q.isComputed) as ComputedQuestionDefinition[];
    } else {
      return [];
    }
  }, [questionnaire]);

  const inheritedPageNumber = useAppSelector((s) => s.patientFlow.pageNumber);
  const [pageNumber, setPageNumber_raw] = useState(inheritedPageNumber);
  const [subpage, setSubpage] = useState<SubpageCount>(subpageless());
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [questionHidden, setQuestionHidden] = useState<Record<string, string>>(
    {}
  );

  const [fullPageModal, setFullPageModal] =
    useState<QuestionnaireFlowModalWindow | null>(null);
  function closeModal() {
    setFullPageModal(null);
  }

  // useChangeDebugging([pageNumber, subpage], "questionnaire flow");

  /**
   * The actual current Page object retrieved from the questionnaire definition
   * based on the pageNumber. This is calculated because it handles cases where
   * the index is malformed or questionnaire definition is invalid/not loaded.
   * In all cases currentPage will always be a valid Page object.
   */
  const currentPage: Page = useMemo(() => {
    if (questionnaire.loading) {
      return PAGE_LOADING;
    }
    if (
      Array.isArray(questionnaire?.pages) &&
      pageNumber > -1 &&
      pageNumber < questionnaire.pages.length
    ) {
      return questionnaire.pages[pageNumber];
    } else {
      console.error({ pageNav: pageNumber, pages: questionnaire?.pages });
      const status = (questionnaire as any).status as Status;
      if (status === Status.LoadFailed) {
        return {
          type: PageType.Error,
          error: `The questionnaire could not be loaded.`,
          trace: JSON.stringify(questionnaireErrors),
        };
      }
      return PAGE_LOADING;
    }
  }, [questionnaire, pageNumber]);

  /**
   * Retrieve the current length of a loop.
   *
   * As of right now, all loops are "answer-as-iterable" so the logic is pretty
   * simple: if the loop anchor has answers, the loop is as long as the number
   * of answers.
   *
   * If/when we reintroduce other types, this will need more.
   */
  function determineLoopLength(
    loopAnchorKey?: string,
    isInset: boolean = false
  ): number {
    if (typeof loopAnchorKey === "string") {
      const anchorQuestion = questionnaire.questions[loopAnchorKey];
      if (!anchorQuestion) {
        console.error(
          `Cannot find question ${loopAnchorKey} to anchor loop to!`
        );
        return -1;
      }
      if (!anchorQuestion.isMulti) {
        console.error(
          `Anchor questions must be muli-valued, otherwise loop is nonsensical`
        );
        return -1;
      }

      if (isInset) return 0;
      if (loopAnchorKey in globalAnswers) {
        return Array.isArray(globalAnswers[loopAnchorKey].values)
          ? globalAnswers[loopAnchorKey].values.length
          : 0;
      } else {
        console.warn(
          `Asked to determine loop length for unanswered question '${loopAnchorKey}'`
        );
        // question has not been answered!
        return 0;
      }
    }
    return -1;
  }

  /**
   * Determine if a displayable (an item with skipWhen or displayWhen formulas)
   * should be shown, given the current contextual data, primarily the answers
   * the user has provided.
   */
  function evaluateDisplayLogic(
    displayable: DisplayableEntity,
    answerContext: Record<string, any>,
    valueCache: Record<string, any>,
    label = `ad-hoc formula ${unlabeledFormulaCount++}`,
    showFailReason = false
  ): boolean | string {
    if (!displayable) {
      console.error("Attempted to evaluate display logic for null object");
      return false;
    }
    if (!useNavLogic) return true;
    if (Object.keys(answerContext)?.length) {
      console.warn({ answerContext });
    }
    const expandedValues = {
      ...valueCache,
      ...globalAnswers,
    };
    const expandedContext = {
      ...questionnaire,
      _currentRow: subpage.loopIteration,
    };
    if (Array.isArray(displayable.skipWhen)) {
      const result = !evaluator(
        displayable.skipWhen,
        expandedValues,
        expandedContext,
        true,
        false,
        label + ".skipWhen"
      );
      return result
        ? true
        : showFailReason
        ? "question skipWhen evaluated to true"
        : false;
    }
    if (Array.isArray(displayable.displayWhen)) {
      const result = evaluator(
        displayable.displayWhen,
        expandedValues,
        expandedContext,
        true,
        false,
        label + ".displayWhen"
      );
      return result
        ? true
        : showFailReason
        ? "question displayWhen evaluated to false"
        : false;
    }
    return true;
  }

  /**
   * Determine which questions on the current page are hidden or displayed.
   */
  function reevaluateQuestionVisibility(questionKeys: string[]) {
    const hideMapping: Record<string, string> = {};
    questionKeys.forEach((q) => {
      const showOrFailReason = evaluateDisplayLogic(
        questionnaire.questions?.[q],
        {},
        {},
        q,
        true
      );
      if (typeof showOrFailReason === "string") {
        hideMapping[q] = showOrFailReason;
      }
    });
    setQuestionHidden(hideMapping);
  }
  useEffect(() => {
    reevaluateQuestionVisibility(currentPage?.questions || []);
  }, [currentPage, globalAnswers]);

  /**
   * Set the new page number. This accepts the number as-is. It does not check
   * if the page would be visible, nor does it even check if the index is valid.
   */
  function setPageNumber(
    n: number,
    refresh: boolean = false,
    forward: boolean = pageNumber < n,
    needsSubpageUpdates: boolean = false
  ) {
    recordActivityForTimeout("page_navigation");
    if (n === pageNumber && !refresh) return;
    const newPage = questionnaire?.pages?.[n];
    const questionsOnPage = newPage?.questions;
    setActiveQuestionIndex(
      forward ?? n > pageNumber ? 0 : (questionsOnPage?.length || 1) - 1
    );
    if (newPage?.type === PageType.Question) {
      reevaluateQuestionVisibility(questionsOnPage || []);
    }

    if (needsSubpageUpdates) {
      const nextLoop = newPage?.loopId;
      if (nextLoop && nextLoop !== currentPage.loopId) {
        const loopLength = newPage.insetLoop
          ? 1
          : determineLoopLength(nextLoop, false);
        const choice = Number.parseInt(
          prompt("Which loop index? (enter an integer)", 0) ?? "",
          10
        );
        // const targetQ = questionnaire.questions[nextLoop];
        const loopIteration = newPage.insetLoop ? 0 : choice;
        let loopChoiceIndex = choice;
        setSubpage({
          ...subpage,
          loopLength,
          loopIteration,
          loopChoiceIndex,
        });
      } else if (!nextLoop && currentPage.loopId) {
        setSubpage(subpageless());
      }
    }
    setPageNumber_raw(n);
  }
  function advanceOneNoUpdate() {
    setPageNumber(pageNumber + 1, false, true, false);
  }
  function setPageByQuestionID(
    id: string,
    refresh: boolean = false,
    forward?: boolean,
    needsSubpageUpdates: boolean = false
  ) {
    const newPageNum = questionnaire.pages.findIndex((p) =>
      p.questions?.includes(id)
    );
    if (newPageNum === -1) {
      console.error(`Could not find page with question '${id}'`);
      return;
    }
    if (forward === undefined) {
      forward = newPageNum > pageNumber;
    }
    setPageNumber(newPageNum, refresh, forward, needsSubpageUpdates);
  }

  const autoplayAudio = useAppSelector((s) => s.audio.autoplay);
  /** Queue up the AudioSequence for the page */
  useEffect(() => {
    if (!autoplayAudio) return;
    if (currentPage.type === PageType.UniqueQuestion) {
      const q = UniqueTypeToQuestionDef[currentPage.uniqueType as UniqueType];
      const locs = buildAudioLocationListForQuestion(q, []);
      if (locs.length > 0) {
        requestOrRegister(locs, dispatch);
        const sequence: Partial<AudioSequence> & IDed = {
          ...DefaultAudioSequence,
          id: `question-unique-${currentPage.uniqueType}`,
          files: locs.map((l) => l.filename),
        };
        dispatch(audioSlice.actions.playSequence(sequence));
      }
    }
  }, [currentPage, autoplayAudio]);

  // const allQuestions = useMemo(() => {
  //   return {
  //     ...questionnaire?.questions,
  //     ...UniqueTypeToQuestionDef
  //   }
  // }, [questionnaire]);
  const activeQuestionId = useMemo(() => {
    return currentPage.type === PageType.UniqueQuestion
      ? currentPage.uniqueType
      : currentPage.questions?.[activeQuestionIndex] ?? "";
  }, [activeQuestionIndex, currentPage]);
  const activeQuestion = useMemo(() => {
    return questionnaire?.questions?.[activeQuestionId] ?? {};
  }, [questionnaire, activeQuestionId]);
  const activeAnswer = useMemo(() => {
    return globalAnswers[
      loopIndexedAnswerId(activeQuestionId, subpage.loopChoiceIndex)
    ];
  }, [globalAnswers, activeQuestionId, currentPage]);

  /**
   * Walk through pages until we find one that is valid to display. This method
   * is shared for both forward and backward navigations (based on the forward
   * argument); the word "next" in the title is to be understood *temporally*.
   */
  function findNextValidPage(
    startingPageNumber: number,
    forward: boolean = true,
    ignore = {},
    [min, max] = [0, questionnaire.pages.length]
  ): [number, Page] {
    if (startingPageNumber < min || startingPageNumber >= max) {
      throw new Error(
        `Initial page num ${startingPageNumber} is outside bounds [${min}, ${max}]`
      );
    }
    const delta = forward ? 1 : -1;
    /**
     * The value cache is used to keep track of the results of formulas we've
     * already run this update cycle, to avoid duplication. It gets passed
     * down to all the evaluator calls and is updated within them.
     */
    const valueCache = new Map();
    const startingPage = questionnaire.pages[startingPageNumber];

    let targetPageNumber = startingPageNumber;
    let targetPage = questionnaire.pages[targetPageNumber];
    function tryNextPage(specific = targetPageNumber + delta) {
      targetPageNumber = specific;
      targetPage = questionnaire.pages[targetPageNumber];
    }

    while (targetPageNumber >= min && targetPageNumber < max) {
      // until we walk off the edge of the questionnaire...

      if (targetPage.loopId) {
        // when we hit a loopId, this inner handler walks through the loop pages
        // until we've left that loop's contiguous block
        const targetLoopId = targetPage.loopId;
        const startedInThisLoop = startingPage.loopId === targetLoopId;
        const loopAnchor = questionnaire.questions[targetLoopId];
        const loopAnchorPageNumber = questionnaire.pages.findIndex(
          (p) => p.questions?.[0] === targetLoopId
        );
        const loopAnchorPage = questionnaire.pages[loopAnchorPageNumber];
        const loopLength = forceExhaustiveLoop
          ? (loopAnchor as ChoiceQuestionDefinition)?.choices?.length ?? 0
          : (targetPage as any).insetLoop
          ? startedInThisLoop
            ? 1
            : 0
          : determineLoopLength(targetLoopId);
        if (loopAnchor && loopLength > 0) {
          console.error(`loop '${targetLoopId}' has length ${loopLength}`);
          // if we're in the same loop as the initial question, use the active
          // loop iteration value, otherwise we start at the first or last:
          const initialIteration =
            targetLoopId === startingPage.loopId
              ? subpage.loopIteration
              : forward
              ? 0
              : loopLength - 1;
          let targetLoopIteration = initialIteration;
          // find the first and last page in the contiguous run of pages with the
          // same loopId as the initial page
          let firstLoopPage = targetPageNumber;
          while (
            questionnaire.pages[firstLoopPage - 1]?.loopId === targetLoopId
          ) {
            firstLoopPage--;
          }
          let lastLoopPage = targetPageNumber;
          while (
            questionnaire.pages[lastLoopPage + 1]?.loopId === targetLoopId
          ) {
            lastLoopPage++;
          }

          // until we've left the loop entirely...
          while (targetLoopIteration >= 0 && targetLoopIteration < loopLength) {
            if (targetLoopIteration !== initialIteration) {
              // when we start an iteration OTHER THAN THE VERY FIRST WE SEE, we
              // need to reset the target page to the "edge" of the loop
              tryNextPage(forward ? firstLoopPage : lastLoopPage);
            }
            while (
              targetPageNumber >= firstLoopPage &&
              targetPageNumber <= lastLoopPage
            ) {
              if (
                targetPageNumber === startingPageNumber &&
                targetLoopIteration === initialIteration
              ) {
                // we haven't yet moved from starting postion!
                console.log("First page is inside loop");
              } else {
                const [valid] = pageIsDisplayable(
                  targetPage,
                  startingPage,
                  forward,
                  valueCache,
                  { index: targetLoopIteration, length: loopLength },
                  ignore
                );
                if (valid) {
                  // right now we can be confident this will be defined since
                  // the determineLoopLength fn wouldn't return >0 otherwise
                  const answer: MultiValuedAnswer<IndexedString> | undefined =
                    onlyAcceptMultiChoice(globalAnswers[targetLoopId]);
                  const choiceOfNthAnswer =
                    answer?.values?.[targetLoopIteration]?.choiceIndex ?? -999;
                  // NOTE: we KEEP the previous loopChoiceIndex if we are on
                  // the first iteration to preserve it for hybrid/inset loops
                  // where we may not be on the "expected" choice relative to
                  // an iteration number
                  const useExistingChoice =
                    subpage.loopChoiceIndex > -1 &&
                    targetLoopIteration === initialIteration;
                  const loopChoiceIndex = useExistingChoice
                    ? subpage.loopChoiceIndex
                    : forceExhaustiveLoop
                    ? targetLoopIteration
                    : choiceOfNthAnswer;
                  setSubpage({
                    ...subpage,
                    loopLength: loopLength,
                    loopIteration: targetLoopIteration,
                    loopPageCount: lastLoopPage - firstLoopPage + 1,
                    loopPageIndex: targetPageNumber - firstLoopPage,
                    loopChoiceIndex,
                  });
                  setPageNumber(targetPageNumber, false, forward);
                  return [targetPageNumber, targetPage];
                }
              }
              tryNextPage(); // target page num += delta
              // remember we don't call continue until we leave the block of
              // matching loop pages
            }
            // we walked off the edge of the loop, but may have more iterations to
            // visit
            targetLoopIteration += delta;
          } // targetLoopIteration is no longer in [0, loopLength]

          if (
            !forceExhaustiveLoop &&
            startedInThisLoop &&
            (startingPage.insetLoop ||
              loopAnchorPage?.type === PageType.HybridQuestionSummary)
          ) {
            // SPECIAL: if we started in an "inset loop" or one whose anchor
            // page is a hybrid summary, then WHENEVER we leave the loop, we
            // should return to the summary page
            setSubpage(subpageless());
            setPageNumber(loopAnchorPageNumber, false, forward);
            return [loopAnchorPageNumber, loopAnchorPage];
          }
        } else {
          // loop definition was not found for ID, so bail on looping behavior
          tryNextPage();
          continue;
        }
        // END OF LOOP HANDLER
      } else {
        if (targetPageNumber !== startingPageNumber) {
          // this isn't a loop, so just test the page as is
          const [valid, displayedQuestions] = pageIsDisplayable(
            targetPage,
            startingPage,
            forward,
            valueCache,
            undefined,
            ignore
          );
          if (valid) {
            // we have finally found a displayable page
            setSubpage(subpageless());
            setPageNumber(targetPageNumber, false, forward);
            return [targetPageNumber, targetPage];
          }
        }
        tryNextPage();
        continue;
      }
    }

    console.warn("Found no valid pages to advance to!");
    return [startingPageNumber, questionnaire.pages[startingPageNumber]];
  }

  const PageTypesSkippedBackwards = [PageType.SaveWaypoint];
  function pageIsDisplayable(
    targetPage: Page,
    currentPage: Page,
    forwards = true,
    valueCache = new Map(),
    loopSettings = {},
    ignore = {}
  ): [boolean] {
    if (loopSettings) {
      if (
        loopSettings.length === 0 &&
        !ignore.loopLengthLimits &&
        !currentPage.showOnEmptyLoop
      ) {
        return [false];
      }
    }

    if (!forwards && PageTypesSkippedBackwards.includes(targetPage.type)) {
      return [false];
    }

    const tempAnswers = {};

    const pageHasLogic =
      Array.isArray(targetPage.displayWhen) ||
      Array.isArray(targetPage.skipWhen);
    const shouldDisplayPage = evaluateDisplayLogic(
      targetPage,
      tempAnswers,
      valueCache
    );
    if (!shouldDisplayPage) {
      return [false];
    }

    if (targetPage.type === PageType.UniqueQuestion) {
      if (targetPage.uniqueType === "chief complaint followup") {
        const unrefinedCC = globalAnswers["chief complaint unrefined"];
        if (unrefinedCC && unrefinedCC.values?.length > 1) {
          return [true];
        } else {
          return [false];
        }
      } else if (targetPage.uniqueType === "region of problem followup") {
        const unrefinedCC = globalAnswers["region of problem unrefined"];
        if (unrefinedCC && unrefinedCC.values?.length > 1) {
          return [true];
        } else {
          return [false];
        }
      } else {
        console.error(
          `Displaying unknown unique question type ${targetPage.uniqueType}`,
          targetPage
        );
        return [true];
      }
    }

    if (
      [PageType.Question, PageType.HybridQuestionSummary].includes(
        targetPage.type
      )
    ) {
      const questionsWithDisplayLogic: string[] = [];
      const activeQuestions = (targetPage as QuestionPage).questions.map(
        (qId) => {
          if (
            Array.isArray(questionnaire.questions[qId]?.displayWhen) ||
            Array.isArray(questionnaire.questions[qId]?.skipWhen)
          ) {
            questionsWithDisplayLogic.push(qId);
          }
          return evaluateDisplayLogic(
            questionnaire.questions[qId],
            tempAnswers,
            valueCache,
            qId
          );
        }
      );
      if (pageHasLogic && questionsWithDisplayLogic.length > 0) {
        console.warn(
          `Potential misconfiguration of display logic: ${
            targetPage.type
          } page AND its questions [${questionsWithDisplayLogic.join(
            ", "
          )}] each had their own display logic. This may be a mistake! Please compare and review.`
        );
      }
      if (activeQuestions.every((x) => x === false)) {
        return [false];
      } else {
        return [
          true,
          targetPage.questions.filter((q, i) => activeQuestions[i]),
        ];
      }
    }

    return [true];
  }

  // ********************************** PAGE NAVIGATION ********************************** //
  function goToNextPage(ignore?: object) {
    if (autoplayAudio) {
      dispatch(audioSlice.actions.stopAudio()); // TEMPORARY FOR TESTING
    }
    const [newNum, newObj] = findNextValidPage(pageNumber, true, ignore);
    metricsForPageTransition(newNum, newObj, MetricsEvent.PageForward);
  }

  function goToPreviousPage(ignore?: object) {
    if (autoplayAudio) {
      dispatch(audioSlice.actions.stopAudio()); // TEMPORARY FOR TESTING
    }
    const [newNum, newObj] = findNextValidPage(pageNumber, false, ignore);
    metricsForPageTransition(newNum, newObj, MetricsEvent.PageForward);
  }

  function activateByIndex(i) {
    setActiveQuestionIndex(i);
  }

  function calculateDerivedAnswers(
    newAnswer: GeneralAnswer,
    id: string
  ): Record<string, GeneralAnswer> {
    const out: Record<string, GeneralAnswer> = {};
    let hasUpdate = false;
    computedQuestions
      .filter((cq) => cq.triggers.includes(id))
      .forEach((cq) => {
        let computed: GeneralAnswer | null = null;
        if (cq.hardcodedFunctionName || cq.hardcodedFunction) {
          const cqf: ComputedQuestionFunction =
            cq.hardcodedFunction ??
            HARDCODED_COMPUTATION_FUNCTIONS[cq.hardcodedFunctionName!!];
          if (typeof cqf !== "function") {
            throw new Error(
              `Computation question had illegal non-function argument (${cq.id})`
            );
          }
          computed = cqf(
            cq,
            id,
            newAnswer,
            globalAnswers,
            out,
            questionnaire,
            cq.hardcodedFunctionArgument
          );
        } else {
          const formulaResult = evaluator(
            cq.formula,
            { ...globalAnswers, [id]: newAnswer },
            questionnaire,
            false,
            true,
            `${cq.id}.formula`
          );
          console.log({ [cq.id]: formulaResult });
          computed = buildAnswerFromComputedFormulaResult(cq, formulaResult);
        }
        if (computed !== null) {
          hasUpdate = true;
          out[cq.id] = computed;
        }
      });

    console.log({ derived: { hasUpdate, out } });

    if (hasUpdate) {
      return out;
    } else {
      return {};
    }
    // if (typeof HARDCODED_DERIVED_ANSWERS[id] === "function") {
    //   const result = HARDCODED_DERIVED_ANSWERS[id](newAnswer, globalAnswers);
    //   return Object.fromEntries(
    //     Object.entries(result).filter(
    //       ([key, answerOrNull]) => !!answerOrNull
    //     ) as Array<[string, GeneralAnswer]>
    //   );
    // } else {
    //   return {};
    // }
  }

  function acceptAnswer(newAnswer: GeneralAnswer, id: string) {
    recordActivityForTimeout("answer_change");
    // const updatedAnswers = {
    //   ...globalAnswers,
    //   [id]: newAnswer,
    //   ...calculateDerivedAnswers(newAnswer, id),
    // };
    // console.log({ updatedAnswers });
    // setAnswers(updatedAnswers);
    const activeQuestion = questionnaire.questions?.[activeQuestionId];
    const answerUpdate: AnswerUpdatePart[] = [[id, activeQuestion, newAnswer]];
    Object.entries(calculateDerivedAnswers(newAnswer, id)).forEach(
      ([dak, dav]) => {
        answerUpdate.push([dak, { special: "HARDCODED_DERIVED_ANSWER" }, dav]);
      }
    );
    dispatch(patientFlowSlice.actions.acceptAnswers(answerUpdate));

    if (
      autoProgression &&
      (AUTO_ADVANCE_TYPES.includes(activeQuestion?.layout) ||
        activeQuestion?.asList) &&
      !activeQuestion?.isMulti
    ) {
      setTimeout(() => {
        // TODO: make this check if this is still the same question once the time is up
        completeQuestion();
      }, AUTO_ADVANCE_TIMEOUT);
    }
  }

  // ********************************** KEYBOARD EVENTS ********************************** //
  function whenNoInputFocused(
    event: KeyboardEvent,
    shiftCB?: (e: KeyboardEvent) => void,
    nonShiftCB?: (e: KeyboardEvent) => void
  ) {
    const focused =
      ((event.target as Node).getRootNode() as Document | undefined)
        ?.activeElement || (event.target as HTMLElement);
    if (["INPUT", "TEXTAREA"].includes(focused.tagName)) {
      return;
    }
    if (event.shiftKey) {
      if (typeof shiftCB === "function") {
        shiftCB(event);
      }
    } else {
      if (typeof nonShiftCB === "function") {
        nonShiftCB(event);
      }
    }
  }

  // ********************************** USER METRICS ********************************** //
  function metricsForPageTransition(
    newPageNum: number,
    newPageObj: Page,
    eventType: MetricsEvent
  ) {
    const pageData: Partial<PageEventData> = {
      fromPage: pageNumber,
      fromPageType: currentPage.type,
      toPage: newPageNum,
      toPageType: newPageObj.type,
      navSkipped: Math.abs(newPageNum - pageNumber - 1),
      toPageQuestions: Array.isArray(newPageObj.questions)
        ? newPageObj.questions.slice()
        : [],
    };
    console.log({ pageData });
    dispatch(
      metricsSlice.actions.recordEvent({ type: eventType, addlProps: pageData })
    );
  }

  // ********************************** INTERNAL TESTING ********************************** //
  function jumpToReview() {
    const r = questionnaire.pages?.findIndex((p) => p.type === "review");
    if (r !== -1) {
      setPageNumber(r, true, true, true);
      metricsForPageTransition(
        r,
        questionnaire.pages[r] ?? {},
        MetricsEvent.PageJump
      );
    }
  }

  function showJumpOptions() {
    setFullPageModal({ type: "jump dialog" });
  }

  function clearCurrentAnswer(e: KeyboardEvent) {
    if (e.altKey) {
      dispatch(
        patientFlowSlice.actions.fullReset({
          isEarly: true,
          reason: "Debug answer wipe",
        })
      );
      return;
    }
    const aId = loopIndexedAnswerId(activeQuestionId, subpage.loopChoiceIndex);
    if (aId in globalAnswers) {
      console.warn(`Found '${aId}' in answers, deleting`);
      dispatch(patientFlowSlice.actions.clearAnswers([aId]));
      // delete answers[aId];
      // setAnswers({ ...answers });
    } else {
      console.log(`Did not find '${aId}' in answers list`);
    }
  }

  const [renderForcer, setRenderForcer] = useState(0);
  function forceRender() {
    setRenderForcer((i) => i + 1);
  }

  useKeyboardEvents(
    [
      "ArrowRight",
      (e: KeyboardEvent) =>
        whenNoInputFocused(e, goToNextPage, completeQuestion),
      "ArrowLeft",
      (e: KeyboardEvent) =>
        whenNoInputFocused(e, goToPreviousPage, navigateBack),
      "~",
      (e: KeyboardEvent) => whenNoInputFocused(e, jumpToReview, jumpToReview),
      "J",
      (e: KeyboardEvent) => whenNoInputFocused(e, showJumpOptions),
      "C",
      (e: KeyboardEvent) => whenNoInputFocused(e, clearCurrentAnswer),
      "Escape",
      (e: KeyboardEvent) =>
        fullPageModal ? setFullPageModal(null) : forceRender(),
      "F6",
      (e: KeyboardEvent) => {
        if (e.shiftKey) {
          setFullPageModal({
            type: "evaluator",
            answers: globalAnswers,
            title: "Evaluator Tool",
          });
        } else {
          setFullPageModal({
            type: "report",
            maxWidth: "1000px",
            answers: globalAnswers,
            title: "Debug Report",
          });
        }
      },
    ],
    "keyup",
    undefined,
    [
      pageNumber,
      activeQuestionIndex,
      findNextValidPage,
      goToNextPage,
      goToPreviousPage,
    ]
  );

  const reloadBlockerOn = useAppSelector((s) => s.patientFlow.blockReload);
  useEffect(() => {
    const listener = (beforeUnloadEvent: BeforeUnloadEvent) => {
      if (!reloadBlockerOn) return;
      console.error(beforeUnloadEvent);
      // modern browser preference is to use prevent default but the 2nd line
      // is good for backwards compatibility
      beforeUnloadEvent.preventDefault();
      beforeUnloadEvent.returnValue = true;
    };
    const result = window.addEventListener("beforeunload", listener);
    console.warn(`attached beforeunload listener with result ${result}`);
    return () => {
      console.error(`removing before unload listener`);
      window.removeEventListener("beforeunload", listener);
    };
  }, [reloadBlockerOn]);

  const uniqueTypesThatUseStandardProgression = [
    "chief complaint followup",
    "region of problem followup",
  ];
  /**
   * ********************************** HANDLE PAGE NAVIGATION CONDITIONS **********************************
   * Determine whether the user can progress from a page. Generally the only
   * reason they couldn't is that it is a question-like page and at least one
   * question has not yet been answered! This does not examine if the simulator
   * setting noDisableProgress is on as there are bits of code that may not care
   * so you must take care to OR that value with the result when relevant.
   *
   * TODO: This logic ONLY works with the (currently true) assumption that every
   * page only has a single question on it. If we ever have multiple questions
   * per page again, it will need to check all of them, but I'm hoping by then
   * we have some cleaner helper classes for dealing with reading them.
   */
  function canProgressBasedOnAnswers() {
    switch (currentPage.type) {
      case PageType.Question:
        return !isUnanswered(activeAnswer);
      case PageType.UniqueQuestion:
        if (
          uniqueTypesThatUseStandardProgression.includes(currentPage.uniqueType)
        ) {
          return !isUnanswered(activeAnswer);
        }
        console.warn(`Unknown unique question: ${currentPage.uniqueType}`);
        return false;
      case PageType.HybridQuestionSummary:
        return !isUnanswered(activeAnswer);
      default:
        return true;
    }
  }

  function completeQuestion() {
    const loggablePageName = `p${pageNumber} (${currentPage.type}:${
      (currentPage as TitlePage)?.title ??
      currentPage?.questions?.join(",") ??
      "*"
    })`;
    if (!noDisableProgress && !canProgressBasedOnAnswers()) {
      console.warn(
        `COULD NOT ADVANCE due to answer requirements ${loggablePageName}`
      );
      return;
    }
    console.log(`completing ${loggablePageName}`);
    if (
      currentPage.type !== PageType.Question ||
      activeQuestionIndex === currentPage.questions.length - 1
    ) {
      if (activeQuestion.layout === ResponseLayout.AnatomicalRegion) {
        const length = lengthOfActiveAnatomicalQuestion();
        if (subpage.questionStage < length - 1) {
          setSubpage({
            ...subpage,
            questionStage: subpage.questionStage + 1,
            questionStageCount: length,
          });
          return;
        }
      }
      goToNextPage();
    } else {
      activateByIndex(activeQuestionIndex + 1);
    }
  }

  function lengthOfActiveAnatomicalQuestion(): number {
    if (forceExhaustiveLoop) {
      const rootViewId =
        activeQuestion?.initialView ??
        getRootViewOfModel(PredefinedModels.default_human);
      const rootView = PredefinedModels.default_human?.[rootViewId];
      if (!rootView || rootView.isLeaf) return 1;
      return (rootView.choices?.length ?? 0) + 1;
    }
    if (!activeAnswer || !activeAnswer.isMulti) return 1;
    const firstValue = (
      activeAnswer as MultiValuedAnswer<
        WrappedOf<string> & { viewPath: string[] }
      >
    ).values?.[0];
    return Array.isArray(firstValue?.viewPath) ? firstValue.viewPath.length : 1;
  }

  const [showDeletionModal, setShowDeletionModal] = useState(false);
  function navigateBack() {
    if (pageNumber === 0) {
      dispatch(
        patientFlowSlice.actions.fullReset({
          isEarly: true,
          reason: "navigate before start",
        })
      );
      quitFn();
    }
    if (currentPage.type === "question") {
      if (
        currentPage.loopId &&
        !!currentPage.insetLoop &&
        !forceExhaustiveLoop
      ) {
        setShowDeletionModal(true);
        return;
      }
      if (subpage.questionStage > 0) {
        setSubpage({
          ...subpage,
          questionStage: subpage.questionStage - 1,
          questionStageCount: lengthOfActiveAnatomicalQuestion(),
        });
        return;
      }
      if (activeQuestionIndex > 0) {
        activateByIndex(activeQuestionIndex - 1);
      } else {
        goToPreviousPage();
      }
    } else {
      // TODO: more logic here potentially
      goToPreviousPage();
    }
  }

  function deleteCurrentLoopIteration(withAnchorEntry: boolean) {
    if (!currentPage.loopId) return;
    const loopQuestions = questionnaire.pages
      .filter((p) => p.loopId === currentPage.loopId)
      .map((p) => p.questions ?? [])
      .flat();
    const anchorQuestion = questionnaire.questions[currentPage.loopId];
    const answerUpdates: AnswerUpdatePart[] = loopQuestions
      .map((qId) => {
        const answerKey = loopIndexedAnswerId(qId, subpage.loopChoiceIndex);
        if (globalAnswers[answerKey]) {
          const empty = { ...globalAnswers[answerKey] };
          if (empty.value) {
            empty.value = { value: null };
          }
          if (empty.values) {
            empty.values = [];
          }
          if (empty.nonconformingValues) {
            empty.nonconformingValues = [];
          }
          return [
            answerKey,
            questionnaire.questions[qId],
            empty,
          ] as AnswerUpdatePart;
        }
        return null;
      })
      .filter((u) => u !== null);

    if (withAnchorEntry) {
      const anchorAnswer = onlyAcceptMultiChoice(
        globalAnswers[currentPage.loopId]
      );
      if (anchorAnswer) {
        answerUpdates.push([
          currentPage.loopId,
          anchorQuestion,
          {
            ...anchorAnswer,
            values: spliceOutOf(
              (v) => v.choiceIndex === subpage.loopChoiceIndex,
              anchorAnswer.values
            ),
          },
        ]);
      }
    }
    dispatch(patientFlowSlice.actions.acceptAnswers(answerUpdates));
    setPageByQuestionID(currentPage.loopId, false, undefined, true);
    setShowDeletionModal(false);
  }

  // ********************************** HEADER COMPONENT ********************************** //
  const headerMode: "backdrop" | "bubble" | "no-art" = useMemo(() => {
    if (fullPageModal && fullPageModal.type !== "jump dialog") {
      // when a modal is set
      return "no-art";
    }
    if (currentPage?.type === "language-selector") {
      return "backdrop";
    }
    if (!currentPage || !activeQuestion) {
      // when there is nothing active,
      return "no-art";
    }
    if (
      (!activeQuestion?.art && !activeQuestion?.contextualArt) ||
      COMPLEX_UI_TYPES.includes(activeQuestion.layout)
    ) {
      return "no-art";
    }
    return currentPage.questions?.indexOf(activeQuestion.id) > 0
      ? "bubble"
      : "backdrop";
  }, [activeQuestion, currentPage, fullPageModal]);

  const headerButtonState = useMemo(() => {
    function nextButtonState() {
      // highest priority is to acknowledge errors
      if (
        currentPage.error ||
        activeQuestion?.error ||
        questionHidden[activeQuestionId]
      ) {
        return {
          visible: true,
          active: true,
          error: true,
        };
      }

      // the last page never gets a next button
      if (pageNumber == questionnaire?.pages?.length - 1) {
        return {
          visible: false,
          active: false,
        };
      }

      // introduction modal is deprecated, but it uses standard next button
      if (fullPageModal?.type === "introduction") {
        return { active: true, visible: true };
      }
      // any page with a CTA
      if (
        currentPage?.type === "kiosk_start" ||
        (currentPage as TitlePage)?.callToAction ||
        questionnaire?.pages?.[pageNumber + 1]?.type === "success"
      ) {
        return {
          visible: false,
          active: false,
        };
      }

      const canProgress = canProgressBasedOnAnswers();
      if (currentPage.type === "question") {
        if (
          AUTO_ADVANCE_TYPES.includes(activeQuestion.layout) &&
          !activeQuestion.isMulti
        ) {
          // we are on an auto-progression question
          if (canProgress) {
            // but there is already an answer
            return {
              visible: true,
              active: true,
            };
          } else {
            return {
              visible: !autoProgression || noDisableProgress,
              active: noDisableProgress,
            };
          }
        } else {
          return {
            visible: true,
            active: noDisableProgress || canProgress,
          };
        }
      } else {
        // for now we give every other kind of page a next button...
        return {
          visible: true,
          active: canProgress,
        };
      }
    }

    return {
      next: nextButtonState(),
      audio: {
        active: true,
        visible: !fullPageModal,
      },
      back: {
        visible: true,
        // !!fullPageModal || pageNumber > 0 || activeQuestionIndex > 0
        active: true,
        icon: fullPageModal // || currentPage?.type === "question-modal"
          ? "quit"
          : "back",
      },
      callToAction:
        questionnaire?.pages?.[pageNumber + 1]?.type === PageType.Success
          ? "I'm done"
          : null,
    };
  }, [
    activeQuestion,
    // activeAnswer,
    globalAnswers,
    fullPageModal,
    pageNumber,
    autoProgression,
  ]);

  const filterChoicesByFormula = useAppSelector(
    (s) => s.simulator.conditionalChoicesEnabled
  );
  const hiddenChoices = useMemo(() => {
    if (
      currentPage?.type !== "question" ||
      !currentPage.questions?.length ||
      !questionnaire?.questions ||
      !filterChoicesByFormula
    )
      return [];
    const hiddenByQuestion = {};
    currentPage.questions.forEach((qID, i) => {
      if (Array.isArray(questionnaire.questions[qID]?.choices)) {
        const hiddenForThis = [];
        questionnaire.questions[qID].choices.forEach((c, i) => {
          if (c.displayWhen || c.skipWhen) {
            if (!evaluateDisplayLogic(c, {}, {}, `${qID}.choices.${i}`)) {
              hiddenForThis.push(i);
            }
          }
        });
        hiddenByQuestion[qID] = hiddenForThis;
      } else {
        hiddenByQuestion[qID] = [];
      }
    });
    console.log({ hiddenChoices: JSON.stringify(hiddenByQuestion) });
    return hiddenByQuestion;
  }, [currentPage]);

  const DEFAULT_BG_COLOR = "var(--evergreen)";
  const COLOR_PAGES = ["title", "kiosk_consent", "success", "study_invitation"];
  const STYLE_BGS = {
    light: null,
    cool: "var(--sky-blue)",
  };
  const fullBackground = useMemo(() => {
    if (currentPage.style in STYLE_BGS) {
      return STYLE_BGS[currentPage.style];
    }
    if (COLOR_PAGES.includes(currentPage.type)) {
      return DEFAULT_BG_COLOR;
    }
    return null;
  }, [currentPage]);

  const contextualArt = useMemo(() => {
    const choice = contextualChoice(
      activeQuestion.contextualArt,
      globalAnswers,
      questionnaire.questions,
      currentPage.loopId,
      subpage.loopChoiceIndex
    );
    if (!!choice) {
      if (!!choice.art) {
        return choice.art;
      }
      console.error(
        `Found choice ${choice.value} for context ${activeQuestion.contextualArt} but had empty art property!`
      );
    }
    return null;
  }, [activeQuestion]);

  // ********************************** INACTIVITY TIMER ********************************** //
  const lastActionTimestamp = useRef(Date.now());
  const [lastActionType, setLastActionType] = useState("uninitialized");
  const [inactivityDialogTimerId, setInactivityDialogTimerId] = useState(-1);
  const [inactivityDialogActive, setInactivityDialogActive] = useState(false);
  function inactiveDuration() {
    return Date.now() - lastActionTimestamp.current;
  }
  function idleLogoutTimestamp() {
    return lastActionTimestamp.current + INACTIVITY_LIMIT;
  }
  function displayIdleWarningTimestamp() {
    return (
      lastActionTimestamp.current + INACTIVITY_LIMIT - INACTIVITY_WARNING_LENGTH
    );
  }
  function logoutForInactivity() {
    if (lastActionTimestamp.current + INACTIVITY_LIMIT > Date.now()) {
      console.warn(
        `Inactivity logout seems to be irrelevant, last action was ${
          Date.now() - lastActionTimestamp.current
        }ms ago`
      );
      return;
    }
    dispatch(
      patientFlowSlice.actions.fullReset({ isEarly: true, reason: "inactive" })
    );
    quitFn();
  }

  function showInactivityDialog() {
    if (Date.now() >= displayIdleWarningTimestamp() - 200) {
      setInactivityDialogActive(true);
      const timerId = window.setTimeout(
        () => logoutForInactivity(),
        INACTIVITY_WARNING_LENGTH
      );
      setInactivityDialogTimerId(timerId);
    }
  }

  function recordActivityForTimeout(activityType: string) {
    window.clearTimeout(inactivityDialogTimerId);
    setLastActionType(activityType);
    lastActionTimestamp.current = Date.now();
    const timerId =
      INACTIVITY_WARNING_LENGTH > 0
        ? window.setTimeout(
            () => showInactivityDialog(),
            INACTIVITY_LIMIT - INACTIVITY_WARNING_LENGTH
          )
        : window.setTimeout(() => logoutForInactivity(), INACTIVITY_LIMIT);
    setInactivityDialogTimerId(timerId);
    if (inactivityDialogActive) setInactivityDialogActive(false);
  }

  useEffect(() => {
    recordActivityForTimeout("first_render");
  }, []);

  // ********************************** QUIT DIALOG ********************************** //
  const [showQuitDialog, setShowQuitDialog] = useState(false);
  function providerQuit(saveData = true) {
    if (saveData) {
      // NOTE: we don't issue a report ID this way though, which is fine ig?
      dispatch(
        answerSetSlice.actions.saveCurrentAnswerSet(
          AnswerSetSubmissionStatus.QuitDialog
        )
      );
    }
    dispatch(metricsSlice.actions.saveMetricsSession());
    setTimeout(() => {
      dispatch(
        patientFlowSlice.actions.fullReset({
          isEarly: true,
          reason: "quit-button",
        })
      );
      setShowQuitDialog(false);
      quitFn(false);
    }, 50);
  }
  function providerRestartAtEnd(refesh?: boolean) {
    dispatch(
      patientFlowSlice.actions.fullReset({
        isEarly: true,
        reason: "quit-button",
      })
    );
    quitFn(refesh); // should do lang reset + new metrics session
  }
  const hasSessionId = !!window.sessionStorage.getItem("tokenSessionId");
  const [pin, setPin] = useState<string | null>(null);

  // Open the quit dialog if the user backs up through an artificially injected
  // "guard" state in the browser history (which is applied whenever we aren't
  // currently in the quit dialog).
  useSimpleBackGuard(
    "quitDialog", // arbitrary name
    () => setShowQuitDialog(true),
    [!showQuitDialog]
  );

  // ********************************** LONG PRESS HANDLER ********************************** //
  function longPressHandler(event: MouseEvent | TouchEvent) {
    // add TouchEvent?
    console.warn({ event });
    if ((event?.target as HTMLElement | undefined)?.id === "backward-button") {
      console.log("Long press detected on backward button");
      setShowQuitDialog(true);
    } else {
      // disable for demos because it was happening accidentally
      // showJumpOptions();
      console.log("Long press detected on another element");
    }
  }
  function returnToDashboard(saveData = true) {
    const getDashboardToken = fn.httpsCallable("returnToDashboard");
    const sessionId = window.sessionStorage.getItem("tokenSessionId");
    getDashboardToken({ sessionId, pin }).then(
      ({ data }) => {
        console.warn({ data });
        window.sessionStorage.removeItem("tokenSessionId");
        window.sessionStorage.setItem("authToken", data.kioskToken);
        setShowQuitDialog(false);
        dispatch(patientFlowSlice.actions.allowReload(1000));
        setTimeout(() => {
          // we are about to reload, this debugger statement is to catch
          // anything we want to look at before the page is cleared
          window.location.reload();
        }, 500);
      },
      (err) => {
        console.error({ err });
        // setKioskRequestPending(false);
      }
    );
    if (saveData) {
      // NOTE: we don't issue a report ID this way though, which is fine ig?
      dispatch(
        answerSetSlice.actions.saveCurrentAnswerSet(
          AnswerSetSubmissionStatus.QuitDialog
        )
      );
    }
    dispatch(metricsSlice.actions.saveMetricsSession());
  }

  const uniques_chiefComplaintFollowup: ChoiceQuestionDefinition & IDed =
    useMemo(() => {
      const CCs_chosen = Array.isArray(
        globalAnswers["chief complaint unrefined"]?.values
      )
        ? globalAnswers["chief complaint unrefined"].values.map(
            (a) =>
              questionnaire.questions["chief complaint unrefined"].choices[
                a.choiceIndex
              ]
          ) ?? []
        : [];
      return {
        id: "chief complaint followup",
        layout: ResponseLayout.GridCards,
        coreType: CoreDataType.SingleChoiceText,
        isMulti: false,
        art: "/images/symptoms_fm.png",
        text: "You selected more than one choice. Which of these is your most important complaint?",
        translationId: "Q_GEN046",
        reportLabel: "Chief Complaint (Singular)",
        choices: CCs_chosen,
      };
    }, [globalAnswers]);

  const bodyTypeAnswer = useAppSelector(
    (s) => s.patientFlow.answers.entities["sex assigned at birth"]
  );
  function bodyPartToStaticImageAsset(part: any) {
    if (typeof part !== "string") return "bad_body_part";
    // note if "sex assigned at birth" is absent, this defaults to male
    const bodyTypeSuffix =
      bodyTypeAnswer?.value?.value === "Female" ? "fm" : "ml";
    return `/images/body_part_${part
      .toLocaleLowerCase()
      .replace(/ /g, "_")
      .replace("{SEX}", bodyTypeSuffix)}.png`;
  }

  const uniques_regionOfProblemFollowup: ChoiceQuestionDefinition & IDed =
    useMemo(() => {
      const question: ChoiceQuestionDefinition =
        questionnaire.questions?.["region of problem unrefined"];
      let regions_chosen: ResponseChoice[] = [];
      if (
        question &&
        Array.isArray(globalAnswers["region of problem unrefined"]?.values)
      ) {
        regions_chosen = globalAnswers[
          "region of problem unrefined"
        ].values.map((a) => {
          const viewId = a.viewPath[a.viewPath?.length - 1];
          const view = PredefinedModels.default_human[viewId];
          const modelChoice = view?.choices.find((c) => c.value === a.value);
          return {
            ...a,
            label: `${a.value}`,
            art: bodyPartToStaticImageAsset(
              modelChoice?.staticArt ?? `${a.value}_{SEX}`
            ),
            labelTranslationId: modelChoice?.labelTranslationId,
          };
        });
      }
      return {
        id: "region of problem followup",
        layout: ResponseLayout.GridCards,
        coreType: CoreDataType.SingleChoiceText,
        art: "/images/symptoms.png",
        text: "You selected more than one region. Which of these has the worst problem/pain?",
        translationId: "Q_GEN046", // not an exact match, but this question is currently unused
        reportLabel: "Problem Region (Singular)",
        choices: regions_chosen,
      };
    }, [globalAnswers["region of problem unrefined"], bodyTypeAnswer]);

  const KNOWN_UNIQUES = [
    "chief complaint followup",
    "region of problem followup",
  ] as const;
  type UniqueType = (typeof KNOWN_UNIQUES)[number];

  const UniqueTypeToQuestionDef: Record<UniqueType, ChoiceQuestionDefinition> =
    {
      "chief complaint followup": uniques_chiefComplaintFollowup,
      "region of problem followup": uniques_regionOfProblemFollowup,
    };

  // TEMP until navigation is reduxified
  function setPage_Overload(
    pageNum?: number,
    subPageConfig?: Partial<SubpageCount>,
    activeQI?: number
  ) {
    if (pageNum !== undefined) {
      setPageNumber(pageNum, false, undefined, subPageConfig === undefined);
    }
    if (subPageConfig !== undefined) {
      setSubpage({ ...subpage, ...subPageConfig });
    }
    if (activeQI !== undefined) {
      setActiveQuestionIndex(activeQI);
    }
  }

  const RTLLangs = [Language.Arabic, Language.Urdu];

  return (
    <div
      className={`redesign page-${currentPage?.type} ${
        headerMode === "backdrop" ? "has-backdrop" : "no-backdrop"
      } ${debugHighlighting ? "debugHighlighting" : ""}`}
      style={{ background: fullBackground ?? "transparent" }}
      dir={RTLLangs.includes(language) ? "rtl" : "ltr"}
    >
      <NavigationalOverlay
        page={currentPage}
        subpage={subpage}
        mode={headerMode}
        activeQuestion={activeQuestion}
        artOverride={
          currentPage?.type === "language-selector"
            ? "/images/AMA_md.png"
            : contextualArt
        }
        activeAnswer={activeAnswer}
        buttonState={headerButtonState}
        hideFade={!!fullPageModal}
        goBackExit={fullPageModal ? closeModal : navigateBack}
        goForward={completeQuestion}
        longPress={longPressHandler}
        twinPress={() => setFullPageModal({ type: "jump dialog" })}
        useNeutralColor={fullBackground}
      />
      {fullPageModal ? (
        <StandardModalDialog
          {...fullPageModal}
          modalPage={subpage.infomationStage}
          questionnaire={questionnaire}
          setPage={setPage_Overload}
          closeModal={closeModal}
          currentPage={pageNumber}
        />
      ) : null}

      {/* ============ STANDARD PAGE TYPES ============ */}
      <StandardPage
        questionnaire={questionnaire}
        answers={globalAnswers}
        activeQuestionId={activeQuestionId}
        currentPage={currentPage}
        pageNumber={pageNumber}
        subpage={subpage}
        questionHidden={questionHidden}
        hiddenChoices={hiddenChoices}
        // actions
        completeQuestion={completeQuestion}
        navigateBack={navigateBack}
        acceptAnswer={acceptAnswer}
        setFullPageModal={setFullPageModal}
        restart={(refresh?: boolean) => {
          providerRestartAtEnd(refresh);
          return true;
        }}
      />

      {/* ============ CUSTOM PAGE TYPES ============ */}
      {/* we will work to standardize these, but for now, here be dragons */}

      {currentPage.type === PageType.HybridQuestionSummary ? (
        <HybridQuestionSummaryPage
          {...currentPage}
          questionnaire={questionnaire}
          answers={globalAnswers}
          activeQuestionId={activeQuestionId}
          questionHidden={questionHidden}
          hiddenChoices={hiddenChoices}
          setSubpage={setSubpage}
          advanceOneNoUpdate={advanceOneNoUpdate}
          openModal={setFullPageModal}
          acceptAnswer={acceptAnswer}
          completeQuestion={completeQuestion}
          navigateBack={navigateBack}
        />
      ) : null}

      {currentPage.type === PageType.UniqueQuestion ? (
        <div
          className="full-height"
          style={{
            maxWidth: "100%",
            overflowY: "hidden",
            paddingBottom: "33dvh",
          }}
        >
          {KNOWN_UNIQUES.includes(currentPage.uniqueType) ? null : (
            <h3 className="text-danger">
              Unknown unique type: {safeStringify(currentPage.uniqueType)}
            </h3>
          )}
          {currentPage.uniqueType === "chief complaint followup" ? (
            <div className="full-height" style={{ width: "100%" }}>
              <SingleQuestion
                page={currentPage}
                index={0}
                isActive={true}
                question={uniques_chiefComplaintFollowup}
                answer={
                  globalAnswers[uniques_chiefComplaintFollowup.id] ??
                  EMPTY_ANSWER
                }
                answerId={uniques_chiefComplaintFollowup.id}
                submitAnswerFor={(a, i) =>
                  acceptAnswer(
                    { ...a, questionKey: "chief complaint unrefined" },
                    i
                  )
                }
                complete={completeQuestion}
                // containerRef={questionRefs[i]}
                hiddenChoices={[]}
                trackingInfo={showTrackingInfo}
                keywordAction={(label, content) =>
                  setFullPageModal({ type: "markdown", content })
                }
                displayInfoModal={(choice) =>
                  setFullPageModal({
                    type: "markdown",
                    content: choice.moreInfo,
                    imgUrl: choice.imgUrl,
                    title: choice.label ?? choice.value,
                  })
                }
                contextualResponse={null}
              />
            </div>
          ) : null}
          {currentPage.uniqueType === "region of problem followup" ? (
            <div className="full-height" style={{ width: "100%" }}>
              <SingleQuestion
                page={currentPage}
                index={0}
                isActive={true}
                question={uniques_regionOfProblemFollowup}
                // textOverride={}
                answer={
                  globalAnswers[uniques_regionOfProblemFollowup.id] ??
                  EMPTY_ANSWER
                }
                answerId={uniques_regionOfProblemFollowup.id}
                submitAnswerFor={acceptAnswer}
                complete={completeQuestion}
                // containerRef={questionRefs[i]}
                hiddenChoices={[]}
                trackingInfo={showTrackingInfo}
                keywordAction={(label, content) =>
                  setFullPageModal({ type: "markdown", content })
                }
                displayInfoModal={(choice) =>
                  setFullPageModal({
                    type: "markdown",
                    content: choice.moreInfo,
                    imgUrl: choice.imgUrl,
                    title: choice.label ?? choice.value,
                  })
                }
                contextualResponse={null}
              />
            </div>
          ) : null}
        </div>
      ) : null}

      {/* ======================= debug info display ====================== */}
      {showTrackingInfo ? (
        <div
          style={{
            position: "absolute",
            left: "2em",
            bottom: "1em",
            color: "gray",
            opacity: 0.75,
            pointerEvents: "none",
          }}
        >
          <button
            className="btn"
            style={{ pointerEvents: "all", padding: 4 }}
            onClick={quitFn}
          >
            Restart
          </button>
          page: {pageNumber} ({`${currentPage?.type}`}) | loop: #
          {`${subpage.loopIteration}`}({`${subpage.loopChoiceIndex}`})/
          {`${subpage.loopLength}`} p: {subpage.loopPageIndex}
          {subpage.loopPageCount}| info subpage: {`${subpage.infomationStage}`}|
          activeQ: {activeQuestionIndex}/{currentPage?.questions?.length} (
          {`${activeQuestionId}`}) | scroll: (-{activeQuestionIndex * 100}vh) |
          idle in {Math.floor((idleLogoutTimestamp() - Date.now()) / 1000)}s (
          {moment(idleLogoutTimestamp()).format("HH:mm:ss")}), last idle action
          was <u>{lastActionType}</u> at{" "}
          {moment(lastActionTimestamp.current).format("HH:mm:ss")}, last metric
          was {"õ"}
        </div>
      ) : null}

      {/* ======================= timeout popup ====================== */}
      {inactivityDialogActive ? (
        <SmallModalDialog
          quit={() => setInactivityDialogActive(false)}
          background="green"
        >
          <h2>Are you still there?</h2>
          <p>Tap Yes to continue</p>
          <CountdownTimer
            timestamp={Date.now() + 60000}
            completionMessage={"Ending session..."}
          />
          <button
            className="btn btn-light w-100"
            onClick={() => recordActivityForTimeout("manual_de_idle")}
          >
            Yes
          </button>
        </SmallModalDialog>
      ) : null}

      {/* ====================== provider quit dialog ==================== */}
      {showQuitDialog ? (
        <SmallModalDialog
          quit={() => setShowQuitDialog(false)}
          background="green"
        >
          <h2>Confirm Exit</h2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "var(--softened-gold)",
              color: "var(--dark-font)",
              borderRadius: "8px",
              padding: "16px",
              maxWidth: "400px",
              margin: "20px auto",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <FaExclamationTriangle size={24} style={{ marginRight: "8px" }} />
            <strong style={{ marginRight: "4px" }}> Warning:</strong>{" "}
            <span>You may lose patient data.</span>
          </div>
          <h3 style={{ marginTop: "2rem" }}>Restart Questionnaire</h3>
          <p>
            The questionnaire will restart and be ready for the next patient. Do
            you want to save the current report?
          </p>
          <div className="row">
            <div className="col">
              <CTA
                onClick={() => providerQuit(true)}
                light={true}
                style={{
                  fontSize: "1em",
                  width: "100%",
                  backgroundColor: "var(--softened-evergreen)",
                }}
              >
                Save Report
              </CTA>
            </div>
            <div className="col">
              <CTA
                onClick={() => providerQuit(false)}
                style={{
                  backgroundColor: "#c70000",
                  fontSize: "1em",
                  width: "100%",
                  color: "white",
                }}
              >
                Erase Report
              </CTA>
            </div>
          </div>

          <hr
            style={{
              border: "none",
              borderTop: "1px solid var(--dark-evergreen)",
            }}
          />
          {hasSessionId ? (
            <>
              <h3 style={{ marginTop: "2rem" }}>Return to Dashboard</h3>
              <p>
                To return to the provider dashboard, enter your PIN and then
                select save or erase for the current report.
              </p>
              <input
                type="text"
                className="pin"
                onChange={(e) => setPin(e.target.value)}
                pattern="\d{4,6}"
                placeholder="Enter your PIN"
                aria-label="Enter your PIN"
              />
              <div className="row" style={{ marginTop: "12px" }}>
                <div className="col">
                  <CTA
                    onClick={() => returnToDashboard(true)}
                    light={true}
                    style={{
                      fontSize: "1em",
                      width: "100%",
                      backgroundColor: "var(--softened-evergreen)",
                    }}
                  >
                    Save Report
                  </CTA>
                </div>
                <div className="col">
                  <CTA
                    onClick={() => returnToDashboard(false)}
                    style={{
                      backgroundColor: "#c70000",
                      fontSize: "1em",
                      width: "100%",
                      color: "white",
                    }}
                  >
                    Erase Report
                  </CTA>
                </div>
              </div>
            </>
          ) : (
            <em>
              Session validation failed. Please choose an option above and
              reload the page to log in with your username and password.
            </em>
          )}
        </SmallModalDialog>
      ) : null}

      {showDeletionModal ? (
        <SmallModalDialog quit={() => setShowDeletionModal(false)}>
          <h2>
            {t("Q_GEN045", "This will delete your answers. Are you sure?")}
          </h2>
          <CTA onClick={() => setShowDeletionModal(false)}>
            {t("R_GEN283", "Keep")}
          </CTA>
          <CTA
            onClick={() => deleteCurrentLoopIteration(true)}
            style={{ backgroundColor: "var(--salmon-red)" }}
          >
            {t("R_GEN282", "Delete")}
          </CTA>
        </SmallModalDialog>
      ) : null}
    </div>
  );
};
