import { AudioSequence } from "../models/audio";

export const DefaultInterTrackAudioGap = 200; // 0.2 sec
export const DefaultSequenceLoopAudioGap = 5000; // 5 sec
export const DefaultAudioSequence: Partial<AudioSequence> = {
  id: `default-sequence`,
  files: [],
  current: 0,
  loop: false,
  gap: DefaultInterTrackAudioGap,
  loopGap: DefaultSequenceLoopAudioGap
};

/**
 * The amount of "excusable" time for audio to load, in ms. This is used to
 * decide how to present UI elements related to audio that has not finished
 * loading.
 */
export const LOADING_GRACE_PERIOD = 1500;