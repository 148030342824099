import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { KeyTranslationIDs } from "../../../models/audio";
import { debounce } from "../../../utils";

const DEFAULT_TEXT_INPUT_DEBOUNCE_MS = 1000;

export default function FreeTextResponse({
  answer,
  questionDefinition,
  updateAnswer,
  debounceTime = DEFAULT_TEXT_INPUT_DEBOUNCE_MS
}) {
  const {t} = useTranslation();

  const [localValue, setLocalValue] = useState(answer.value?.value ?? "");

  const submitAfterSettled = useCallback(debounce(updateAnswer, debounceTime), []);

  function handleUserInput(event) {
    setLocalValue(event.target.value);
    // call updateAnswer here with value: event.target.value and other things set as in ListQuestion
    submitAfterSettled({
      // id: questionDefinition.id,
      isMulti: false,
      value: {
        ...answer,
        value: event.target.value,
      },
    });
  }

  return (
    <div
      className="main-column"
      style={{
        margin: "0 auto",
        padding: questionDefinition.nonconformingResponses ? "" : "",
      }}
    >
      <textarea
        className={
          questionDefinition.nonconformingResponses
            ? "stack-height"
            : "full-height"
        }
        value={localValue}
        onChange={handleUserInput}
        placeholder={t(questionDefinition.placeholderTranslationId, questionDefinition.placeholder) || t(KeyTranslationIDs.Placeholder_FreeResponse, "Type your answer here")}
      />
    </div>
  );
}
