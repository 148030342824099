import { useState } from "react";
import { IS_PROD } from "../../constants/app";
import { authSlice, Credentials } from "../../store/slices/auth";
import {
  useAppDispatch,
  useAppSelector,
  useBooleanState,
  useKeyboardEvents,
} from "../../utils/hooks";
import { JSObjectDump } from "../../utils/UtilComponents";
import { CTA } from "../UI/buttons/CTA";
import BeatingHeart from "../UI/BeatingHeart";
import Footer from '../Questionnaire/Partials/Footer';

export type BuildAnnotatedWindow = {
  __BUILD: Record<"time" | "hash" | "dirty" | "branch", string>;
} & typeof window;

export const LoginComponent = ({
  actionOverride,
  tokenMode: tokenModeProp = false
}: {
  actionOverride?: (c: Credentials) => void;
  tokenMode: boolean
}) => {
  const dispatch = useAppDispatch();

  const { value: showBuildDetails, toggle: toggleBuildDetails } =
    useBooleanState(false);

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [tokenMode, setTokenMode] = useState<boolean>(tokenModeProp);

  const user = useAppSelector((s) => s.auth.user);
  const authProcessing = useAppSelector((s) => s.auth.loading);
  const authError = useAppSelector((s) => s.auth.error);

  function tryLogin(e: Event | MouseEvent) {
    if ((e as MouseEvent).shiftKey) {
      setTokenMode(!tokenMode);
      e.preventDefault();
      return false;
    }
    if (actionOverride && typeof actionOverride == "function") {
      actionOverride({ username, password, token });
    } else {
      if (tokenMode) {
        dispatch(authSlice.actions.login({ token }));
      } else {
        dispatch(authSlice.actions.login({ username, password }));
      }
    }
    e.preventDefault();
    return false;
  }

  return (
    <div
      className="centered-flex-container main-columm force-full-height"
      style={{ justifyContent: "center", gap: 12 }}
    >
      {IS_PROD ? null : (
        <div
          onClick={toggleBuildDetails}
          style={{
            position: "absolute",
            top: 15,
            padding: "12px 24px",
            textAlign: "center",
            backgroundColor: "#ffdc26",
          }}
          className="raised-box"
        >
          This is NOT the{" "}
          <a href="https://app.literaseed.io">production application</a>. Do not
          use real data!{" "}
          {showBuildDetails ? "" : <em>(Click for build details.)</em>}
          {showBuildDetails ? (
            <JSObjectDump
              obj={(window as BuildAnnotatedWindow).__BUILD}
              style={{ textAlign: "left" }}
            />
          ) : null}
        </div>
      )}
      <div className="logo-bar">
        {/* <div className="raised-box logo">
          <img src="/images/login_logo_literaseed.png" />
        </div> */}
        <div className="">
          <img src="/images/logos/valleywise_health_logo.png" style={{width: "100%"}}/>
        </div>
      </div>
      <div className="raised-box">
        {tokenMode ? <form onSubmit={tryLogin}>
          <textarea onChange={(e) => setToken(e.target.value)} rows={6}></textarea>
          <CTA
            label="Log In With Token"
            style={{ width: "100%", marginTop: "2em" }}
            light={false}
            onClick={tryLogin}
            disabled={authProcessing}
            children={
              authProcessing ? (
                <div className="loader-dots-fade"/>
              ) : null
            }
          />
        </form>
        
        /* traditional form below: */
        : <form onSubmit={tryLogin} id="login-form">
          <img src="/images/marketing/valleywise_patient_care_green.png" style={{width: "100%"}}/> 
          <h4 style={{textAlign: "center"}}>
            Welcome to the Emergency Department Portal
          </h4>
          <p style={{textAlign: "center"}}>
            Please email us for login issues. Our hours of support are 8am - 10pm EST.
          </p>
          <hr style={{ marginBottom: "2em", textAlign: "center" }} />
          <div>
            <label>Username</label>
            <input
              type="email"
              placeholder="email"
              onChange={(e) => setUsername(e.target.value)}
              autoCapitalize="none"
              disabled={!!authProcessing}
            ></input>
          </div>
          <div style={{ marginTop: "0.5em" }}>
            <label>Password</label>
            <input
              type="password"
              placeholder="password"
              onChange={(e) => setPassword(e.target.value)}
              autoCapitalize="none"
              disabled={!!authProcessing}
            ></input>
          </div>
          <CTA
            label="Log In"
            style={{ width: "100%", marginTop: "2em" }}
            light={false}
            onClick={tryLogin}
            disabled={authProcessing}
            children={
              authProcessing ? (
                <div className="loader-dots-fade"/>
              ) : null
            }
          />
          <div style={{textAlign: "center", marginTop: "2em"}}> We <BeatingHeart/> Valleywise Health </div>
          
        </form>}
        {authError ? (
          <>
            <JSObjectDump obj={authError} style={{ background: "#FFE0E0" }} />
            <span style={{ color: "var(--salmon-red)" }}>
              If you are having difficulty with the app, you may want to{" "}
              <a
                style={{ textDecoration: "underline" }}
                onClick={() => window.location.reload()}
              >
                reload
              </a>
            </span>
          </>
        ) : null}
      </div>
      <Footer />
    </div>
  );
};
