import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DefaultAudioSequence } from "../../../constants/audio";
import {
  EnglishNames,
  Language,
  LanguageEndonym,
  LanguageISO,
  SelectorAssets,
} from "../../../constants/locales";
import {
  defaultInstructionalAudioForType,
  getAudioLocationFromId,
  getLanguageEntryAudioFilename,
} from "../../../models/audio";
import { audioSlice, registerLocalAudio } from "../../../store/slices/audio";
import { patientFlowSlice } from "../../../store/slices/patient-flow";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { Card } from "../../UI/Card/Card";

const SupportedLanguages = [
  Language.English,
  Language.Spanish,
  Language.Arabic,
  Language.Swahili,
  Language.Burmese,
  Language.Kinyarwanda,
  Language.Somali,
];

const disabledStyle: React.CSSProperties = {
  opacity: 0.4,
  filter: "saturation(0.4)",
  pointerEvents: "none",
};

const warningStyle: React.CSSProperties = {
  border: "5px solid transparent",
  backgroundImage:
    "linear-gradient(white, white), repeating-linear-gradient(-45deg, gold 0, gold 1em, transparent 0, transparent 2em)",
  backgroundOrigin: "border-box",
  backgroundClip: "padding-box, border-box",
};

export const LanguageSelectorPage = ({
  listedLanguages = SupportedLanguages,
  disabledLanguages = [],
  warningLanguages = [],
  useScrolling = true,
}: {
  listedLanguages?: LanguageISO[];
  disabledLanguages?: LanguageISO[];
  warningLanguages?: LanguageISO[];
  useScrolling?: boolean;
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const activeAudio = useAppSelector((s) => s.audio.activeAudio);
  // const [audioToLangHighlight, setAudioToLangHighlight] =
  //   useState<{[file: string]: LanguageISO}>({});

  const promptAudioFilename = useMemo(() => {
    const promptTranslationId = defaultInstructionalAudioForType(
      "!! language selector"
    );
    return promptTranslationId
      ? getAudioLocationFromId(promptTranslationId, Language.English).filename
      : undefined;
  }, []);
  // NOTE the language selector always ignores the current autoplay setting!
  useEffect(() => {
    const files: string[] = [];
    if (promptAudioFilename != undefined) {
      files.push(promptAudioFilename);
    }
    // const reverseMap: {[file: string]: LanguageISO} = {};
    // The audio files for the language are local
    listedLanguages.forEach((l) => {
      if (l in SelectorAssets) {
        const url = getLanguageEntryAudioFilename(l);
        if (url) {
          files.push(url);
        }
      }
    });
    dispatch(registerLocalAudio(files));
    dispatch(
      audioSlice.actions.playSequence({
        ...DefaultAudioSequence,
        id: "language-selector",
        files,
        // loop: true,
        loopGap: 2500,
      })
    );
  }, []);

  const chosenLanguage = useAppSelector((s) => s.patientFlow.language);

  function pickLanguage(l: LanguageISO) {
    dispatch(patientFlowSlice.actions.selectLanguage(l));
    dispatch(audioSlice.actions.stopAudio());
  }

  return (
    <div className="centered-flex-container main-columm pad-for-header force-full-height">
      <h3
        className={`question ${
          activeAudio.playing && activeAudio.filename === promptAudioFilename
            ? "pulsing"
            : ""
        }`}
      >
        What is your preferred language? {/* i18n: no translation */}
      </h3>
      <div className="centered-flex-container main-column">
        <div className="stack-of-cards">
          {listedLanguages.map((l) => (
            <Card
              isSelected={chosenLanguage === l}
              style={
                disabledLanguages.includes(l)
                  ? disabledStyle
                  : warningLanguages.includes(l)
                  ? warningStyle
                  : {}
              }
              onClick={() => pickLanguage(l)}
              stack={true}
              title={
                warningLanguages.includes(l)
                  ? "This language is not normally available on production."
                  : ""
              }
              // art={null}
              useGlyphForArt={
                SelectorAssets[l]?.flags?.slice?.(0, 4) ?? undefined
              }
              label={LanguageEndonym[l]}
              description={l === Language.English ? null : EnglishNames[l]}
              audioHighlightKeys={[getLanguageEntryAudioFilename(l)]}
              scrollOnAudio={useScrolling}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
