import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { ChangeEvent, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import Picker from "react-mobile-picker";
import { KNOWN_MEASUREMENT_TYPES } from "../../../constants/measurements";
import { GeneralAnswer, isUnanswered } from "../../../models/answers";
import { ResponseLayout } from "../../../models/layouts";
import {
  AtomicUnit,
  Dimension,
  ESTABLISHED_SYSTEMS,
  NumberAndUnitType,
  pseudoMeasurementUnit,
  SystemOfMeasure,
  toAtomArray,
  Unit,
} from "../../../models/measurements";
import {
  MeasurementQuestionDefinition,
  PseudoMeasurementQuestionDefinition,
} from "../../../models/questions";
import { clamp, joinWithConjunction, ordinals } from "../../../utils/index";

const TREAT_ALL_MEASUREMENTS_AS_MULTI = true;

export default function MeasurementResponse({
  answer,
  questionDefinition,
  updateAnswer,
}: {
  answer: GeneralAnswer;
  questionDefinition:
    | MeasurementQuestionDefinition
    | PseudoMeasurementQuestionDefinition;
  updateAnswer: (a: GeneralAnswer) => void;
}) {
  const { t } = useTranslation();
  const firstInput = useRef<HTMLInputElement | null>(null);

  const isPseudo =
    questionDefinition.layout === ResponseLayout.PseudoMeasurement;
  const pseudoUnit = useMemo(() => {
    if (isPseudo) {
      return pseudoMeasurementUnit(questionDefinition);
    } else {
      return null;
    }
  }, [questionDefinition]);
  const systemOverride = isPseudo
    ? SystemOfMeasure.Nonstandard
    : questionDefinition.forceSystem;

  /** Is this metric, imperial, or something else? */
  const system: SystemOfMeasure =
    answer.system ?? systemOverride ?? SystemOfMeasure.Imperial;

  /** The dimension sets the general category of measurement such as width or height. */
  const dimension: Dimension | null = isPseudo
    ? { [SystemOfMeasure.Nonstandard]: pseudoUnit!! }
    : KNOWN_MEASUREMENT_TYPES[questionDefinition?.measurementType] ?? null;

  /** The unit set is a specific unit or units (which implies both a system & dimension) */
  const unitSet: Unit | null = dimension?.[system] ?? null;

  function defaultAsValues(
    newSystem: SystemOfMeasure = system
  ): NumberAndUnitType | NumberAndUnitType[] | null {
    if (questionDefinition.initialValue) {
      if (isPseudo) {
        return {
          value: questionDefinition.initialValue,
          unit: questionDefinition.unit,
          index: 0,
        };
      } else {
        return questionDefinition.initialValue[newSystem] ?? null;
      }
    }
    const newUnitSet = dimension?.[newSystem];
    if (newUnitSet?.default) {
      if (Array.isArray(newUnitSet.unit)) {
        return newUnitSet.unit.map((unit, i) => ({
          unit,
          value: (newUnitSet.default as number[])[i],
        })) as NumberAndUnitType[];
      } else {
        return {
          unit: newUnitSet.unit,
          value: newUnitSet.default as number,
        } as NumberAndUnitType;
      }
    }
    return null;
  }

  /**
   * Update the answer to be the initial values for a particular system of
   * measure. This starts with the question definition's initialValue property
   * and then opts for the defaults for that over measurement kind. If neither
   * is found this does nothing.
   * @param newSystem The system of measure we are changing to, if any.
   * @returns Whether or not an answer update was dispatched.
   */
  function applyInitialValueFor(newSystem: SystemOfMeasure = system): boolean {
    const initial = defaultAsValues(newSystem);
    if (initial) {
      if (Array.isArray(initial)) {
        updateAnswer({
          isMulti: true,
          key: questionDefinition.id,
          values: initial,
          system: newSystem,
        });
      } else {
        if (TREAT_ALL_MEASUREMENTS_AS_MULTI) {
          updateAnswer({
            isMulti: true,
            key: questionDefinition.id,
            values: [initial],
            system: newSystem,
          });
        } else {
          updateAnswer({
            isMulti: false,
            key: questionDefinition.id,
            value: initial,
            system: newSystem,
          });
        }
      }
      return true;
    } else {
      console.warn(
        `Tried to apply initial value for ${newSystem} ${questionDefinition?.measurementType} but could not find defaults!`
      );
      return false;
    }
  }

  /**
   * Whenever the question definition changes, if the answer is not available
   * (which likely means it is our first visit), apply the default value(s).
   */
  useEffect(() => {
    if (isUnanswered(answer)) {
      applyInitialValueFor();
    }
  }, [questionDefinition]);

  /**
   * Each element of the unitSet in an array as if it were an atomic unit
   */
  const submeasures = useMemo(() => {
    if (!unitSet) return null;
    return toAtomArray(unitSet);
  }, [unitSet]);
  /**
   * Each part of the answer's values, or a filler 0, such that every
   * element of the unitSet has a value available.
   */
  const answerParts = useMemo(() => {
    if (Array.isArray(submeasures)) {
      return submeasures.map((sm, i) => answer?.values?.[i] ?? { value: 0 });
    } else {
      return [];
    }
  }, [submeasures, answer]);
  /**
   * A combined record that links the unit names to their values
   */
  const answerByUnit: Record<string, number> = useMemo(() => {
    if (Array.isArray(submeasures)) {
      return Object.fromEntries(
        submeasures.map((sm, i) => {
          return [sm.unit, +(answer?.values?.[i]?.value ?? 0)];
        })
      );
    } else {
      return {};
    }
  }, [submeasures, answer]);

  function applyFocusIfOnlyInput() {
    if (
      submeasures?.length === 1 &&
      !submeasures[0].listExhaustively &&
      !!firstInput.current
    ) {
      firstInput.current.focus();
    }
  }

  useEffect(() => {
    applyFocusIfOnlyInput();
  }, [submeasures]);

  /**
   * Change the system-of-measure and reset the current answer appropriately.
   */
  function resetAnswerForToggle(newSystem?: SystemOfMeasure) {
    const appliedDefault = applyInitialValueFor(newSystem);
    if (!appliedDefault) {
      // This should only happen when a unit doesn't have any kind of default
      // value defined -- then the above fn doesn't send an answer update and
      // returns false. So instead we apply an update with an empty answer as a
      // means to force the answer toggle to take place.
      updateAnswer({
        isMulti: true,
        values: [],
        system: newSystem,
      });
    }
  }

  function updateAnswerFromNumberInput(
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) {
    const newValues = answerParts.slice();
    const inputValue = parseFloat(event.target.value);

    if (isNaN(inputValue)) return; // Ignore invalid input

    // If the question has a step size, we round based on that instead of using
    // the default. Note that having steps that aren't integers or reciprocals
    // of integers (such as 2.5 = 5/2 or 0.4 = 2/5) may have undefined behavior.
    const step = submeasures?.[index]?.step ?? 1;
    const adjustedValue = step
      ? Math.round(inputValue / step) * step
      : Math.round(inputValue);

    newValues[index] = {
      value: clamp(
        adjustedValue,
        submeasures?.[index]?.min ?? 0,
        submeasures?.[index]?.max ?? Number.MAX_SAFE_INTEGER
      ),
      unit: submeasures?.[index]?.unit,
    };

    updateAnswer({
      // id: questionDefinition.id,
      isMulti: true,
      system,
      values: newValues,
    });
  }

  function setAnswerFromPicker(pickerMap: Record<string, any>) {
    if (!TREAT_ALL_MEASUREMENTS_AS_MULTI) {
      throw new Error(
        "Picker does not support 'singular' style answer emitting"
      );
    }
    if (!Array.isArray(submeasures)) {
      throw new Error(
        `Submeasures not an array, measurement definition is broken`
      );
    }
    // TODO: this doesn't support a blended view of both pickers and numbers
    updateAnswer({
      // id: questionDefinition.id,
      isMulti: true,
      system,
      values: submeasures.map((m, i) => ({
        value: pickerMap[m.unit],
        unit: m.unit,
      })),
    });
  }

  const MAX_EXHAUSTIVE = 500;

  function exhaustiveFor(measure: AtomicUnit) {
    let max = measure.max ?? 100;
    const min = measure.min ?? 0;
    const step = measure.step ?? 1;

    if (max - min > MAX_EXHAUSTIVE) {
      console.error(
        `Measure is attempting to delineate ${
          max - min
        } options, too many for DOM`
      );
      max = min + MAX_EXHAUSTIVE;
    }

    // Determine how many decimal places the step has so we can attempt to
    // limit the stringified version to that many.
    // In practice this shouldn't usually be relevant but could be an issue if
    // we ever have values that have floating-point precision issues.
    const decimalLength =
      step > 0 && step < 1 ? step.toString(10).length - 2 : 0;

    return Array.from(
      { length: Math.ceil((max - min) / step) + 1 },
      (_, i) => +(min + i * step).toFixed(decimalLength)
    );
  }

  function labelFor(system: SystemOfMeasure) {
    if (!unitSet) return "--";
    const measureForSystem =
      KNOWN_MEASUREMENT_TYPES[questionDefinition.measurementType][system];
    if (!measureForSystem) return "--";
    if (measureForSystem.isTuple) {
      // TODO: the general case here gets iffy, hardcoding for now
      const unitLabels = measureForSystem.unitLong
        ? measureForSystem.unitLong.map(
            (unitLong, i) =>
              `${t(
                measureForSystem.unitTranslationIds?.[i]?.[1],
                unitLong
              )} (${t(
                measureForSystem.unitTranslationIds?.[i]?.[0],
                measureForSystem.unit[i]
              )})`
          )
        : measureForSystem.unit;
      return joinWithConjunction(unitLabels, ", ", "& ", true);
    } else {
      return measureForSystem.unitLong
        ? `${t(
            measureForSystem.unitTranslationIds?.[1],
            measureForSystem.unitLong
          )} (${t(
            measureForSystem.unitTranslationIds?.[0],
            measureForSystem.unit
          )})`
        : measureForSystem.unit;
    }
  }

  /* It's almost silly how easy
   */
  function makeDragsBetterIOS(e: TouchEvent) {
    e.preventDefault();
  }

  const CHANGE_ON_CLICK = 1;
  function detectIncreaseDecrease(e: MouseEvent) {
    if (submeasures?.length === 1 && !submeasures[0].listExhaustively) {
      if ((e.target as HTMLElement).tagName === "INPUT") return;
      const targetRect = (e.target as HTMLElement).getBoundingClientRect();
      const relativeHeight = (e.clientY - targetRect.y) / targetRect.height;
      const topHalf = relativeHeight < 0.5;
      if (typeof answerParts[0]?.value !== "number") return;
      const value = clamp(
        answerParts[0]?.value +
          (topHalf ? -1 * CHANGE_ON_CLICK : CHANGE_ON_CLICK),
        submeasures[0].min ?? 0,
        submeasures[0].max ?? 1000
      );
      updateAnswer({
        // id: questionDefinition.id,
        isMulti: true,
        system,
        values: [
          {
            value,
            unit: submeasures[0].unit,
          },
        ],
      });
    }
  }

  return (
    <div className="main-column measurement-base" style={{ margin: "0 auto" }}>
      {!systemOverride ? (
        <ToggleButtonGroup
          color="primary"
          value={system}
          exclusive
          style={{ width: "100%" }}
          className="measurement-system-select"
        >
          {ESTABLISHED_SYSTEMS.map((system) => (
            <ToggleButton
              style={{ flex: 1 }}
              value={system}
              onClick={() => {
                resetAnswerForToggle(system);
              }}
            >
              {labelFor(system)}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      ) : null}
      <div
        className="measurement-unit-input" /* tabIndex={-1} onKeyUp={moveWithArrowKey} */
        onClick={detectIncreaseDecrease}
        onTouchMove={makeDragsBetterIOS}
      >
        <Picker
          value={answerByUnit}
          onChange={setAnswerFromPicker}
          wheelMode="normal"
          height={160}
          className="Picker"
        >
          {Array.isArray(submeasures) ? (
            submeasures.map((m, i) => (
              <>
                {m.listExhaustively ? (
                  <Picker.Column key={m.unit} name={m.unit}>
                    {exhaustiveFor(m).map((option) => (
                      <Picker.Item
                        key={option}
                        value={option}
                        className={`exhaustive-entry ${
                          answerByUnit[m.unit] === option ? "selected" : ""
                        }`}
                      >
                        {option}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ) : (
                  <input
                    type="number"
                    pattern="\d*"
                    inputMode="decimal"
                    className="text-center"
                    max={m.max ?? 1000}
                    min={m.min ?? 0}
                    style={{
                      flex: "1 1 0%",
                      border: "0px",
                      fontSize: 22,
                    }}
                    ref={i === 0 ? firstInput : null}
                    value={answerParts[i].value ?? ""}
                    onChange={(e) => updateAnswerFromNumberInput(e, i)}
                  />
                )}
                <div
                  style={{
                    height: 36,
                    lineHeight: "36px",
                    alignSelf: "center",
                    textAlign: "center",
                    width: isPseudo || submeasures.length < 2 ? "" : "10%",
                    background: isPseudo ? "var(--evergreen)" : "",
                    padding: "0 6px",
                  }}
                  title={m.unitTranslationIds?.[0] ?? "no id"}
                >
                  {t(m.unitTranslationIds?.[0], m.unit)}
                </div>
              </>
            ))
          ) : (
            <em>Error: unknown units</em>
          )}
        </Picker>
      </div>
    </div>
  );

  // return (
  //   <div className="main-column" style={{margin: "0 auto"}}>
  //     {subunits ?
  //       subunits.map((su, i) => <div className="mb-5">
  //         <label style={{display: "block"}}>{su.label}</label>
  //         <input
  //           type="number"
  //           value={subAnswers[i].value}
  //           placeholder={su.placeholder}
  //           min={su.min ?? questionDefinition.min ?? Number.MIN_SAFE_INTEGER}
  //           max={su.max ?? questionDefinition.max ?? Number.MAX_SAFE_INTEGER}
  //           step={questionDefinition.step ?? 1}
  //           onChange={e => handleUserInput(e, i)}
  //           />
  //       </div>)
  //     : <h3 className="text-danger">Unknown unit combination: {questionDefinition.multiUnitType}</h3>}
  //   </div>
  // );
}
