import { useEffect, useMemo, useState } from "react";
import { DefaultAudioSequence } from "../../../constants/audio";
import {
  DerivedAnswerKey,
  EMPTY_ANSWER,
  GeneralAnswer,
  onlyAcceptMultiChoice,
} from "../../../models/answers";
import { buildAudioLocationListForQuestion } from "../../../models/audio";
import {
  ChoiceQuestionDefinition,
  loopIndexedAnswerId,
  QuestionDefinition,
} from "../../../models/questions";
import {
  AudioSequence,
  audioSlice,
  requestOrRegister,
} from "../../../store/slices/audio";
import { QuestionnaireDefinition } from "../../../store/slices/definitions";
import { assignOnly, safeStringify, shortOrdinal } from "../../../utils";
import { IDed } from "../../../utils/database";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import SingleQuestion from "../Partials/SingleQuestion";
import {
  contextualChoice,
  type QuestionnaireFlowModalWindow,
  type SubpageCount,
} from "../QuestionnaireFlow";

// Whether the app should attempt to halt the question's audio sequence whenever
// an answer is submitted. This is to support the hypothesis that the user no
// longer needs narration once they have made their first selection.
const STOP_AUDIO_ON_ANSWER_PROVIDED = false;

export const QuestionPage = ({
  questions,
  answers,
  questionnaire,
  activeQuestionId,
  questionHidden,
  hiddenChoices,
  loopId,
  subpage,
  openModal,
  acceptAnswer,
  completeQuestion,
  navigateBack,
  ...props
}: {
  questions: string[];
  answers: Record<string, GeneralAnswer>;
  questionnaire: QuestionnaireDefinition;
  activeQuestionId: string;
  questionHidden: Record<string, string | boolean>;
  hiddenChoices: Record<string, number[]>;
  loopId?: string;
  subpage: SubpageCount;
  openModal: (w: QuestionnaireFlowModalWindow) => void;
}) => {
  const dispatch = useAppDispatch();
  const [manuallyShown, setManuallyShown] = useState<string[]>([]);
  const questionHiddenLocal = useMemo(() => {
    let hiddenMap: Record<string, boolean> = {};
    questions.forEach((qID) => {
      if (questionHidden[qID] && !manuallyShown.includes(qID)) {
        hiddenMap[qID] = true;
      }
    });
    return hiddenMap;
  }, [questionHidden, manuallyShown]);

  const autoplayAudio = useAppSelector((s) => s.audio.autoplay);
  /** Queue up the AudioSequence for the page */
  useEffect(() => {
    if (!autoplayAudio) return;
    const firstQ = questionnaire.questions[questions[0]] as QuestionDefinition;
    if (firstQ.coreType === "multi choice anatomy") {
      return; // model handles its own sequence
    }
    const locs = buildAudioLocationListForQuestion(
      firstQ,
      hiddenChoices[questions[0]]
    );
    if (locs.length > 0) {
      requestOrRegister(locs, dispatch);
      const sequence: Partial<AudioSequence> & IDed = {
        ...DefaultAudioSequence,
        id: `question-${activeQuestionId}`,
        files: locs.map((l) => l.filename),
      };
      dispatch(audioSlice.actions.playSequence(sequence));
    }
  }, [questions, autoplayAudio]);

  function acceptAnswerInner(...args: any[]) {
    if (autoplayAudio && STOP_AUDIO_ON_ANSWER_PROVIDED) {
      dispatch(audioSlice.actions.stopAudio());
    }
    acceptAnswer(...args);
  }

  const SpecialCase_SearchByValue = [
    DerivedAnswerKey.DisambiguatedChiefComplaint,
    DerivedAnswerKey.DetailedChiefComplaint,
    DerivedAnswerKey.DisambiguatedRegionOfProblem,
  ];
  type ContextualResponseMaybe = { value: { value: any } } | null;
  function contextualResponseFor(qID: string): ContextualResponseMaybe {
    const q: QuestionDefinition | undefined = questionnaire.questions[qID];
    const response = contextualChoice(
      q.contextualResponse,
      answers,
      questionnaire.questions,
      loopId,
      subpage.loopChoiceIndex
    );
    if (response) {
      return { value: response };
    }
    return null;

    // This was a rapid-fire fix. Once we're confident in it (later today, 3/24)
    // I'll take this out...

    // if (alternateChoice)
    // if (loopId && q.contextualResponse) {
    //   // special case, we are looking at our own loop anchor, so be sure to get
    //   // the value associated with the current iteration
    //   const loopAnchorAnswer = onlyAcceptMultiChoice(answers[loopId]);
    //   const loopAnchorQuestion = questionnaire.questions[
    //     loopId
    //   ] as ChoiceQuestionDefinition;
    //   if (Array.isArray(loopAnchorQuestion?.choices)) {
    //     const choice = loopAnchorQuestion.choices[subpage.loopChoiceIndex];
    //     return { value: choice };
    //   }
    //   // if (loopAnchorAnswer) {
    //   //   const value = loopAnchorAnswer.values.find(v => v.choiceIndex === subpage.loopChoiceIndex);
    //   //   return value ? ({value}) : null;
    //   // }
    //   return null;
    // } else {
    //   const a = answers[q.contextualResponse];
    //   if (a && !a.isMulti) {
    //     if ("choiceIndex" in a.value) {
    //       const qKey = a.questionKey ?? q.contextualResponse;
    //       const contextualQuestion = questionnaire.questions[
    //         qKey
    //       ] as ChoiceQuestionDefinition;
    //       if (Array.isArray(contextualQuestion?.choices)) {
    //         if (SpecialCase_SearchByValue.includes(q.contextualResponse)) {
    //           // we are on a derived question, the choice index will not be accurate to the "original"
    //           const found = contextualQuestion.choices.find(
    //             (c) => c.value === a.value.value
    //           );
    //           if (!!found) {
    //             return { value: found };
    //           }
    //         } else {
    //           return { value: contextualQuestion.choices[a.value.choiceIndex] };
    //         }
    //       }
    //     }
    //     // fall back to just returning the answer object
    //     return a;
    //   }
    //   return null;
    // }
  }

  return (
    <>
      {questions?.map((qID, i) => (
        <div key={qID}>
          {questionHiddenLocal[qID] ? (
            <>
              <h3 className="text-center text-warning pad-for-header">
                "{qID}" hidden because {questionHiddenLocal[qID]}
                <button
                  className="btn btn-sm btn-primary"
                  onClick={(e) => setManuallyShown([...manuallyShown, qID])}
                >
                  Force Display
                </button>
              </h3>
              <pre
                className="p-2 mb-0"
                style={{
                  whiteSpace: "pre-wrap",
                  background: "#E0E0FF",
                  borderRadius: "8px",
                }}
              >
                {safeStringify(
                  assignOnly(questionnaire.questions[qID], { id: qID }, [
                    "displayWhen",
                    "skipWhen",
                  ])
                )}
              </pre>
            </>
          ) : (
            <SingleQuestion
              key={loopIndexedAnswerId(qID, subpage.loopChoiceIndex)}
              page={props}
              index={i}
              isActive={activeQuestionId === qID}
              question={questionnaire.questions[qID]}
              textOverride={
                loopId
                  ? {
                      text: questionnaire.questions[qID].text.replace(
                        "<multiplex_ordinal>",
                        shortOrdinal(subpage.loopIteration + 1)
                      ),
                    }
                  : null
              }
              answer={
                answers[loopIndexedAnswerId(qID, subpage.loopChoiceIndex)] ??
                EMPTY_ANSWER
              }
              answerId={loopIndexedAnswerId(qID, subpage.loopChoiceIndex)}
              submitAnswerFor={acceptAnswerInner}
              complete={completeQuestion}
              goBack={navigateBack}
              // containerRef={questionRefs[i]}
              hiddenChoices={hiddenChoices[qID]}
              showTrackingInfo={false}
              keywordAction={(label, content) =>
                openModal({ type: "markdown", content })
              }
              displayInfoModal={(choice) =>
                openModal({
                  type: "markdown",
                  content: choice.moreInfo,
                  imgUrl: choice.imgUrl,
                  title: choice.label ?? choice.value,
                })
              }
              contextualResponse={contextualResponseFor(qID)}
              subpage={subpage}
            />
          )}
        </div>
      ))}
    </>
  );
};
