import React from "react";
import "./beating_heart.css"

const BeatingHeart = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      className="beating-heart"
    >
      <path
        fill="#e63946"
        d="M12 21s-7.3-4.3-10-8.5c-2.3-3.6-.3-8.8 4.3-9.7 2.6-.5 5.2 1.1 5.7 2.2.5-1.1 3.1-2.7 5.7-2.2 4.6.9 6.6 6.1 4.3 9.7C19.3 16.7 12 21 12 21z"
      />
    </svg>
  );
};

export default BeatingHeart;