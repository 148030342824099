import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";
import { firestoreCollections } from "../../database/collections";
import {
  AnswerSet,
  AnswerSetSubmissionStatus,
  DEFAULT_HRI_ALGORITHM,
  GeneralAnswer,
  SpecialAnswerKeys,
} from "../../models/answers";
import { BaseUser, UserType } from "../../models/users";
import {
  initialLoadStatus,
  LoadState,
  unwrapQuerySnapshotForThunk,
  unwrapSingularSnapshotForThunk,
  WithLoadStatus,
} from "../../utils/database";
import { createSliceWithThunks } from "../../utils/redux";
import { QuestionnaireDefinition } from "./definitions";
import { MetricsState } from "./metrics";
import { patientFlowSlice, PatientFlowState } from "./patient-flow";
import { getQuestionnaireByTypeAndKind } from "./questionnaire-old";

const USE_TRUE_DELETION = false;

const answerSetAdapter = createEntityAdapter<AnswerSet, string>({
  selectId: (a: AnswerSet) => a.id ?? a.userId + "_" + nanoid(6),
});

export interface AnswersState {
  currentId: string;
  // updateNumber: number;
  // current: { [key: string]: Answer<any> };
  allSets: EntityState<AnswerSet, string> & WithLoadStatus;
  error: any;
}
const initialState: AnswersState = {
  currentId: nanoid(),
  // updateNumber: 0,
  // current: {},
  allSets: answerSetAdapter.getInitialState(initialLoadStatus()),
  error: undefined,
};

function buildCurrentAnswerSet(
  patientFlowState: PatientFlowState,
  user: BaseUser,
  submissionType: AnswerSetSubmissionStatus,
  pageAtSave: number
): AnswerSet {
  const answersWithoutUnmarshallable = JSON.parse(
    JSON.stringify(patientFlowState.answers.entities)
  );
  return {
    submitted: Date.now(),
    userId: user.id,
    organizationId: user.organizationId,
    sessionIds: [],
    answers: answersWithoutUnmarshallable,
    questionnaire: patientFlowState.questionnaireType,
    language: patientFlowState.language,
    submissionType,
    humanReadableId: {
      id: answersWithoutUnmarshallable[SpecialAnswerKeys.GeneratedReportID]
        ?.value?.value,
      algo: DEFAULT_HRI_ALGORITHM,
    },
    pageAtSave,
  };
}

function getCurrentAnswerSet(state: AnswersState): AnswerSet | undefined {
  if (!state.currentId) {
    return undefined;
  }
  return state.allSets.entities[state.currentId];
}

export const answerSetSlice = createSliceWithThunks({
  name: "answerSets",
  initialState,
  reducers: (create) => ({
    // THIS IS HANDLED BY PATIENT-FLOW!
    // updateCurrentAnswer: create.reducer((state, action: PayloadAction<Answer<any>>) => {
    //   state.updateNumber += 1;
    //   state.current[action.payload.key] = action.payload;
    // }),

    // startNewAnswerSet: create.reducer((state, action: PayloadAction<void>) => {
    //   state.updateNumber += 1;
    //   // state.current = {};
    //   state.currentId = nanoid();
    // }),

    saveCurrentAnswerSet: create.asyncThunk(
      async (submissionType: AnswerSetSubmissionStatus, thunkApi) => {
        const rootState = thunkApi.getState() as any; // typing this as RootState gives a circular dependency... I'm so confusedddd
        if (
          (rootState.auth.user as BaseUser).type == UserType.Unauthenticated
        ) {
          throw new Error(
            `Save not available for unauthenticated users (are you in flatreview or have broken credentials?)`
          );
        }
        const q = getQuestionnaireByTypeAndKind(
          rootState,
          rootState.patientFlow.questionnaireType,
          { language: rootState.patientFlow.language }
        ) as unknown as QuestionnaireDefinition;
        let lastPage = -1;
        if (q) {
          const answerListAsRecorded = Object.entries(
            rootState.patientFlow.answers.entities as Record<
              string,
              GeneralAnswer
            >
          );
          const pagesUsed = answerListAsRecorded.map(([ak, a]) => {
            const qKey = a.questionKey ?? a.key ?? ak;
            if (typeof q.pages.findLastIndex === "function") {
              return q.pages.findLastIndex(
                (p) => Array.isArray(p.questions) && p.questions.includes(qKey)
              );
            } else {
              return q.pages.findIndex(
                (p) => Array.isArray(p.questions) && p.questions.includes(qKey)
              );
            }
          });
          console.log({ pagesUsed });
          lastPage = Math.max(...pagesUsed) || -1;
        } else {
          console.warn(
            `Wasn't able to retrieve questionnaire definition during save.`
          );
        }

        const freshAnswerSet = buildCurrentAnswerSet(
          rootState.patientFlow,
          rootState.auth.user,
          submissionType,
          lastPage
        );

        const priorAnswerSet = getCurrentAnswerSet(rootState.answerSets);
        const priorSessionIds = priorAnswerSet?.sessionIds ?? [];

        if ((rootState.metrics as MetricsState).sessionId) {
          if (rootState.metrics.sessionId.startsWith("INIT")) {
            debugger;
          }
          freshAnswerSet.sessionIds = [
            ...priorSessionIds,
            rootState.metrics.sessionId,
          ];
        } else {
          debugger;
          freshAnswerSet.sessionIds = priorSessionIds.slice();
          console.error(`No metrics tracking available, cannot record!`);
        }
        console.warn(`Submitting answer set:`);
        console.warn(freshAnswerSet);
        const firebaseResponse = await firestoreCollections.answerSets
          .doc(rootState.answerSets.currentId)
          .set(freshAnswerSet);
        return {
          firebaseResponse,
          answerSet: freshAnswerSet,
          updateNumber: rootState.patientFlow.answerUpdateNumber,
        };
      },
      {
        fulfilled: (state, action) => {
          answerSetAdapter.setOne(state.allSets, action.payload.answerSet);
        },
      }
    ),

    deleteAnswerSet: create.asyncThunk(
      async (id: string, thunkApi) => {
        const rootState = thunkApi.getState() as any; // typing this as RootState gives a circular dependency... I'm so confusedddd
        if (id === rootState.answerSets.currentId) {
          throw new Error(
            "Cannot delete active answer set, state transitions not supported"
          );
        }
        const existingSet = (rootState.answerSets as AnswersState).allSets
          .entities[id];
        if (!existingSet) {
          throw new Error(`Cannot delete unloaded answer set!`);
        }
        if (USE_TRUE_DELETION) {
          const firebaseResponse = await firestoreCollections.answerSets
            .doc(id)
            .delete();
          return { firebaseResponse, id };
        } else {
          const firebaseResponse = await firestoreCollections.answerSets
            .doc(id)
            .update({
              organizationId: `DELETED:${existingSet.organizationId}`,
              deleted: true,
              deleteTime: Date.now(),
              deletingUser: rootState.auth.user?.id,
            });
          return { firebaseResponse, id };
        }
      },
      {
        fulfilled: (state, action) => {
          answerSetAdapter.removeOne(state.allSets, action.payload.id);
        },
      }
    ),

    loadAnswersForResumedSession: create.asyncThunk(
      async (answerSetId: string, thunkApi) => {
        const rootState = thunkApi.getState() as any;
        const firebaseResponse = await firestoreCollections.answerSets
          .doc(answerSetId)
          .get();
        return unwrapSingularSnapshotForThunk<AnswerSet>(firebaseResponse);
      },
      // An object containing `{pending?, rejected?, fulfilled?, settled?, options?}` second
      {
        pending: (state) => {
          // state.loading = true
          // state.allSets.loadState = LoadState.Loading;
        },
        rejected: (state, action) => {
          // state.error = action.payload ?? action.error
          // state.allSets.loadState = LoadState.Failed_Unspecified;
          console.error({ action });
        },
        fulfilled: (state, action) => {
          answerSetAdapter.setOne(state.allSets, action.payload);
          state.currentId = action.meta.arg;
        },
      }
    ),

    loadDashboardAnswerSets: create.asyncThunk(
      async (organizationId: string, thunkApi) => {
        const rootState = thunkApi.getState() as any;
        const firebaseResponse = await firestoreCollections.answerSets
          .where("organizationId", "==", organizationId)
          .get();
        return unwrapQuerySnapshotForThunk(firebaseResponse);
      },
      // An object containing `{pending?, rejected?, fulfilled?, settled?, options?}` second
      {
        pending: (state) => {
          // state.loading = true
          state.allSets.loadState = LoadState.Loading;
        },
        rejected: (state, action) => {
          // state.error = action.payload ?? action.error
          state.allSets.loadState = LoadState.Failed_Unspecified;
        },
        fulfilled: (state, action) => {
          answerSetAdapter.setMany(state.allSets, action.payload);
          state.allSets.loadState = LoadState.Complete;
          state.allSets.loadTime = Date.now();
          state.allSets.ready = true;
        },
        // settled is called for both rejected and fulfilled actions
        settled: (state, action) => {
          // state.loading = false
        },
      }
    ),
  }),
  extraReducers(builder) {
    builder.addCase(patientFlowSlice.actions.fullReset, (state, action) => {
      state.currentId = nanoid();
    });
  },
});
