import type moment from "moment";
import { KNOWN_MEASUREMENT_TYPES } from "../constants/measurements";
import { AnatomicalModel } from "./anatomical-models";
import {
  GeneralAnswer,
  MultiValuedAnswer,
  NonconformingValueKind,
  SingleValuedAnswer,
} from "./answers";
import { ArtResource } from "./assets";
import { CoreDataTypeLabel, ValueAtom } from "./core-data-types";
import { ChoiceLikeLayout, ResponseLayout } from "./layouts";
import { NumberAndUnitType, SystemOfMeasure } from "./measurements";
import {
  DisplayableEntity,
  DisplayableFormulaInJSON_Simplified,
} from "./formula";
import { QuestionnaireDefinition } from "../store/slices/definitions";

/**
 * A response choice is a single selectable option amongst a multiple choice set
 * presented to the user.
 */
export interface ResponseChoice {
  /**
   * How the choice is labeled to the user.
   */
  label?: string;
  reportLabel?: string;
  labelTranslationId?: string;
  description?: string;
  descriptionTranslationId?: string;
  art?: ArtResource;
  /**
   * The underlying value used to represent this choice. This should always be
   * in English regardless of the current language.
   */
  value: string;
  isExclusionary?: boolean;

  nonconformingKind?: NonconformingValueKind;

  displayWhen?: any[];
  skipWhen?: any[];
}

export interface NonconformingResponseChoice extends ResponseChoice {
  kind: NonconformingValueKind;
  instructionalTranslationId?: string;
  // isChoice: boolean;
}

export interface AbstractQuestionDefinition extends DisplayableEntity {
  id?: string;
  coreType: CoreDataTypeLabel;
  layout: ResponseLayout;
  isMulti: boolean;
  isComputed?: boolean;
  art?: string;
  additionalArt?: ArtResource[];
  contextualArt?: string;
  contextualResponse?: string;
  text?: string;
  translationId?: string;
  subtext?: string;
  subtextTranslationId?: string;
  instructionalTranslationId?: string;
  nonconformingResponses?: NonconformingResponseChoice[];
}

export interface NumericQuestionDefinition extends AbstractQuestionDefinition {
  min?: number;
  max?: number;
  layout: ResponseLayout.Numeric;

  // The following properties are for numeric questions designed to be displayed
  // as exhaustive lists. asList: true enables this behavior, and will render a
  // dropdown or stack-like element rather than a free input. This necessitates
  // having a finite number of options, and so expects a min & max, or it will
  // apply some sort of cap on elements rendered.
  asList?: boolean;
  // Up to 3 unit labels, representing singular, plural, and "or more" cases.
  // Unit labels are applied after each value in the render. They are used in
  // the answer's label but not its unerlying value (which remains numeric).
  units?: [string] | [string, string] | [string, string, string];
  // Whether to treat the last entry as an uncapped ceiling, using the special
  // label "X or more" or the 3rd units string. This affects both rendering and
  // the semantics of the answer being the highest value. False by default.
  orMore?: boolean;
  // Distance between list asList choices, if different than 1.
  step?: number;
}

export interface ChoiceQuestionDefinition extends AbstractQuestionDefinition {
  layout: ChoiceLikeLayout;
  choices: ResponseChoice[];
}
export function isChoiceLike(
  q: AbstractQuestionDefinition | undefined | null
): q is ChoiceQuestionDefinition {
  return (
    !!q &&
    [
      ResponseLayout.GridCards,
      ResponseLayout.StackCards,
      ResponseLayout.Dropdown,
    ].includes(q.layout)
  );
}

export interface CalendarQuestionDefinition extends AbstractQuestionDefinition {
  layout: ResponseLayout.Calendar;
  disablePast?: boolean;
  disableFuture?: boolean;
  initialDateDelta?: [number, moment.unitOfTime.DurationConstructor];
}

export interface MeasurementQuestionDefinition
  extends AbstractQuestionDefinition {
  layout: ResponseLayout.Measurement;
  measurementType: keyof typeof KNOWN_MEASUREMENT_TYPES;
  forceSystem?: SystemOfMeasure;
  initialValue?: Record<
    SystemOfMeasure,
    NumberAndUnitType | Array<NumberAndUnitType>
  >;
}
export interface PseudoMeasurementQuestionDefinition
  extends AbstractQuestionDefinition {
  layout: ResponseLayout.PseudoMeasurement;
  unit: string;
  unitTranslationId?: string;
  unitLong?: string;
  unitLongTranslationId?: string;
  initialValue?: number;
  min?: number;
  max?: number;
  step?: number;
}

export interface TextQuestionDefinition extends AbstractQuestionDefinition {
  layout: ResponseLayout.ShortAnswer;
}

export interface AnatomicalRegionQuestionDefinition
  extends AbstractQuestionDefinition {
  layout: ResponseLayout.AnatomicalRegion;
  initialView?: string;
  model?: AnatomicalModel | string;
  leafOnly?: boolean;
}

export type QuestionDefinition =
  | ChoiceQuestionDefinition
  | TextQuestionDefinition
  | NumericQuestionDefinition
  | CalendarQuestionDefinition
  | MeasurementQuestionDefinition
  | PseudoMeasurementQuestionDefinition
  | AnatomicalRegionQuestionDefinition;

export function loopIndexedAnswerId(
  baseId: string,
  index: number,
  element?: string
) {
  if (typeof element === "string") {
    return `${baseId}∋${element}`;
  }
  if (index < 0) {
    return baseId;
  } else {
    return `${baseId}§${index}`;
  }
}

export type ComputedQuestionFunction = (
  host: ComputedQuestionDefinition,
  changedId: string,
  changedAnswer: GeneralAnswer,
  allAnswers: Record<string, GeneralAnswer>,
  currentCalculationResults: Record<string, GeneralAnswer>,
  questionnaire: QuestionnaireDefinition,
  addlArgument?: any
) => GeneralAnswer | null;

export interface ComputedQuestionDefinition extends AbstractQuestionDefinition {
  id: string;
  isComputed: true;
  triggers: string[];
  hardcodedFunctionName?: string;
  hardcodedFunction?: ComputedQuestionFunction;
  hardcodedFunctionArgument?: any;
  formula: DisplayableFormulaInJSON_Simplified;
  duplicatesChoicesOf?: string;
  unit?: string;
}

export function buildAnswerFromComputedFormulaResult(
  question: ComputedQuestionDefinition,
  formulaResult: any
): GeneralAnswer | null {
  const nvcForNull =
    formulaResult === null || formulaResult?.length === 0
      ? {
          nonconformingValues: [
            { value: null, kind: NonconformingValueKind.InactiveComputation },
          ],
        }
      : {};
  if (question.isMulti) {
    if (Array.isArray(formulaResult)) {
      const result: MultiValuedAnswer<ValueAtom> = {
        key: question.id,
        isMulti: true,
        isComputed: true,
        values: formulaResult.map((x) => ({ value: x })),
        ...nvcForNull,
      };
      return result;
    } else {
      console.error(
        `Computed ${question.id} isMulti but got non-array formula result`
      );
      return null;
    }
  } else {
    if (
      typeof formulaResult === "string" ||
      typeof formulaResult === "number" ||
      !Array.isArray(formulaResult)
    ) {
      if (question.layout === ResponseLayout.PseudoMeasurement) {
        return {
          key: question.id,
          isMulti: true,
          isComputed: true,
          values:
            formulaResult === null
              ? []
              : [{ value: formulaResult, unit: question.unit ?? "" }],
          ...nvcForNull,
        };
      }
      const result: SingleValuedAnswer<ValueAtom> = {
        key: question.id,
        isMulti: false,
        isComputed: true,
        value: { value: formulaResult },
        ...nvcForNull,
      };
      return result;
    } else {
      console.error(
        `Computed ${question.id} is NOT multi but got array formula result`
      );
      return null;
    }
  }
}
