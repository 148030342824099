import { useState } from "react";
import { safeStringify } from ".";

// todo: move, it's technically a component 🙃
export function JSObjectDump({
  obj,
  style,
  classes,
  startHidden = false,
  padding = 2,
  omit = [],
}: {
  obj: any;
  style?: React.CSSProperties;
  classes?: string;
  startHidden?: boolean;
  padding?: number;
  omit?: string[];
}) {
  const [show, setShow] = useState(!startHidden);
  function toggle(e: React.MouseEvent): void {
    if (e.shiftKey) {
      setShow(!show);
    }
  }

  return (
    <pre
      className={classes ?? ""}
      style={{
        whiteSpace: "pre-wrap",
        background: "#E0E0FF",
        borderRadius: "8px",
        padding: "0.5em",
        ...style,
      }}
      onClick={toggle}
      title="Shift+Click to show/hide"
    >
      {show
        ? safeStringify(obj, padding, omit)
        : "[hidden - shift+click to show]"}
    </pre>
  );
}
