import React from "react";
import { BuildAnnotatedWindow } from "../../Auth/Login";
import { VERSION_LABEL } from "../../../constants/config";

const Footer = () => {
  const build = (window as BuildAnnotatedWindow).__BUILD;
  function showDebugDialog() {
    alert(
      `LiteraSeed version ${VERSION_LABEL}-#${build.hash}${
        build.dirty ? "!" : ""
      } \n[${build.time}]`
    );
  }

  return (
    <footer
      style={{
        textAlign: "center",
        padding: "1rem",
      }}
      onDoubleClick={showDebugDialog}
    >
      {new Date().getFullYear()} &copy; LiteraSeed, Inc.
    </footer>
  );
};

export default Footer;
