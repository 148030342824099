import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DefaultAudioSequence } from "../../../constants/audio";
import {
  EMPTY_ANSWER,
  GeneralAnswer,
  MultiValuedAnswer,
  NonconformingValueKind,
  onlyAcceptMultiChoice,
} from "../../../models/answers";
import { buildAudioLocationListForQuestion } from "../../../models/audio";
import { IndexedString } from "../../../models/core-data-types";
import { ResponseLayout } from "../../../models/layouts";
import {
  loopIndexedAnswerId,
  QuestionDefinition,
} from "../../../models/questions";
import {
  AudioSequence,
  audioSlice,
  requestOrRegister,
} from "../../../store/slices/audio";
import { QuestionnaireDefinition } from "../../../store/slices/definitions";
import { IDed } from "../../../utils/database";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { CTA } from "../../UI/buttons/CTA";
import { SmallModalDialog } from "../../UI/dialogs/SmallModalDialog";
import SingleQuestion from "../Partials/SingleQuestion";
import type { QuestionnaireFlowModalWindow, SubpageCount } from "../QuestionnaireFlow";

export const HybridQuestionSummaryPage = ({
  questions,
  answers,
  questionnaire,
  activeQuestionId,
  hiddenChoices,
  openModal,
  setSubpage,
  advanceOneNoUpdate,
  acceptAnswer,
  completeQuestion,
  navigateBack,
  ...props
}: {
  questions: string[];
  answers: Record<string, GeneralAnswer>;
  questionnaire: QuestionnaireDefinition;
  activeQuestionId: string;
  hiddenChoices: Record<string, number[]>;
  openModal: (w: QuestionnaireFlowModalWindow) => void;
  advanceOneNoUpdate: () => void;
  setPage: (
    n: number,
    refresh?: boolean,
    forward?: boolean,
    needsSubpageUpdates?: boolean
  ) => void;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [qID, question]: [string, QuestionDefinition | null] = useMemo(() => {
    if (questions.length === 1) {
      const qID = questions[0];
      const qObj = questionnaire.questions[qID];
      if (qObj && qObj.isMulti && qObj.layout === ResponseLayout.StackCards) {
        return [qID, qObj];
      }
    }
    console.error(
      `Hybrid question summaries must contain a single useMulti:true with stack layout!`
    );
    return ["error", null];
  }, [questions, questionnaire]);

  const autoplayAudio = useAppSelector((s) => s.audio.autoplay);
  /** Queue up the AudioSequence for the page */
  useEffect(() => {
    if (!autoplayAudio) return;
    const firstQ = questionnaire.questions[questions[0]] as QuestionDefinition;
    if (firstQ.coreType === "multi choice anatomy") {
      return; // model handles its own sequence
    }
    const locs = buildAudioLocationListForQuestion(
      firstQ,
      hiddenChoices[qID] ?? []
    );
    if (locs.length > 0) {
      requestOrRegister(locs, dispatch);
      const sequence: Partial<AudioSequence> & IDed = {
        ...DefaultAudioSequence,
        id: `question-${activeQuestionId}`,
        files: locs.map((l) => l.filename),
      };
      dispatch(audioSlice.actions.playSequence(sequence));
    }
  }, [questions, autoplayAudio]);

  const [answerToConfirmDeletion, setAnswerToConfirmDeletion] = useState<
    undefined | [MultiValuedAnswer<IndexedString>, string]
  >(undefined);
  const answer: MultiValuedAnswer<IndexedString> | undefined =
    onlyAcceptMultiChoice(answers[qID]);
  const followupPages = questionnaire.pages.filter((p) => p.loopId === qID);
  const followupQuestions = followupPages.map((p) => p.questions ?? []).flat();

  function simpleClear(a: GeneralAnswer): GeneralAnswer {
    if (a.isMulti) {
      return {
        ...a,
        values: [],
        nonconformingValues: undefined,
      };
    } else {
      return {
        ...a,
        value: { value: null },
        nonconformingValues: undefined,
      };
    }
  }

  function acceptAnswerInner(
    newAnswer: MultiValuedAnswer<IndexedString>,
    key: string
  ) {
    if (autoplayAudio) {
      dispatch(audioSlice.actions.stopAudio());
    }
    const oldChoices = answer?.values?.map((v) => v.choiceIndex) ?? [];
    const newChoices = newAnswer.values.map((v) => v.choiceIndex);
    const removed = (answer?.values ?? []).filter(
      (v) => !newChoices.includes(v?.choiceIndex)
    );
    if (removed.length > 0) {
      // the action removed at least 1 value
      setAnswerToConfirmDeletion([newAnswer, key]);
      return;
    }
    const added = newAnswer.values.filter(
      (v) => !oldChoices.includes(v.choiceIndex)
    );
    if (added.length > 0) {
      if (added.length > 1) {
        console.error(
          "Added more than one answer at a time! Can only nav to first",
          added
        );
      }
      acceptAnswer(newAnswer, key);

      setTimeout(() => {
        setSubpage((counts: SubpageCount) => ({
          ...counts,
          loopIteration: 0,
          loopLength: 1,
          loopPageCount: followupPages.length,
          loopPageIndex: 0,
          loopChoiceIndex: added[0].choiceIndex,
        }));
        advanceOneNoUpdate();
      }, 300);
      return;
    }

    if (Array.isArray(newAnswer.nonconformingValues)) {
      const newFreeform = newAnswer.nonconformingValues.find(
        (v) => v.kind === NonconformingValueKind.UserDefined
      );
      const oldFreeform = answer?.nonconformingValues?.find(
        (v) => v.kind === NonconformingValueKind.UserDefined
      );
      if (
        !!newFreeform &&
        (!oldFreeform || newFreeform.value !== oldFreeform.value)
      ) {
        acceptAnswer(newAnswer, key);
        return;
      }
    }

    console.warn(
      `Got answer update with no additions or removals:`,
      answer,
      newAnswer
    );
    acceptAnswer(newAnswer, key);
  }

  function continueDeletion(a: MultiValuedAnswer<IndexedString>, key: string) {
    const newChoices = a.values.map((v) => v.choiceIndex);
    const removed = (answer?.values ?? []).filter(
      (v) => !newChoices.includes(v?.choiceIndex)
    );
    if (removed.length > 1) {
      console.error(
        "Removing more than 1 at once! System can handle this but strange.",
        removed
      );
    }
    acceptAnswer(a, key);
    // then delete all the matching followups (TODO: this should be batched)
    removed.forEach((r) => {
      followupQuestions.forEach((fq) => {
        const answerKey = loopIndexedAnswerId(fq, r.choiceIndex);
        if (answerKey in answers) {
          acceptAnswer(simpleClear(answers[answerKey]), answerKey);
        }
      });
    });
    // close the modal
    setAnswerToConfirmDeletion(undefined);
  }

  const blank = "___";
  function quickText(a: GeneralAnswer | undefined): string {
    if (!a) return blank;
    if (a.isMulti) {
      const joined = a.values.map((v) => `${v.value ?? ""}`).join("; ");
      return joined.length > 0 ? joined : blank;
    } else {
      return `${a.value.value ?? blank}`;
    }
  }
  const overrideForSummaries = useMemo(() => {
    if (!answer || !question) return {};
    return {
      responseSubtexts: question.choices.map((c, ci) => {
        const rowKeys = followupQuestions.map((key) =>
          loopIndexedAnswerId(key, ci)
        );
        const rowAnswers = rowKeys.map((key) => quickText(answers[key]));
        if (rowAnswers.some((s) => s !== blank)) {
          return rowAnswers.join(" • ");
        } else {
          return null;
        }
      }),
    };
  }, [answers]);

  return question ? (
    <>
      <SingleQuestion
        key={qID}
        page={props}
        isActive={activeQuestionId === qID}
        question={question}
        answer={answers[qID] ?? EMPTY_ANSWER}
        textOverride={overrideForSummaries}
        answerId={qID}
        submitAnswerFor={acceptAnswerInner}
        complete={completeQuestion}
        goBack={navigateBack}
        // containerRef={questionRefs[i]}
        hiddenChoices={hiddenChoices[qID]}
        showTrackingInfo={false}
        keywordAction={(label, content) =>
          openModal({ type: "markdown", content })
        }
        displayInfoModal={(choice) =>
          openModal({
            type: "markdown",
            content: choice.moreInfo,
            imgUrl: choice.imgUrl,
            title: choice.label ?? choice.value,
          })
        }
        contextualResponse={null}
      />
      {answerToConfirmDeletion ? (
        <SmallModalDialog quit={() => setAnswerToConfirmDeletion(undefined)}>
          <h2>
            {t("Q_GEN045", "This will delete your answers. Are you sure?")}
          </h2>

          <CTA onClick={() => setAnswerToConfirmDeletion(undefined)}>
            {t("R_GEN283", "Keep")}
          </CTA>
          <CTA
            onClick={() => continueDeletion(...answerToConfirmDeletion)}
            style={{ backgroundColor: "var(--salmon-red)" }}
          >
            {t("R_GEN282", "Delete")}
          </CTA>
        </SmallModalDialog>
      ) : null}
    </>
  ) : (
    <h3>This question could not be shown</h3>
  );
};
