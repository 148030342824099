import { ResponseLayout } from "../models/layouts";

/**
 * The app version label to present to users. This is only meaningful in a
 * human-to-human sense: to the app, it's just a displayed string.
 * 
 * This should move somewhere more automated at some point.
 */
export const VERSION_LABEL = "1.3.001";

/**
 * Whether clicking the "background" area around/behind a modal dialog should
 * be interpreted as a close action for that dialog.
 */
export const ALLOW_BACKDROP_TO_CLOSE_MODAL = true;


/**
 * The response layout types that can treat receiving an answer as motivation to
 * automatically advance to the next page. This only controls which types have
 * such functionality, whether automatic progression is *enabled* is defined
 * by the state value simulator.autoProgressScalars. Additionally, only non
 * multi-select response types could ever auto progress.
 */
export const AUTO_ADVANCE_TYPES: ResponseLayout[] = [
  ResponseLayout.GridCards,
  ResponseLayout.StackCards,
];

/**
 * If automatic progression is enabled, this is the delay (in ms) between the
 * user's submission of the answer and the queued navigation.
 */
export const AUTO_ADVANCE_TIMEOUT = 1200;

/**
 * Whether a response layout is considered "complex" and should suppress any
 * extraneous UI elements. This used to control more but it currently stops
 * the header from rendering the emerald backdrop or the question art.
 */
export const COMPLEX_UI_TYPES: ResponseLayout[] = [];


/**
 * The maximum amount of time (in ms) you can spend without taking actions
 * before the app forces you to logout.
 */
export const INACTIVITY_LIMIT = 24 * 60 * 60 * 1000;
/**
 * How long (in ms) before the inactivity timeout that the warning dialog
 * should appear, informing the user that they will be logged out shortly. If
 * this is ≤0, the dialog will not display and the user will be logged out
 * immediately.
 */
export const INACTIVITY_WARNING_LENGTH = 1 * 60 * 1000;
