import { useEffect, useRef, useState } from "react";

// import '../../../components/Questionnaire/Responses/AnatomicalModel/view-assets/female_whole_body_covered.svg';

/*
 * MAJOR CAVEAT #1
 * In create-react-app v5.0, dynamic SVG imports are not possible by default due
 * to how the webpack config is set. See these links for a discussion:
 *
 * - https://github.com/webpack/webpack/discussions/15117#discussioncomment-1924353
 * - https://ryanhutzley.medium.com/dynamic-svg-imports-in-create-react-app-d6d411f6d6c6
 *
 * For now I have merely taken the temporary solution of manually overwriting
 * my CRA module's webpack.config.js to comment out the issuers section.
 * However this obviously won't scale to multiple users or updates to our tools.
 * I would like to defer to post-prototype phase to make a bigger decision --
 * likely we will use rewire in the short-term until we eventually eject and
 * can change the behavior directly in the long term.
 *
 * As of 2025-01-15, on react-scripts v5.0.0 (check your package-lock.json) you
 * can fix this by opening /node_modules/react-scripts/config/webpack.config.js
 * and commenting out lines 408-410, which should start with issuer: { and end
 * with the matching } curly braces.
 */

export const DynamicSVG = ({ src, prefix = "", ...otherProps }) => {
  const SVGImport = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const tryImport = async () => {
      try {
        const url = `${prefix}${src}`;
        /*
         * MAJOR CAVEAT #2
         * Webpack's dynamic imports are fairly constrained and actually have a
         * fairly major impact to what gets bundled and packaged into a build.
         * The most important thing here is that the import MUST have some sort
         * of prefixing to the path to limit the possibilities of the import.
         *
         * https://webpack.js.org/api/module-methods/#dynamic-expressions-in-import
         *
         * For now we constrain this to cosomponents, but it would probably be
         * better for performance/sizes if we limited the scope further, e.g.
         * we ONLY use this for AreaSelector areas  that could entirely be
         * built-in to the import path.
         */
        const { ReactComponent: componentImport } = await import(
          `../../${url}`
        );
        // const componentImport = await import(url);
        if (!componentImport) {
          throw new Error(
            `Import of '${url}' did not return a React Component, verify the URL relative`
          );
        }
        SVGImport.current = componentImport;
      } catch (err) {
        // debugger;
        throw err;
      } finally {
        setLoading(false);
      }
    };
    tryImport();
  }, [src]);

  if (!loading && SVGImport.current) {
    const { current: SVGElement } = SVGImport;
    return <SVGElement {...otherProps} />;
  }

  return null;
};
