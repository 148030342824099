import { useTranslation } from "react-i18next";
import {
  getAudioLocationFromId,
  KeyTranslationIDs,
} from "../../../models/audio";
import React from "react";
import { useAppSelector } from "../../../utils/hooks";

const DEFAULT_UNCERTAIN_ART = "/images/response_choice_do_not_know_fm_v1.png";

export const UncertainButton = ({
  labelOverride,
  selected,
  translationId = KeyTranslationIDs.Unsure,
  instructionalTranslationId = KeyTranslationIDs.Instructions_Unsure,
  art,
  ...props
}: {
  labelOverride?: string;
  selected?: boolean;
  translationId?: string;
  instructionalTranslationId?: string;
  art?: string;
  props?: React.PropsWithoutRef<"div">;
}) => {
  const { t } = useTranslation();

  /**
   * Note that these audio functions are only used to determine if relevant
   * audio is playing. The question and/or response type component is still the
   * one responsible for queueing it, since there are various rules about which
   * should play (notably that the instructions will probably only play on
   * the very first question with an unsure, which is set by the JSON).
   */
  const activeAudio = useAppSelector((s) => s.audio.activeAudio);
  const audioFiles = [instructionalTranslationId, translationId].map(
    (tid) => getAudioLocationFromId(tid)?.filename
  );
  const isPlaying =
    !!activeAudio.playing && audioFiles.includes(activeAudio.filename);

  return (
    <div
      className={`uncertain-button ${selected ? "selected" : ""} ${
        isPlaying ? "pulsing" : ""
      }`}
      {...props}
    >
      <img src={art ?? DEFAULT_UNCERTAIN_ART} />
      <span>{labelOverride ?? t(translationId)}</span>
    </div>
  );
};
