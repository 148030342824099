import moment from "moment";
import { capitalizeByWord, capitalizeFirstLetter } from "..";

const Equals = (_, a, b) => {
  if (Array.isArray(a) && Array.isArray(b)) {
    // 1-level comparison, not deep
    return a.length === b.length && a.every((e, i) => b[i] === e);
  } else if (Array.isArray(a) && a.length === 1) {
    return a[0] === b;
  } else if (Array.isArray(b) && b.length === 1) {
    return b[0] === a;
  }
  return a === b;
}
const Not = (fn) => {
  return (_, a, b) => !(fn(_, a,b));
}

function getAssociatedResponses (answerWithKey, screenerDef) {
  if (!screenerDef || !screenerDef.questions) {
    throw new Error("Use of this operator requires screener definition context");
  }
  const question = screenerDef.questions[answerWithKey.key];
  if (!question) {
    console.error(`Could not find question associated with answer ${answerWithKey.key}`);
    return null;
  }
  if (question.display !== "multi_options") {
    if (answerWithKey.selected?.length === 1) {
      return question.responses[answerWithKey.selected[0]];
    }
    return null;
  } else {
    return answerWithKey.selected.map(i => question.responses[i]);
  }
}
function getResponseProperty (answer, context, propName) {
  if (!answer) {
    return undefined;
  }
  const response = getAssociatedResponses(answer, context);
  if (typeof response === "object" && response !== null && propName in response) {
    return response[propName];
  } else {
    console.error(`Could not find ${propName} in ${response?.key} (${quickStr(response)})`);
    return undefined;
  }
}
function getExecutionContextName (variables) {
  return variables["##contextualName"] || "«unknown eval»";
}

/**
 * Process a date string with an appropriate format to avoid issues on Safari
 * 
 * MomentJS's default parsing behavior tries ISO8601 and RFC2822 before falling
 * back to the browser's built-in `new Date(str)`. On Chrome and Firefox, this
 * constructor handles ambiguous locale-specific formats fine, but Safari
 * instead chooses to return Invalid Date. Since our standard calendar input
 * uses MM-DD-YYYY strings as its canonical storage format, this breaks all our
 * date logic on Safari, so instead we manually apply that parsing format here.
 * 
 * In the future we may wish to switch to using Date or moment (/date-utils?)
 * objects directly in the global state, but that's just a TODO for now.
 *
 * @returns moment object
 */
function safariSafeDateParse (dateString) {
  const US_STYLE_DATE = /^\d\d-\d\d-\d\d\d\d$/g;
  if (typeof dateString === "string" && US_STYLE_DATE.test(dateString)) {
    return moment(dateString, "MM-DD-YYYY");
  } else {
    return moment(dateString);
  }
}

const LOG_FLAG = "##logAll";

function rejectNonNumbersForArithmeticInfix (config) {
  return ({
    ...config,
    infix: (ctx, a, b) => {
      if (typeof a !== "number" || Number.isNaN(a) || typeof b !== "number" || Number.isNaN(b)) {
        return null;
      }
      return config.infix(ctx, a, b);
    }
  });
}

export const Operator = {
  // arithmetic
  PLUS: rejectNonNumbersForArithmeticInfix({infix: (context, left, right) => (+(left || 0)) + (+(right || 0))}),
  MINUS: rejectNonNumbersForArithmeticInfix({infix: (context, left, right) => left - right}),
  MULTIPLIED_BY: rejectNonNumbersForArithmeticInfix({infix: (_, a, b) => a * b}),
  DIVIDED_BY: rejectNonNumbersForArithmeticInfix({infix: (_, a, b) => a / b}),
  MODULO: rejectNonNumbersForArithmeticInfix({infix: (_, a, b) => a % b}),
  SUM: {listPrefix: (_, list) => list.reduce((a, b) => (+(a || 0)) + (+(b || 0)), 0)},
  ROUND: {prefix: (_1, _2, v) => Math.round(v), infix: (_, v, d) => {
    if (!Number.isSafeInteger(d) || d < 0 || d > 15) throw new Error("Places limited to integers [0, 15], got: " + d);
    const factor = Math.pow(10, d);
    return Math.round(v * factor) / factor;
  }},

  // logical
  IS: {infix: Equals},
  IS_EXACTLY: {infix: (_, a, b) => a === b},
  IS_NOT: {infix: Not(Equals)},
  GREATER_THAN: {infix: (_, a, b) => a > b},
  GREATER_THAN_OR_EQUAL: {infix: (_, a, b) => a >= b},
  LESS_THAN: {infix: (_, a, b) => a < b},
  LESS_THAN_OR_EQUAL: {infix: (_, a, b) => a <= b},
  BETWEEN_INCLUSIVE: {
    infix: (_, x, range) => {
      if (!Array.isArray(range) || range.length !== 2) {
        console.error("Illegal range: "+quickStr(range));
        return false;
      }
      return x >= range[0] && x <= range[1];
    }
  },
  BETWEEN_EXCLUSIVE: {
    infix: (_, x, range) => {
      if (!Array.isArray(range) || range.length !== 2) {
        console.error("Illegal range: "+quickStr(range));
        return false;
      }
      return x > range[0] && x < range[1];
    }
  },
  AND: {
    infix: (_, a, b) => a && b,
    listPrefix: (_, list, vars) => {
      console.warn(`List usage of AND is deprecated, use ALL_OF. (in ${getExecutionContextName(vars)})`);
      return list.reduce((a, b) => a && b, true) // non-shortcutting!
    }
  },
  ALL_OF: {listPrefix: (_, list) => list.reduce((a, b) => a && b, true)},
  OR: {
    infix: (_, a, b) => a || b,
    listPrefix: (_, list, vars) => {
      console.warn(`List usage of OR is deprecated, use ANY_OF. (in ${getExecutionContextName(vars)})`);
      return list.reduce((a, b) => a || b, false) // non-shortcutting!
    }
  },
  ANY_OF: {listPrefix: (_, list) => list.reduce((a, b) => a || b, false)},

  AS_BOOLEAN: {prefix: (ctx, _, v) => getResponseProperty(v, ctx, "booleanValue"), requiresVarMetadata: true},
  NOT: {prefix: (_1, _2, v) => !v},
  AS_BOOLEAN_NOT: {prefix: (ctx, _, v) => !getResponseProperty(v, ctx, "booleanValue"), requiresVarMetadata: true},
  SWITCH: {listPrefix: (_, list, vars) => {
    const hasDefault = list.length % 2 === 1;
    const defaultValue = hasDefault ? list[list.length - 1] : null;
    for (let i = 0; i < list.length - 1; i += 2) {
      if (list[i]) return list[i + 1];
    }
    return defaultValue;
  }},

  // string
  CONCAT: {
    infix: (_, a, b) => `${a}${b}`,
    listPrefix: (_, list) => {
      return list.map(x => `${x}`).join("")
    }
  },
  UPPERCASE: {prefix: (_1, _2, v) => `${v}`.toLocaleUpperCase()},
  LOWERCASE: {prefix: (_1, _2, v) => `${v}`.toLocaleLowerCase()},
  CAPITALIZE_FIRST: {prefix: (_1, _2, v) => capitalizeFirstLetter(`${v}`)},
  CAPITALIZE_WORDS: {prefix: (_1, _2, v) => capitalizeByWord(`${v}`)},
  STRING_CONTAINS: {infix: (_, a, b) => {
    if (typeof a === "string" && typeof b === "string") {
      return a.includes(b);
    }
    return false;
  }},
  SLICE: {listPrefix: (_, list) => {
    if (list.length > 3) console.error("SLICE operator had more than 3 arguments. Ignoring extra, but this could be an error!");
    if (!Array.isArray(list[0]) && typeof list[0] !== "string") throw new Error("SLICE first argument must be string or array!");
    if (!Number.isSafeInteger(list[1])) {
      return list[0].slice();
    } else if (!Number.isSafeInteger(list[2])) {
      return list[0].slice(list[1]);
    }
    return list[0].slice(list[1], list[2]);
  }},

  // answer meta stuff
  ANSWERED: {prefix: (_1, _2, a) => a && (a.value?.value !== null || a.values?.length > 0 || a.nonconformingValues?.length > 0), requiresVarMetadata: true},
  // SKIP_REASON: {prefix: (_1, _2, a) => a && a.skipReason, requiresVarMetadata: true},
  // these should be limited to the right cardinalities
  RESPONSE_INDEX: {prefix: (ctx, _, a) => a?.value?.choiceIndex ?? -1, infix: (ctx, a, i) => a?.values?.[i]?.choiceIndex ?? -1, requiresVarMetadata: true},
  RESPONSE_AT: {infix: (ctx, a, i) => {
    return a?.values?.[i]?.value;
  }, requiresVarMetadata: true},
  RESPONSE_COUNT: {prefix: (ctx, _, a) => a?.values?.length || 0, requiresVarMetadata: true},
  CONTAINS_RESPONSE_INDEX: {infix: (ctx, a, el) => Array.isArray(a?.values) && a?.values?.some(v => v.choiceIndex === el) > -1, requiresVarMetadata: true},

  // array stuff
  IS_EMPTY: {prefix: (ctx, _, a) => Array.isArray(a) ? a.length === 0 : (a === null || a === undefined)},
  CONTAINS: {infix: (ctx, arr, el, vars) => {
    if (arr && !Array.isArray(arr)) console.error(`[${getExecutionContextName(vars)}] CONTAINS received a non-array value ${arr}, are you sure this is a value which can be a list?`);
    return Array.isArray(arr) && arr.indexOf(el) > -1
  }},
  INTERSECTS: {infix: (ctx, a, b) => Array.isArray(a) && Array.isArray(b) && a.some(x => b.indexOf(x) > -1)},
  IS_SUBSET_OF: {infix: (ctx, a, b) => Array.isArray(a) && Array.isArray(b) && a.every(x => b.indexOf(x) > -1)},
  LENGTH: {prefix: (ctx, _, arr) => Array.isArray(arr) ? arr.length : 0},
  ELEMENT_AT: {infix: (ctx, arr, i) => Array.isArray(arr) ? arr[i] : undefined},

  // date relations
  DAYS_TO_TODAY: {prefix: (ctx, _, d) => {
    if (d) {
      
      return moment().startOf('day').diff(safariSafeDateParse(d), "day", true)
    }
    return null;
  }},
  WEEKS_TO_TODAY: {prefix: (ctx, _, d) => {
    if (d) {
      return moment().startOf('day').diff(safariSafeDateParse(d), "week", true)
    }
    return null;
  }},
  MONTHS_TO_TODAY: {prefix: (ctx, _, d) => {
    if (d) {
      return moment().startOf('day').diff(safariSafeDateParse(d), "month", true)
    }
    return null;
  }},
  DATE_ADD: {infix: (ctx, date, diff) => {
    if (date && Array.isArray(diff)) {
      return safariSafeDateParse(date).add(...diff).valueOf()
    }
    return null;
  }},
  FIXED_DIGITS: {infix: (ctx, a, b) => {
    if (typeof a === "number" && Number.isInteger(b) && b >= 0 && b < 100) {
      return Number.parseFloat(a.toFixed(b));
    }
    return null;
   }},

  SCREENER_USED: {prefix: (ctx, _, d) => ctx?.screener === d},
  DEBUGGER: {prefix: (ctx, _, x) => {
    debugger;  // KEEP DEBUGGER
    return x;
  }, prepare: () => {
    debugger;
  }},
  LOG_ALL: {
    prepare: (ctv, vars, expr) => {
      console.error(`~~~~~~ Start of evaluator logging for [ ${getExecutionContextName(vars)} ]`);
      vars[LOG_FLAG] = true;
    },
    prefix: (ctx, _, x, vars) => {
      console.error(`~~~~~~ End of evaluator logging for [ ${getExecutionContextName(vars)} ]`);
      vars[LOG_FLAG] = false;
      return x;
    }
  },
  COMMENT: {infix: (ctx, a, b) => a},

  HAS_AT_LEAST_1_NONCONFORMING: {prefix: (ctx, _, a) => {
    return Array.isArray(a?.nonconformingValues) && a.nonconformingValues.length > 0;
  }, requiresVarMetadata: true},
  CONTAINS_NONCONFORMING: {infix: (ctx, a, b) => {
    return Array.isArray(a?.nonconformingValues) && a.nonconformingValues.some(ncv => ncv.value === b);
  }, requiresVarMetadata: true},

  // hard-coded formulas for specific cases should go here:
  // (none yet)
}

// aliases
Operator["+"] = Operator.PLUS;
Operator["-"] = Operator.MINUS;
Operator["*"] = Operator.MULTIPLIED_BY;
Operator["×"] = Operator.MULTIPLIED_BY;
Operator["÷"] = Operator.DIVIDED_BY;
Operator["="] = Operator.IS;
Operator["EQUALS"] = Operator.IS;
Operator["≠"] = Operator.IS_NOT;
Operator["!="] = Operator.IS_NOT;
Operator[">"] = Operator.GREATER_THAN;
Operator[">="] = Operator.GREATER_THAN_OR_EQUAL;
Operator["≥"] = Operator.GREATER_THAN_OR_EQUAL;
Operator["<"] = Operator.LESS_THAN;
Operator["<="] = Operator.LESS_THAN_OR_EQUAL;
Operator["≤"] = Operator.LESS_THAN_OR_EQUAL;
Operator["&"] = Operator.AND;
Operator["∧"] = Operator.AND;
Operator["∨"] = Operator.OR;
Operator["#"] = Operator.RESPONSE_INDEX;
Operator["CONTAINS_ANY"] = Operator.INTERSECTS;
Operator["IF_THEN"] = Operator.SWITCH;

const extractValue = (answer, context) => {
  return (context?.schema_version === 2 || context?.effectiveSchema === 2) ? extractValueV2(answer) : extractValueV1(answer);
}
const extractValueV1 = answer => {
  if (
    typeof answer === 'object' &&
    answer !== null &&
    (
      "value" in answer
      // (typeof answer.options === 'object' && Array.isArray(answer.selected))
      // || answer.isOutcome === true
    )
  ) {
    return answer.value;
  }
  // console.warn(`${answer} does not appear to be a variable`);
  return answer;
}

const Sentinels = new Map([
  ["NULL", null]
]);

const extractValueV2 = answer => {
  if (typeof answer === 'object' && answer !== null) {
    if (answer.isMulti) {
      if (Array.isArray(answer.values)) {
        return answer.values.map(v => v.value);
      }
    } else {
      if (answer.value && "value" in answer.value) {
        return answer.value.value;
      }
    }
  }
  if (Sentinels.has(answer)) {
    return Sentinels.get(answer);
  }
  return answer;
}

export function findDependencies (expression) {
  return mergeDepObjs(findDependencies_inner(expression), null);
}
function merge(a, b) {
  return Array.from(new Set((a ?? []).concat(b ?? [])));
}
function mergeDepObjs (a, b) {
  return {
    questions: merge(a?.questions, b?.questions),
    formulaAliases: merge(a?.formulaAliases, b?.formulaAliases),
    reportOutcomes: merge(a?.reportOutcomes, b?.reportOutcomes)
  };
}
function findDependencies_inner (expression) {
  if (!Array.isArray(expression)) {
    if (typeof expression === "string" && (expression.startsWith("?") || expression.startsWith("⁇"))) {
      const scalar = expression.startsWith("?");
      let [_, varKey, defaultValue] = scalar ? expression.split("?") : expression.split("⁇");
      if (varKey.includes("[]")) {
        varKey = varKey.replaceAll("[]", "");
      }
      if (varKey.startsWith("~")) {
        return {reportOutcomes: [varKey.slice(1)]};
      }
      return {questions: [varKey]}
    }
    if (typeof expression === "string" && expression.startsWith("=")) {
      return {formulaAliases: [expression.slice(1)]};
    }
    return null;
  }
  if (expression.length === 1) {
    return null;
  }
  if (expression.length < 4) {
    let infix;
    let left, opKey, right;
    if (expression.length === 3) {
      // assume infix operator with 3 args (left OPERATOR right)
      [left, opKey, right] = expression;
      infix = true;
    } else if (expression.length === 2) {
      // assume prefix operator when 2 args (OPERATOR right)
      [opKey, right] = expression;
      infix = false;
    } else {
      throw new Error("Expressions must be at least 2 entries, got " + expression.join("…"));
    }
    const operator = Operator[opKey];
    if (!operator) {
      console.warn("Could not find operator for key " + opKey);
    }
    const rightDeps = findDependencies(right);
    const leftDeps = infix ? findDependencies(left) : null;
    return mergeDepObjs(leftDeps, rightDeps);
  } else {
    // special handling for possible list expressions
    const opKey = expression[0];
    const operator = Operator[opKey];
    if (!operator) {
      console.warn("Could not find operator for key " + opKey);
    }
    const values = expression.slice(1).map(v => findDependencies(v)).filter(x => x);
    return values.reduce(mergeDepObjs, null);
  }
}

const evaluate = (expression, context, variables) => {
  if (!Array.isArray(expression)) {
    // if we reach an un-array-wrapped value we either use it as a key lookup or
    // take its value as a literal
    if (typeof expression === "string" && expression.length > 1 && (expression.startsWith("?") || expression.startsWith("⁇"))) {
      const scalar = expression.startsWith("?");
      let [_, varKey, defaultValue] = scalar ? expression.split("?") : expression.split("⁇");
      if (varKey.includes("[]") && Number.isInteger(context._currentRow)) {
        if (context?.schema_version === 2 || context?.effectiveSchema === 2) {
          varKey = varKey.replaceAll("[]", `§${context._currentRow}`);
        } else {
          varKey = varKey.replaceAll("[]", `[${context._currentRow}]`);
        }
      }
      if (!(typeof variables === 'object' && varKey in variables)) {
        if (defaultValue?.length > 0) {
          try {
            return JSON.parse(defaultValue);
          } catch (err) {
            console.error("Fatal issue parsing default JSON: " + defaultValue);
            return undefined;
          }
        } else {
          return undefined;
        }
      }
      return Object.assign({key: varKey}, variables[varKey]);
    } else if (expression === "THIS") {
      return context.this;
    } else if (typeof expression === "string" && expression.startsWith("=")) {
      return evaluate(context.formulaAliases[expression.slice(1)], context, variables);
    }
    return expression;
  }
  if (expression.length === 1) {
    // if the expression is a length 1 array, we unwrap it
    // this is mostly to allow us to escape certain hard to produce expressions
    // like array literals and strings that look like special meta values
    const inner = expression[0];
    // since it's a common error, check if this inner looks like it should
    // be a regular formula expression
    if (Array.isArray(inner) && 
      ((inner.length === 3 && Operator[inner[1]])
        || ((inner.length === 2 || inner.length > 3) && Operator[inner[0]]))
    ) {
      console.error(`WARNING: this expression in ${getExecutionContextName(variables)} looks like it should be an expression, but was DOUBLE-WRAPPED in brackets, so the evaluator will treat it as a literal:\n[${JSON.stringify(inner)}]`);
    }
    return expression[0];
  }

  if (expression.length < 4) {
    let infix;
    let left, opKey, right;
    if (expression.length === 3) {
      // assume infix operator with 3 args (left OPERATOR right)
      [left, opKey, right] = expression;
      infix = true;
    } else if (expression.length === 2) {
      // assume prefix operator when 2 args (OPERATOR right)
      [opKey, right] = expression;
      infix = false;
    } else {
      throw new Error("Expressions must be at least 2 entries, got " + expression.join("…"));
    }
    const operator = Operator[opKey];
    if (!operator) {
      throw new Error(`Could not find an operator named "${opKey}". Are you sure your operator is in the right position or that you are using the right one?"`);
    }


    const executeFn = infix ? operator.infix : operator.prefix;
    if (typeof executeFn !== "function") {
      if (opKey === "ANY_OF" && infix) {
        throw new Error(`Found use of "ANY_OF" as an infix operator -- did you mean to use "CONTAINS_ANY"?`);
      }
      throw new Error(`Found an operator "${opKey}" in the expected position, but it was not of the expected type (${infix ? "infix" : "prefix"})`);
    }
    if (typeof operator.prepare === "function") operator.prepare(context, variables, expression);
    const leftValue = infix ? evaluate(left, context, variables) : null;
    const rightValue = evaluate(right, context, variables);

    if (variables[LOG_FLAG]) {
      console.warn(`  for ${infix ? "infix": "prefix"} operator ${opKey}:`);
      if (infix) console.warn(`expression ${left} has value ${quickStr(leftValue)}`);
      console.warn(`expression ${right} has value ${quickStr(rightValue)}`);
    }

    if (operator.requiresVarMetadata === true) {
      return executeFn(context, leftValue, rightValue, variables);
    } else {
      return executeFn(context, extractValue(leftValue, context), extractValue(rightValue, context), variables);
    }
  } else {
    // special handling for possible list expressions
    const opKey = expression[0];
    const operator = Operator[opKey];
    if (typeof operator.prepare === "function") operator.prepare(context, variables, expression);
    if (!operator) {
      throw new Error("Could not find operator for key " + opKey);
    }
    if (!operator.listPrefix) {
      throw new Error(`Received 4+ entry expression, but ${opKey} doesn't have listPrefix fn!`);
    }
    const values = expression.slice(1).map(v => {
      const result = evaluate(v, context, variables);
      return (operator.requiresVarMetadata === true) ? result : extractValue(result, context);
    });
    if (variables[LOG_FLAG]) {
      console.warn(`  for list operator ${opKey}:`);
      values.forEach((v, i) => {
        console.warn(`${i}) expression ${expression[i+1]} has value ${quickStr(v)}`);
      });
    }
    return operator.listPrefix(context, values, variables);
  }
}

function quickStr (obj) {
  if (typeof obj === 'string' || Number.isFinite(obj)) return `${obj}`
  try {
    return JSON.stringify(obj);
  } catch (err) {
    return `«bad json:» ${obj}`;
  }
}
function deepJoin (exp) {
  if (Array.isArray(exp)) {
    return exp.map(i => deepJoin(i)).join(",");
  }
  return `${exp}`;
}

export function evaluator(expression, variables, screenerDef, expectsBoolean = false, alwaysThrow = false, contextualName = "") {
  let result;
  try {
    result = evaluate(expression, screenerDef, {...variables, "##contextualName": contextualName});
    console.log(`evaluated ${contextualName} to get ${quickStr(result)}`); // ${deepJoin(expression)}
  } catch (err) {
    const returnValue = expectsBoolean ? false : null;
    console.error(`Encountered error during evaluation of ${contextualName || "formula"}! ${alwaysThrow ? "Re-throwing" : `Returning ${returnValue}`}`);
    console.error(err);
    console.error(deepJoin(expression));
    if (alwaysThrow) throw err
    return returnValue;
  }
  if (expectsBoolean && typeof result !== "boolean") {
    console.error(`Evaluator was expecting a boolean value from '${contextualName || "this expression"}' but got ${typeof result} -- is the formula configured right?`)
    console.error(result);
    return !!result;
  }
  return result;
}


/// testing area TODO
// const test5plus6 = [5, "PLUS", 6];
// const testCountMinusOne = ["?count", "-", 1];
// evaluator(test5plus6, {});
// evaluator(testCountMinusOne, {count: {selected: [], options: [3]}});
// evaluator(["SUM", 1, 5, 2, 4], {}, {});