import React from "react";

const Note: React.FunctionComponent<{
  note: Array<{ text: string; highlight?: string }>;
}> = ({ note }) => (
  <div className="highlight-box">
    <div className="note">
      <em>
        <strong>Note: </strong>
        {note.map((part, index) => (
          <React.Fragment key={index}>
            {part.text}
            {part.highlight && <kbd style={{marginLeft: "0.25em", marginRight: "0.25em"}}>{part.highlight}</kbd>}
          </React.Fragment>
        ))}
      </em>
    </div>
  </div>
);

export default Note;
