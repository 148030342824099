import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { ALLOW_BACKDROP_TO_CLOSE_MODAL } from "../../../constants/config";
import { useAppSelector } from "../../../utils/hooks";
import { ImageWithFallback } from "../../UI/images/ImageWithFallback";
import { ReportContainer } from "../../Report/ReportContainer";
import { Language } from "../../../constants/locales";
import { evaluator } from "../../../utils/formulas/evaluator";
import { JSObjectDump } from "../../../utils/UtilComponents";

export const StandardModalDialog = ({
  type,
  imgUrl,
  title,
  content,
  contentURL,
  modalPage,
  questionnaire,
  activeQuestionId,
  closeModal,
  setPage,
  currentPage,
  answers,
  maxWidth
}) => {
  const language = useAppSelector((s) => s.patientFlow.language);
  const [filterString, setFilterString] = useState("");
  const [quickSelectIndex, setQuickSelectIndex] = useState(0);

  const [externalContent, setExternalContent] = useState("");
  useEffect(() => {
    if (!contentURL) {
      setExternalContent("");
    } else {
      const urlWithVars = contentURL.replace("{LANG}", language);
      fetch(urlWithVars).then((rsp) => {
        if (rsp.ok) {
          rsp.text().then((textRsp) => setExternalContent(textRsp));
        } else {
          console.warn(`Couldn't load ${contentURL}: ${rsp.statusText}`);
          setExternalContent("");
        }
      });
    }
  }, [contentURL]);

  const filterFn = filterString
    ? (pg, i) => {
        return (
          `${i}`.includes(filterString) ||
          (pg.title && pg.title.toLocaleLowerCase().includes(filterString)) ||
          (Array.isArray(pg.questions) &&
            pg.questions.some((q) =>
              q.toLocaleLowerCase().includes(filterString)
            )) ||
          (pg.loopId && pg.loopId.toLocaleLowerCase().includes(filterString))
        );
      }
    : () => true;

  function specialNavActions(e) {
    if (e.key === "Enter") {
      const shown = questionnaire.pages
        .map((p, originalId) => ({ ...p, originalId }))
        .filter(filterFn);
      if (quickSelectIndex in shown) {
        setPage(shown[quickSelectIndex].originalId);
        closeModal();
      }
    }
    if (e.key === "ArrowUp") {
      setQuickSelectIndex((x) => x - 1);
    }
    if (e.key === "ArrowDown") {
      setQuickSelectIndex((x) => x + 1);
    }
    if (e.key === "Escape") {
      closeModal();
    }
  }

  const [evalResult, setEvalResult] = useState({run: false})
  const [formulaString, setFormulaString] = useState("");
  function runFormula () {
    let formula = {};
    try {
      formula = JSON.parse(formulaString);
    } catch (e) {
      setEvalResult({jsonParseError: e.message});
      return;
    }
    const result = evaluator(
      formula,
      answers,
      questionnaire,
      false,
      false,
      "ad-hoc_dialog_formula"
    );
    setEvalResult(result);
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        background: "rgba(0, 0, 0, 0.1)",
        backdropFilter: "blur(5px)",
        zIndex: 3,
      }}
      onClick={(e) => (ALLOW_BACKDROP_TO_CLOSE_MODAL ? closeModal() : null)}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          maxWidth: maxWidth ?? "600px",
          margin: "auto",
          background: "white",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            width: "100%",
            // height: "100px",
            borderBottom: "1px solid rgba(0,0,0,0.8)",
          }}
        >
          {type === "jump dialog" ? (
            <>
              <h2
                style={{
                  padding: "20px 20px 0px 20px",
                  marginBottom: 0,
                  textAlign: "center",
                }}
              >
                Jump to Page
              </h2>
              <input
                autoFocus
                type="text"
                placeholder="Filter..."
                style={{ margin: "0px 10%", padding: "3px", width: "80%" }}
                onChange={(e) => {
                  setFilterString(e.target.value);
                  setQuickSelectIndex(0);
                }}
                onKeyUp={specialNavActions}
              ></input>
            </>
          ) : null}
          {title ? (
            <h2
              style={{
                paddingTop: "20px",
                textAlign: "center",
              }}
            >
              {title}
            </h2>
          ) : null}
        </div>
        <div
          style={{
            margin: "10px",
            maxHeight: "calc(100% - 120px)",
            overflowY: "scroll",
          }}
        >
          {imgUrl ? (
            <ImageWithFallback src={imgUrl} style={{ width: "100%" }} />
          ) : null}
          {type === "markdown" ? (
            <ReactMarkdown
              children={
                contentURL
                  ? externalContent ?? "Loading..."
                  : content
                  ? `${content}`
                  : "No content!"
              }
              rehypePlugins={[rehypeRaw]}
              components={{}}
              style={{ fontSize: "24px" }}
              className="education resource-dialog popup-education-wrapper body-medium"
            />
          ) : null}
          {type === "introduction" ? (
            modalPage > -1 ? (
              <>
                <h3>{title}</h3>
                <p>{content}</p>
              </>
            ) : (
              <h3 className="text-warning">Introduction failed to load</h3>
            )
          ) : null}
          {type === "jump dialog" ? (
            <ol>
              {questionnaire.pages
                .map((p, originalIndex) => ({ ...p, originalIndex }))
                .filter(filterFn)
                .map((p, pi) => (
                  <li
                    key={p.id + `${pi}`}
                    value={p.originalIndex}
                    className={`${
                      p.type.startsWith("question") ? "" : "text-info"
                    }`}
                    style={{
                      borderRight: p.loopId ? "1px solid gray" : "none",
                      backgroundColor:
                        p.originalIndex === currentPage
                          ? "var(--softened-evergreen)"
                          : pi === quickSelectIndex
                          ? "#ecce3070"
                          : "transparent",
                    }}
                    onClick={() => {
                      setPage(p.originalIndex);
                      closeModal();
                    }}
                  >
                    {p.type} page
                    {p.loopId ? (
                      <em className="float-right badge badge-light">
                        {p.loopId}
                      </em>
                    ) : null}
                    {p.modalType ? (
                      <em className="float-right badge badge-light">
                        {p.modalType}
                      </em>
                    ) : null}
                    {p.type === "title" ? (
                      <span className="badge badge-info float-right">
                        {p.title}
                      </span>
                    ) : null}
                    {p.type === "question-unique" ? (
                      <ul style={{ listStyleType: "square" }}>
                        <li>{p.uniqueType}</li>
                      </ul>
                    ) : null}
                    {["question", "question-hybrid-summary"].includes(
                      p.type
                    ) ? (
                      <ul>
                        {p.questions?.map?.((q, qi) => (
                          <li
                            key={qi}
                            style={
                              q === activeQuestionId
                                ? {
                                    background: "rgba(100, 250, 150, 0.15)",
                                    borderRadius: "12px",
                                    padding: "4px",
                                  }
                                : {}
                            }
                            onClick={(e) => {
                              setPage(p.originalIndex, undefined, qi);
                              closeModal();
                              e.stopPropagation();
                            }}
                          >
                            <span className="badge badge-dark">
                              {q in questionnaire.questions
                                ? questionnaire.questions[q].layout
                                : "NOT FOUND"}
                            </span>{" "}
                            {q}
                          </li>
                        ))}
                        {!Array.isArray(p.questions) ? (
                          <li className="text-danger">
                            MALFORMED QUESTION LIST
                          </li>
                        ) : null}
                      </ul>
                    ) : null}
                  </li>
                ))}
            </ol>
          ) : null}
          {type === "report" ? (
            <ReportContainer
              user={{ name: "debug-preview" }}
              questionnaireDefinition={questionnaire}
              answers={answers}
              submissionStatus={"NOT A SAVED VERSION"}
              language={Language.English}
              acceptAnswer={null}
              forceAllAnswerReport={false}
            />
          ) : null}
          {type === "evaluator" ? (
            <div>
              <textarea onChange={e => setFormulaString(e.target.value)}/>
              <button onClick={() => runFormula()}>Run</button>
              <JSObjectDump obj={evalResult}/>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
