import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { LanguageISO, RegionType } from "../../constants/locales";
import {
  generateQuestionnaireKind,
  QuestionnaireKindString,
  QuestionnaireLoadParams,
  ScreeningType,
} from "../../constants/screenings";
import { AnswerSetSubmissionStatus, GeneralAnswer } from "../../models/answers";
import { Page } from "../../models/pages";
import { QuestionDefinition } from "../../models/questions";
import { BaseUser } from "../../models/users";
import { LoadState } from "../../utils/database";
import { Fulfilled } from "../../utils/promises";
import { createSliceWithThunks } from "../../utils/redux";
import type { RootState } from "../store";

const jsonDataEAdapter = createEntityAdapter<JSONData>();
export const {
  selectById: selectLoadedJSONById,
  // selectIds: ,
  // selectEntities: ,
  // selectAll: ,
  // selectTotal: ,
} = jsonDataEAdapter.getSelectors(
  (state) => (state as RootState).definitions.json
);

const loadedQuestionnairesEAdapter = createEntityAdapter<LoadedQuestionnaire>();
export const {
  selectById: selectQuestionnaireById,
  // selectIds: ,
  // selectEntities: ,
  // selectAll: ,
  // selectTotal: ,
} = loadedQuestionnairesEAdapter.getSelectors(
  (state) => (state as RootState).definitions.questionnaires
);

/**
 * A wrapper for a JSON resource loaded by this slice, containing the JSON body,
 * as well as metadata about the request and response.
 */
export interface JSONData {
  id: string;
  status: LoadState;
}

export interface TemplateReport {
  name: string;
  useForReview?: boolean;
  template: string;
  title?: string;
  templateOptions?: object;
}
export interface GenericReport {
  name: string;
  useForReview?: boolean;
  title: string;
  mainContent: Array<any>;
}

export type CustomReportComponentProps = {
  answers: Record<string, GeneralAnswer>;
  structure: TemplateReport;
  questionnaire: QuestionnaireDefinition;
  submissionStatus: AnswerSetSubmissionStatus;
  user: BaseUser;
  language: LanguageISO;
};

export type ReportStructure = TemplateReport | GenericReport;

export interface QuestionnaireDefinition {
  loading?: boolean;
  screener: ScreeningType;
  locale: {
    language: LanguageISO;
    region: RegionType;
  };
  version: string;
  description: string;
  pageSettings: object;
  formulaAliases: object;
  reports: Array<ReportStructure>;
  _generationMetadata?: object;
  questions: Record<string, QuestionDefinition>;
  pages: Array<Page>;
  loops: object; // depr
}

/**
 * A questionnaire that has been successfully retrieved, validated, and loaded
 * into state as plausibly-runnable.
 */
export interface LoadedQuestionnaire {
  id: string;
  status: LoadState;
  loadStarted: Date;
  // TODO: typing this is gonna be a nightmare, just hold off for now..?
  definition: QuestionnaireDefinition;
}

/**
 * The overall state type for this slice.
 */
export interface DefinitionsState {
  /**
   * An entity adapter of the JSON definition files the app has tried to load
   */
  json: EntityState<JSONData, string>;
  /**
   * An entity adapter of the questionnaire definitions that have been loaded
   */
  questionnaires: EntityState<LoadedQuestionnaire, string>;
  /**
   * Time of the last load operation, or null if no loads have been intiated
   */
  lastLoaded: null | Date;
}
const initialState: DefinitionsState = {
  json: jsonDataEAdapter.getInitialState(),
  questionnaires: loadedQuestionnairesEAdapter.getInitialState({}),
  lastLoaded: null,
};

export const definitionsSlice = createSliceWithThunks({
  name: "definitions",
  initialState,
  reducers: (create) => ({
    loadDefinitionFromJSON: create.asyncThunk(
      // Async payload function as the first argument
      async (
        params: QuestionnaireLoadParams & { ignoreCache?: boolean },
        thunkApi
      ) => {
        const id: QuestionnaireKindString = generateQuestionnaireKind(params);
        const state = thunkApi.getState() as RootState;
        if (
          !params.ignoreCache &&
          state.definitions.questionnaires.ids.includes(id)
        ) {
          const p = Fulfilled(state.definitions.questionnaires.entities);
        }

        return {};
      },
      // An object containing `{pending?, rejected?, fulfilled?, settled?, options?}` second
      {
        pending: (state) => {
          // state.loading = true
        },
        rejected: (state, action) => {
          // state.error = action.payload ?? action.error
        },
        fulfilled: (state, action) => {
          // state.todos.push(action.payload)
        },
        // settled is called for both rejected and fulfilled actions
        settled: (state, action) => {
          // state.loading = false
        },
      }
    ),
  }),
});
