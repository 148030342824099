import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  HIGHLIGHT_BY_SVG_NODES,
  POINTS_ZEROED_TO_SVG_ROOT,
  PredefinedModels,
  SVG_SELECTION_TYPE,
  USE_DYNAMIC_SVG_EMBED,
  USE_SEMITRANSPARENT_CHOICES,
} from "../../../../constants/anatomical-models";
import { IS_PROD } from "../../../../constants/app";
import { DefaultAudioSequence } from "../../../../constants/audio";
import {
  AnatomicalModel,
  AnatomicalRegion,
  AnatomicalView,
  DrawableAnatomicalRegion,
  findRoughCenter,
  getRootViewOfModel,
  PointXY,
  RegionShape,
  SVGRegionSelectorType,
} from "../../../../models/anatomical-models";
import { Answer } from "../../../../models/answers";
import {
  AudioLocation,
  getAudioLocationFromId,
  KeyTranslationIDs,
} from "../../../../models/audio";
import { AnatomicalRegionQuestionDefinition } from "../../../../models/questions";
import {
  AudioSequence,
  audioSlice,
  requestOrRegister,
} from "../../../../store/slices/audio";
import { asArrayWhenScalar, clamp, isObject, oneline } from "../../../../utils";
import { IDed } from "../../../../utils/database";
import {
  useAppDispatch,
  useAppSelector,
  useSize,
} from "../../../../utils/hooks";
import { JSObjectDump } from "../../../../utils/UtilComponents";
import { DynamicSVG } from "../../../UI/images/DynamicSVG";
import { SubpageCount } from "../../QuestionnaireFlow";
import "./selector-styles.css";

const MAX_WIDTH_DESIGN = 675;

const MAX_DISTANCE_ANCHOR_TO_CLICK = 1000; // 200;

interface AnatomicalModelComponentSizing {
  mainColumnWidth: number;
  baseArtWidth: number;
  leftOverhang: number;
  totalHeight: number;
  rightPanelOffset: number;
  truePanelItemHeight: number;
  interPanelItemSpacing: number;
  panelItemHeight: number;
  paddingForShadowSpread: number;
}

function linkPointFor(
  region: AnatomicalRegion,
  isFemale: boolean = true
): PointXY {
  if (region.lineAnchor) {
    if (Array.isArray(region.lineAnchor)) {
      return region.lineAnchor;
    } else {
      const gendered: PointXY | null = isFemale
        ? region.lineAnchor.f
        : region.lineAnchor.m;
      return gendered ?? [0, 0];
    }
  } else if (region.shape) {
    return findRoughCenter(region as DrawableAnatomicalRegion);
  } else {
    console.warn(`No valid line anchor or shape data for ${region.value}`);
    return [0, 0];
  }
}

/**
 * A line made of SVG elements with filled dots placed at both ends.
 */
function LineWithEndPoints({
  a,
  b,
  color = "var(--evergreen)",
  radius = 6,
  opacity = 1,
  additionalClasses = [],
}: {
  a: PointXY;
  b: PointXY;
  color?: string;
  radius?: number;
  opacity?: number;
  additionalClasses?: string[];
}) {
  return (
    <g
      style={{ opacity, pointerEvents: "none" }}
      className={`connector-line ${additionalClasses.join(" ")}`}
    >
      <circle cx={a[0]} cy={a[1]} r={radius} fill={color} />
      <line
        x1={a[0]}
        y1={a[1]}
        x2={b[0]}
        y2={b[1]}
        fill="transparent"
        stroke={color}
        strokeWidth={4}
        // strokeOpacity={0.8}
      />
      <circle cx={b[0]} cy={b[1]} r={radius} fill={color} />
    </g>
  );
}

/**
 * A line made of SVG elements connecting a region within the image with the
 * corresponding choice in the legend area.
 *
 * This component assumes the legend is displayed in the standard right-anchor
 * position, and should not be used with bottom buttons at present.
 */
function LinkLineOf({
  region,
  index,
  sizes,
  active = true,
  isFemale = true,
  hideZeros = true,
}: {
  region: AnatomicalRegion;
  index: number;
  active?: boolean;
  sizes: AnatomicalModelComponentSizing;
  isFemale?: boolean;
  hideZeros?: boolean;
}) {
  const p1: PointXY = linkPointFor(region, isFemale);
  if (p1[0] === 0 && p1[1] === 0 && hideZeros) {
    return null;
  }
  const scaling = sizes.mainColumnWidth / MAX_WIDTH_DESIGN;
  const p1scaled: PointXY = [p1[0] * scaling, p1[1] * scaling];
  if (!Number.isSafeInteger(index)) {
    console.error(
      `Region ${region.value} has no integer index, placing with random vertical nudge`
    );
  }
  const p2: PointXY = [
    sizes.rightPanelOffset + sizes.leftOverhang + sizes.paddingForShadowSpread,
    (index ?? 0) * sizes.panelItemHeight +
      sizes.truePanelItemHeight / 2 +
      sizes.paddingForShadowSpread,
  ];
  return (
    <LineWithEndPoints
      a={p1scaled}
      b={p2}
      opacity={active ? 1.0 : 0.5}
      additionalClasses={active ? [] : ["inactive"]}
      data-scaling={scaling}
    />
  );
}

function HighlightedRegion({
  region,
  color,
  activeRegions,
  sizes,
}: {
  region: DrawableAnatomicalRegion;
  color?: string;
  activeRegions: string[];
  sizes: AnatomicalModelComponentSizing;
}) {
  const active = region.value && activeRegions.includes(region.value);
  const finalColor = color || (active ? "green" : "red");
  switch (region.shape) {
    case RegionShape.Circle:
      return (
        <g>
          <circle
            id={region.value}
            cx={region.center[0]}
            cy={region.center[1]}
            r={region.radius}
            fill={finalColor}
            fillOpacity="0.2"
            stroke={finalColor}
            strokeWidth="2"
          />
        </g>
      );
    case RegionShape.Rectangle:
      return (
        <g>
          <rect
            id={region.value}
            x={region.anchor[0]}
            y={region.anchor[1]}
            width={region.size[0]}
            height={region.size[1]}
            fill={finalColor}
            fillOpacity="0.2"
            stroke={finalColor}
            strokeWidth="2"
          />
        </g>
      );
    case RegionShape.Polygon:
      if (region.asCubicBezier) {
        if (region.points.length % 3 !== 1) {
          alert(
            "Expected points array for cubic bezier poly to satisfy mod(len, 3) = 1"
          );
        }
        let pathString = `M ${region.points[0][0]} ${region.points[0][1]} `;
        region.points.slice(1).forEach((pair, i) => {
          if (i % 3 === 0) {
            pathString += `C ${pair[0]} ${pair[1]}, `;
          } else {
            pathString += `${pair[0]} ${pair[1]}${i % 3 === 1 ? "," : ""} `;
          }
        });
        const weightedCenter = region.points
          .reduce(
            (prev, current, i) => [prev[0] + current[0], prev[1] + current[1]],
            [0, 0]
          )
          .map((c) => c / region.points.length);
        return (
          <g>
            <path
              id={region.value}
              d={pathString}
              fill={finalColor}
              fillOpacity="0.2"
              stroke={finalColor}
              strokeWidth="2"
            />
          </g>
        );
      } else {
        const pointString = region.points
          .map((pair) => `${pair[0]},${pair[1]}`)
          .join(" ");
        const weightedCenter = region.points
          .reduce(
            (prev, current, i) => [prev[0] + current[0], prev[1] + current[1]],
            [0, 0]
          )
          .map((c) => c / region.points.length);
        return (
          <g>
            <polygon
              id={region.value}
              points={pointString}
              fill={finalColor}
              fillOpacity="0.2"
              stroke={finalColor}
              strokeWidth="2"
            />
          </g>
        );
      }
    default:
      return null;
    // return <text x="10" y={20 + Math.random() * 40} fill="cyan">could not draw shape {region.id}</text>;
  }
}

const SELECT_ALL_SIGNAL: string = "«ALL»";
const NO_ANSWER_SIGNAL: string = "«no answer»";
const UNSURE_SIGNAL: string = "«unsure»";

interface AMRProps {
  answer: Answer<any>;
  questionDefinition: AnatomicalRegionQuestionDefinition;
  updateAnswer: (answerData: any) => void;
  complete: () => void;
  goBack: () => void;
  showTrackingInfo: boolean;
  subpage: SubpageCount;
}
function AnatomicalModelResponse({
  answer,
  questionDefinition,
  updateAnswer,
  complete,
  goBack,
  showTrackingInfo,
  subpage,
}: AMRProps) {
  const forceShowAllViews = useAppSelector((s) => s.simulator.exhaustiveLoops);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const activeAudio = useAppSelector((s) => s.audio.activeAudio);

  const sexAnswer = useAppSelector(
    (s) => s.patientFlow.answers.entities["sex assigned at birth"]
  );
  const DEFAULT_TO_FEMALE = true;
  const isFemale = useMemo(() => {
    return sexAnswer && sexAnswer.value
      ? sexAnswer.value.value === "Female"
      : DEFAULT_TO_FEMALE;
  }, [sexAnswer]);
  function resolveImageURLBySex(imgUrl: string): string {
    return imgUrl.replace("{SEX}", isFemale ? "fm" : "ml");
  }

  /**
   * Reference to the current <svg> element within the component. The primary
   * reason we want to track this is to be able to derive the actual on-screen
   * size of the SVG's bounding box, which is needed to use our spiffy drawing
   * functions.
   */
  const svgRef = useRef<SVGSVGElement | null>(null);

  /**
   * Whether to use the "link lines" connecting the legend to the svg regions.
   */
  const drawConnectiveLines = true;

  /**
   * This automatically gets the current size of the container HTMLDivElement
   * (the outermost element of the component's return) and pushes render updates
   * when its sizing changes.
   */
  const [containerRef, containerWidth] = useSize(false, "anatomy-root");

  /**
   * Calculate all of the critical breakpoints based on the most recent width
   * of the containing element. Above 675 pixels, this will be constant, but
   * below that it scales responsively.
   */
  const sizes: AnatomicalModelComponentSizing = useMemo(() => {
    console.log(`Recalculating for ${containerWidth}...`);
    const paddingOfMainColumn = containerWidth < 600 ? 0 : 20 * 2;
    const maximumWidth = MAX_WIDTH_DESIGN;
    const minimumWidth = 200;
    /**
     * useSize is designed to return only numbers (including -1 as a sentinel
     * for an init + failure value) but the structure of the entries returned by
     * the ResizeObserver is a dark art, so it's worth being a little defensive.
     *
     * Then clamp the scaled size to the [min, max] width range.
     */
    const mainColumnWidth = Number.isSafeInteger(containerWidth)
      ? clamp(containerWidth - paddingOfMainColumn, minimumWidth, maximumWidth)
      : maximumWidth;
    const baseArtWidth = 1.5 * mainColumnWidth;
    const leftOverhang = baseArtWidth - mainColumnWidth;
    const truePanelItemHeight = 66;
    const interPanelItemSpacing = 20;
    return {
      mainColumnWidth,
      baseArtWidth,
      leftOverhang,
      totalHeight: baseArtWidth,
      rightPanelOffset: Math.ceil(mainColumnWidth / 2),
      truePanelItemHeight,
      interPanelItemSpacing,
      panelItemHeight: truePanelItemHeight + interPanelItemSpacing,
      paddingForShadowSpread: 10,
    };
  }, [containerWidth]);

  /**
   * The model is the overall collection of images, views, and interactions, and
   * the relationships between them. At present we have a single model of the
   * human body, but in the future we will have varying models that provide
   * different levels of detail or different assortments of options.
   *
   * Note that a response can effectively use a *subtree* of a model by choosing
   * an initialView that isn't the model's root view, but this is the only way
   * a response can tweak the view itself.
   */
  const model: AnatomicalModel = useMemo(() => {
    const m = questionDefinition.model;
    if (typeof m === "string") {
      if (m in PredefinedModels) {
        return PredefinedModels[m];
      } else {
        console.error(`Cannot find model ${m}`);
        return PredefinedModels.EMPTY;
      }
    }
    if (isObject(m, true)) {
      // validate it more? kind of complicated...
      return m as AnatomicalModel;
    }
    return PredefinedModels.default_human;
  }, [questionDefinition]);

  /**
   * The root view is both the initial view shown, as well as the highest point
   * in the model's tree of views that can be accessed. Views that are not
   * descendants of the root view are effectively not able to be shown, and thus
   * their choices can not be selected for the response.
   */
  const rootView = useMemo(() => {
    return questionDefinition.initialView ?? getRootViewOfModel(model);
  }, [questionDefinition, model]);

  /**
   * The currently-selected regions. This is transparently derived from the
   * answer array, but since we use it a lot, it's good to have stored as the
   * empty/null checks + map are kind of a mouthful.
   */
  const activeRegionIds: string[] = Array.isArray(answer?.values)
    ? answer.values.map((v) => v.value)
    : [];

  const hasDecidedChoice: boolean =
    activeRegionIds.length > 0 && !activeRegionIds.includes(NO_ANSWER_SIGNAL);

  /**
   * The view path is the list of all views that are ancestors of the current
   * answer set. IMPORTANT LOGIC: Since we only allow multiple choices within
   * a single leaf view (and answers only *exist* at leaf-level), it suffices
   * to examine a single answer's viewPath to see what our navigation options
   * are. We do note when we find conflicting viewPaths in an answer but we
   * don't do anything about it other than emitting a warning.
   */
  let firstViewPath;
  if (answer && Array.isArray(answer.values) && answer.values.length > 0) {
    firstViewPath = answer.values[0].viewPath;
    if (!Array.isArray(firstViewPath)) {
      console.error(`Answer missing array viewPath! ${answer.values[0]}`);
      firstViewPath = undefined;
    } else {
      const flatVP = firstViewPath.join(",");
      answer.values.forEach((a, i) => {
        if (i === 0) return;
        if (a.viewPath.join(",") !== flatVP) {
          console.error(
            `Found non matching viewPath ${a.viewPath.join(
              ","
            )} at index ${i}\n should be ${flatVP}`
          );
          // note that we aren't bailing out here because at least we can work with what we got
        }
      });
    }
  }
  /**
   * The list of ancestors of the current answer set, used for navigation. When
   * answers are not available we instead just use the root. (Note this case is
   * doubly-enforced, since viewIndices outside the array return rootView as
   * well, see viewId below).
   */
  const viewPath = firstViewPath ?? [rootView];

  /**
   * The view index is our "page number" within the component
   */
  // const [viewIndex, setViewIndex] = useState(0);
  const viewIndex = subpage.questionStage ?? 0;

  /**
   * The viewIdOverride provides a way to force certain views to be displayed
   * regardless of the current answer + index, mostly used for debugging.
   */
  const [viewIdOverride, setViewIdOverride] = useState<string | null>(null);

  /**
   * The viewId is the name of the current view.
   */
  const viewId =
    viewIdOverride ??
    (forceShowAllViews
      ? viewIndex === 0
        ? rootView
        : model[rootView].choices[viewIndex - 1].viewId
      : viewIndex in viewPath
      ? viewPath[viewIndex]
      : rootView);

  /**
   * The view is the subpage within the model response; a specific grouping of
   * image, choices, and interaction regions.
   */
  const view: AnatomicalView = model[viewId] ?? {
    notFound: true,
    viewId,
    choices: [],
  };

  const activeSubview = (view?.choices ?? []).find((region) =>
    viewPath.includes(region.viewId)
  );

  const autoplayAudio = useAppSelector((s) => s.audio.autoplay);
  useEffect(() => {
    (svgRef.current as SVGSVGElement).scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
    if (!autoplayAudio) return;
    if (view.notFound) {
      console.error(`Abandoning audio as current view is broken!`);
      return;
    }
    const locs: AudioLocation[] = [];
    if (questionDefinition.translationId) {
      locs.push(getAudioLocationFromId(questionDefinition.translationId));
    }
    if (questionDefinition.subtextTranslationId) {
      locs.push(
        getAudioLocationFromId(questionDefinition.subtextTranslationId)
      );
    }
    view.choices.forEach((c) => {
      if (c.labelTranslationId !== undefined) {
        locs.push(getAudioLocationFromId(c.labelTranslationId));
      }
    });
    if (view.isLeaf) {
      // there will be an "All of the Above" answer on leaf nodes
      locs.push(getAudioLocationFromId(KeyTranslationIDs.AllOfTheAbove));
    }
    if (locs.length > 0) {
      requestOrRegister(locs, dispatch);
      const sequence: Partial<AudioSequence> & IDed = {
        ...DefaultAudioSequence,
        id: `anatomical-question-${questionDefinition.id ?? answer.key}`,
        files: locs.map((l) => l.filename),
      };
      dispatch(audioSlice.actions.playSequence(sequence));
    }
  }, [view, autoplayAudio]);

  /**
   * The list of region definitions matching the current answer set.
   */
  const activeRegionDefs: AnatomicalRegion[] = Array.isArray(view.choices)
    ? activeRegionIds
        .map((id) => view.choices?.find(({ value }) => value === id))
        .filter((x) => x !== undefined)
    : [];

  /**
   * This is a more rigorous method of finding the active region defs that
   * DOES NOT rely on the assumption that all answers are within a single leaf
   * view. As such, it is overcomplicated for the current model, but may be
   * necessary later, and is kept around for debugging bad answers.
   */
  function findActiveRegionDefs_nonUniversal(): AnatomicalRegion[] {
    if (Array.isArray(answer?.values)) {
      return answer.values
        .map((v, i) => {
          const parentViewId = v.viewPath?.slice(-1)?.[0] ?? "NO VIEW PATH";
          if (parentViewId in model) {
            const parentView = model[parentViewId];
            if (Array.isArray(parentView.choices)) {
              const choice = parentView.choices.find(
                (c) => c.value === v.value
              );
              if (choice) {
                return choice;
              } else {
                console.error(
                  `Answer ${v.value} was not found in view ${parentViewId}`
                );
              }
            } else {
              console.error(
                `Answer supposedly chosen from choiceless view ${parentViewId}`
              );
            }
          } else {
            console.error(
              `Could not find view ${parentViewId} (in answer#${i}'s viewPath) in the current model`
            );
          }
          return null;
        })
        .filter((x) => x !== null);
    } else {
      return [];
    }
  }

  const hiddenChoices: number[] = useMemo(() => {
    if (isFemale) {
      return [];
    }
    const disabledForMale: number[] = [];
    view?.choices?.forEach((c, i) => {
      if (c.onlyFemale) {
        disabledForMale.push(i);
      }
    });
    return disabledForMale;
  }, [view, isFemale]);

  /**
   * Calculate the total height of the choices panel AKA "legend"
   */
  const totalPanelHeight = useMemo(() => {
    return (
      ((view.choices?.length ?? 0) + 1 - hiddenChoices.length) *
        sizes.panelItemHeight +
      2 * sizes.paddingForShadowSpread
    );
  }, [view]);

  /**
   * Determine which regions are actually added and removed based on a toggle
   * action, and return the new list of regions as well as a list of actions
   * taken.
   * @param regionName The region to toggle
   */
  function getRegionActionsForToggle(regionName: string): {
    newActiveRegions: string[];
    actionsTaken: string[];
  } {
    let newActiveRegions = activeRegionIds.slice();
    let actionsTaken: string[] = [];

    if (regionName === SELECT_ALL_SIGNAL) {
      // check if all are on!
      const selectable = view.choices.filter(
        (c) => c.value !== undefined && (isFemale || !c.onlyFemale)
      );
      if (activeRegionIds.length === selectable.length) {
        newActiveRegions = [NO_ANSWER_SIGNAL];
        actionsTaken = ["deselect all"];
      } else {
        newActiveRegions = selectable.map((r) => r.value as string);
        actionsTaken = ["select all"];
      }
    } else if (regionName === UNSURE_SIGNAL) {
      newActiveRegions = [UNSURE_SIGNAL];
      actionsTaken = ["became unsure"];
    } else {
      const index = activeRegionIds.indexOf(regionName);
      if (index > -1) {
        newActiveRegions.splice(index, 1);
        if (newActiveRegions.length === 0) {
          newActiveRegions.push(NO_ANSWER_SIGNAL);
        }
        actionsTaken = [`remove ${regionName}`];
      } else {
        if (
          newActiveRegions.length === 1 &&
          (newActiveRegions[0] === NO_ANSWER_SIGNAL ||
            newActiveRegions[0] === UNSURE_SIGNAL)
        ) {
          newActiveRegions = [];
        }
        newActiveRegions.push(regionName);
        actionsTaken = [`add ${regionName}`];
      }
    }

    return { newActiveRegions, actionsTaken };
  }

  /**
   * Whether all answers in the current (LEAF) view are selected. Used for
   * displaying the highlight status of the "select all" button.
   */
  const allSelected =
    view.isLeaf &&
    view.choices.every(
      (choice) =>
        choice.value === undefined ||
        activeRegionIds.includes(choice.value) ||
        (!isFemale && choice.onlyFemale)
    );

  /**
   * Keep the highlighting inside of the SVG up-to-date with the React state.
   * (This runs every update!)
   *
   * TODO: is there a reason it's useEffect-wrapped though, rather than just
   * being in the component body directly? certainly that seems unlikely
   */
  useEffect(() => {
    /** @type {SVGAElement | null} */
    const svgEl = svgRef.current;
    if (svgEl === null) {
      console.warn("empty ref");
      return;
    }
    if (HIGHLIGHT_BY_SVG_NODES) {
      if (
        SVG_SELECTION_TYPE ==
        SVGRegionSelectorType.GroupIdMatchesRegionHighlightId
      ) {
        const highlightSet = view.isLeaf
          ? allSelected && view.allHighlightRegionId
            ? new Set(asArrayWhenScalar(view.allHighlightRegionId))
            : new Set(
                activeRegionDefs
                  .filter((c) => !!c.highlightRegion)
                  .flatMap((c) => asArrayWhenScalar(c.highlightRegion))
              )
          : activeSubview?.highlightRegion
          ? new Set(asArrayWhenScalar(activeSubview.highlightRegion))
          : new Set();
        /*
          Which regions *could* be dimmed if they aren't in the highlight set.
          We need this as a distinct list because:
              1) the SVG structure has no obvious way to identify what is a
                 region in the first place -- that is, the inverse of the set
                 .highlight-on *relative to our best query selector* includes
                 many other display elements. This could be addressed if we
                 managed to group all of the highlightable regions under one <g>
                 with strict hierarchy, but it's not guaranteed we can always
                 get Figma to maintain the hierarchy we want for complex shapes.
              2) we don't want to apply the highlight-off to "all selected"
                 regions, regions used in other views, or any other special ones
                 because we don't want them showing up (they'll generally be
                 completely invisible, so it's possible the styles used for
                 "dimming" will actually make them more opaque).
        */
        const dimmableRegions = new Set(
          view.choices.flatMap((v) => asArrayWhenScalar(v.highlightRegion))
        );
        console.log({ highlightSet, dimmableRegions });
        let actualHighlightCount = 0;
        document
          .querySelectorAll("#highlight_base svg [id]")
          .forEach((e, k, p) => {
            if (highlightSet.has(e.id)) {
              console.log(`found ${e.id} region at ${k}`);
              e.classList.add("highlight-on");
              if (dimmableRegions.has(e.id)) {
                e.classList.remove("highlight-off");
              }
              actualHighlightCount += 1;
            } else {
              e.classList.remove("highlight-on");
              if (dimmableRegions.has(e.id)) {
                e.classList.add("highlight-off");
              }
            }
          });
        if (actualHighlightCount > 0) {
          document
            .querySelector("#model-container")
            ?.classList.add("has-highlight");
        } else {
          document
            .querySelector("#model-container")
            ?.classList.remove("has-highlight");
        }
      } else {
        console.error(
          `Unknown or deactivated selection type: ${SVG_SELECTION_TYPE}`
        );
      }
    }
  });

  /**
   * Toggle the inclusion of a region, including any additional region state
   * changes due to their exclusionary rules.
   * @param regionName The formal unique name of the region
   * @param representsCurrentView Whether the region is the current view-overall
   */
  function toggleChoiceRegion(regionName: string) {
    const { newActiveRegions, actionsTaken } =
      getRegionActionsForToggle(regionName);
    updateAnswer({
      // id: questionDefinition.id,
      isMulti: true,
      values: newActiveRegions.map((r) => ({
        value: r,
        viewPath: viewPath.slice(),
      })),
    });
  }

  /**
   * Choose a subview from the current selection.
   */
  function selectSubView(viewName: string) {
    if (!(viewName in model)) {
      console.error(
        `Request to select invalid view '${viewName}'! ${
          IS_PROD ? "Rejecting..." : "Allowing for review (QA-only)..."
        }`
      );
      if (IS_PROD) {
        // Note that we abandon this on production. On QA we would allow it so
        // that we could see the "bad" view, which might be an important sign.
        return;
      }
    }
    const newViewPath = viewPath.slice(0, viewIndex + 1);
    newViewPath[viewIndex + 1] = viewName;
    updateAnswer({
      // id: questionDefinition.id,
      isMulti: true,
      values: [{ value: NO_ANSWER_SIGNAL, viewPath: newViewPath }],
    });
  }

  type ImageClickEvent = MouseEvent & {
    target: SVGImageElement | SVGSVGElement;
  };

  /**
   * Attempt to toggle all regions matching the current click. This follows
   * roughly the same logic as
   * @param event
   * @returns
   */
  function toggleMatchingImageRegion(event: ImageClickEvent) {
    if (!svgRef.current) {
      console.error(`SVG reference not available, ignoring click`);
      return;
    }
    if (!Array.isArray(view.choices)) {
      console.warn("Current view has no choices, ignoring click");
      return;
    }
    const targetBox = event.target.getBoundingClientRect();
    const outerSVGBox = svgRef.current.getBoundingClientRect();
    const relativeX =
      event.clientX -
      (POINTS_ZEROED_TO_SVG_ROOT ? outerSVGBox.left : targetBox.left);
    const relativeY =
      event.clientY -
      (POINTS_ZEROED_TO_SVG_ROOT ? outerSVGBox.top : targetBox.top);
    console.log({ relativeX, relativeY });

    const clickStack = document.elementsFromPoint(event.clientX, event.clientY);
    const ids = view.choices.flatMap((v) => v.highlightRegion ?? []);
    const clickedRegionElements = clickStack
      .map((e) => {
        if (ids.includes(e.id)) {
          return e;
        } else if (ids.includes(e.parentElement?.id ?? "~BAD DATA SENTINEL")) {
          return e.parentElement;
        }
        return null;
      })
      .filter((_) => !!_);
    const clickedChoices = view.choices.filter((v) =>
      clickedRegionElements.find((e) => e.id === v.highlightRegion)
    );
    const choicesWithPriority = clickedChoices
      .filter((c) => typeof c.clickPriority === "number")
      .sort((a, b) => a.clickPriority - b.clickPriority);
    // debugger;

    // We have at least one entry with priority values. Entries with priority
    // automatically trump those without, so we will return the entry in this
    // array that has the highest clickPriority.
    if (choicesWithPriority.length > 0) {
      let highestPriorityIndex = 0;
      let highestPriority =
        choicesWithPriority[highestPriorityIndex].clickPriority;
      for (let i = 1; i < choicesWithPriority.length; i++) {
        if (choicesWithPriority[i].clickPriority > highestPriority) {
          highestPriorityIndex = i;
          highestPriority = choicesWithPriority[i].clickPriority;
        }
      }

      if (view.isLeaf) {
        toggleChoiceRegion(choicesWithPriority[highestPriorityIndex].value!!);
      } else {
        selectSubView(choicesWithPriority[highestPriorityIndex].viewId!!);
      }
      return;
    }

    let shortestDistance = Number.MAX_SAFE_INTEGER;
    let shortestIndex = -1;
    const distancesToAnchors = clickedChoices
      .map((c) => linkPointFor(c, isFemale))
      .map(([cx, cy], i) => {
        if (cx === 0 && cy === 0) return Number.MAX_SAFE_INTEGER;
        const d = Math.sqrt(
          Math.pow(cx - relativeX, 2) + Math.pow(cy - relativeY, 2)
        );
        if (d < shortestDistance) {
          shortestDistance = d;
          shortestIndex = i;
        }
        return d;
      });

    // let actionsTaken: string[] = [];
    // let newActiveRegions: string[] = [];
    if (shortestIndex > -1 && shortestDistance < MAX_DISTANCE_ANCHOR_TO_CLICK) {
      // ({ actionsTaken, newActiveRegions } = getRegionActionsForToggle(
      //   view.choices[shortestIndex].value ?? view.choices[shortestIndex].viewId ?? `choice#${shortestIndex}`
      // ));
      if (view.isLeaf) {
        toggleChoiceRegion(clickedChoices[shortestIndex].value!!);
      } else {
        selectSubView(clickedChoices[shortestIndex].viewId!!);
      }
    } else {
      const shortestDistanceText =
        shortestDistance === Number.MAX_SAFE_INTEGER ? "∞" : shortestDistance;
      console.warn(oneline`Click at [${relativeX}, ${relativeY}] was not close 
        enough to any anchor to register. (Closest was index ${shortestIndex} 
        with distance ${shortestDistanceText}`);
    }

    // const closeEnoughRegions = distancesToAnchors.map((d, i): ClickedRegion => ({
    //   id: view.choices[i].value ?? "no value",
    //   value: view.choices[i].value ?? "no value",
    //   weight: d
    // })).filter(r => r.weight < MAX_DISTANCE_ANCHOR_TO_CLICK);
  }

  /**
   * Helper function to determine all of a legend card's classes.
   */
  function classesForChoiceCard(
    choice: AnatomicalRegion,
    asView: boolean
  ): string {
    const disabledClass = choice.disabled ? "card-dark" : "";
    const selectedClass = (
      asView
        ? viewPath.includes(choice.viewId ?? "")
        : activeRegionIds.includes(choice.value ?? "")
    )
      ? "selected"
      : "";
    const audioPlayingClass =
      activeAudio.playing &&
      activeAudio.filename ===
        getAudioLocationFromId(choice.labelTranslationId ?? "").filename
        ? "pulsing"
        : "";
    // choice.viewId && answer?.values?.some((v) => v.ancestors?.includes(choice.viewId)) ? "has-active-descendant" : "";
    return ["card", disabledClass, selectedClass, audioPlayingClass].join(" ");
  }

  /**
   * This method is not intended for use by a user but for programmatically
   * finding appropriate line anchors simply by taking the midpoint of the
   * bounding box for their highlight regions. This can be tweaked after the
   * fact to get a vaue better weighted towards the "ink center" if need be.
   */
  function debug_findApproximateCenterForUnclickableRegions(
    pickLastWhenMulti: boolean = false,
    ignorePresent: boolean = true
  ): void {
    const svgBB = svgRef.current!!.getBoundingClientRect();
    view.choices
      .filter((v) => (!v.lineAnchor || ignorePresent) && v.highlightRegion)
      .forEach((v) => {
        const regionArray = asArrayWhenScalar(
          v.highlightRegion as string | string[]
        );
        const midpoints = regionArray
          .map((highlightId) => {
            const el = document.querySelector(
              `#highlight_base svg [id="${highlightId}"]`
            );
            if (el) {
              const bb = el.getBoundingClientRect();
              return {
                x: Math.round(bb.width / 2 + bb.left - svgBB.left),
                y: Math.round(bb.height / 2 + bb.top - svgBB.top),
              };
            } else {
              return null;
            }
          })
          .filter((x) => x !== null);
        if (midpoints.length > 0) {
          const midpoint =
            midpoints[pickLastWhenMulti ? midpoints.length - 1 : 0];
          console.log(
            `${v.value ?? v.viewId}\n   "lineAnchor": [${midpoint.x}, ${
              midpoint.y
            }]`
          );
        }
      });
  }
  (window as any).__findLineAnchors =
    debug_findApproximateCenterForUnclickableRegions;

  return (
    <>
      <div ref={containerRef} id="anatomical-root">
        <div
          className="main-column"
          style={{
            margin: "-10px auto 0px auto",
            padding: containerWidth < 600 ? "0px" : "0px 20px 20px 20px",
          }}
        >
          {showTrackingInfo ? (
            <table>
              <tbody>
                <tr>
                  <th rowSpan={3}>View</th>
                  <th>
                    <em>path</em>
                  </th>
                  <td>
                    [
                    {viewPath.map((vp) => (
                      <span
                        className="badge"
                        style={{
                          border: "1px solid var(--gold)",
                          borderRadius: 12,
                        }}
                      >
                        {vp}
                      </span>
                    ))}
                    ]
                  </td>
                </tr>
                <tr>
                  <th>
                    <em>index</em>
                  </th>
                  <td>{viewIndex}</td>
                </tr>
                <tr>
                  <th>
                    <em>id</em>
                  </th>
                  <td>{viewId}</td>
                </tr>
                {!answer.values?.length ? (
                  <tr>
                    <th>Answers</th>
                    <th>
                      <em>raw</em>
                    </th>
                    <td>
                      <JSObjectDump obj={answer} />
                    </td>
                  </tr>
                ) : (
                  <>
                    <tr>
                      <th rowSpan={answer.values?.length + 1}>Answers</th>
                    </tr>
                    {answer.values.map((a, i) => (
                      <tr>
                        <th>{i}</th>
                        <td>
                          <JSObjectDump obj={a} />
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          ) : null}

          <div
            style={{
              position: "relative",
              width: `${sizes.baseArtWidth}px`,
              left: `-${sizes.leftOverhang}px`,
              height: `${Math.max(sizes.totalHeight, totalPanelHeight)}px`,
              border: "",
            }}
          >
            <svg
              ref={svgRef}
              id="model-container"
              width={sizes.baseArtWidth}
              height={Math.max(sizes.totalHeight, totalPanelHeight)}
              style={{
                position: "absolute",
                left: `0px`,
                top: "0px",
                background: "transparent" /* "rgba(100, 150, 250, 0.2)" */,
              }}
            >
              <g id="highlight_base" x="0" width={sizes.baseArtWidth}>
                {view.imgUrl ? (
                  USE_DYNAMIC_SVG_EMBED ? (
                    <DynamicSVG
                      prefix={"Questionnaire/Responses/AnatomicalModel/"}
                      src={resolveImageURLBySex(view.imgUrl)}
                      id="base_art"
                      width={sizes.baseArtWidth}
                      height={sizes.totalHeight}
                      onClick={toggleMatchingImageRegion}
                      style={{ pointerEvents: "all" }}
                    />
                  ) : (
                    <image
                      id="base_art"
                      href={resolveImageURLBySex(view.imgUrl)}
                      width={sizes.baseArtWidth}
                      height={sizes.totalHeight}
                      onClick={toggleMatchingImageRegion}
                      pointerEvents={"all"}
                    />
                  )
                ) : (
                  /* Draw an error box */
                  <g>
                    <rect
                      x={sizes.leftOverhang}
                      y="0"
                      width={sizes.mainColumnWidth}
                      height={sizes.totalHeight}
                      fill="lavender"
                    />
                    <text
                      x={sizes.rightPanelOffset / 2}
                      y="30"
                      textAnchor="middle"
                      fill="red"
                    >
                      {view?.notFound
                        ? `View '${viewId}' not found!`
                        : `View '${viewId}' has no imgUrl!`}
                    </text>
                  </g>
                )}
              </g>
              <g
                id="responses"
                transform={`translate(${
                  sizes.rightPanelOffset + sizes.leftOverhang
                } ,0)`}
              >
                <foreignObject
                  width={sizes.mainColumnWidth - sizes.rightPanelOffset}
                  height={totalPanelHeight}
                >
                  <div
                    className={
                      "anatomical-model-legend" +
                      (USE_SEMITRANSPARENT_CHOICES && hasDecidedChoice
                        ? " see-through-unselected"
                        : "")
                    }
                    style={{
                      maxHeight: "",
                      overflowY: "initial",
                      padding: sizes.paddingForShadowSpread,
                    }}
                  >
                    {!Array.isArray(view.choices) ? (
                      <span style={{ background: "var(--salmon-red)" }}>
                        {viewId}
                        <JSObjectDump obj={view} />
                      </span>
                    ) : view.isLeaf ? (
                      <>
                        {view.choices.map((c, ci) =>
                          hiddenChoices.includes(ci) ? null : (
                            <div
                              className={classesForChoiceCard(c, false)}
                              key={c.value ?? `unvalued ${ci}`}
                              id={"LEAF:" + (c.value ?? `unvalued ${ci}`)}
                              onClick={() =>
                                toggleChoiceRegion(c.value ?? `unvalued ${ci}`)
                              }
                            >
                              <div className={`card-body`}>
                                {t(
                                  c.labelTranslationId ?? `no_tid:${c.value}`,
                                  c.label
                                )}
                              </div>
                            </div>
                          )
                        )}
                        <div
                          className={`card ${allSelected ? "selected" : ""} ${
                            activeAudio.playing &&
                            activeAudio.filename ===
                              getAudioLocationFromId(
                                KeyTranslationIDs.AllOfTheAbove
                              ).filename
                              ? "pulsing"
                              : ""
                          }`}
                          onClick={() => toggleChoiceRegion(SELECT_ALL_SIGNAL)}
                        >
                          <div className={`card-body`}>
                            {t("R_GEN284", "All of the Above")}
                          </div>
                        </div>
                      </>
                    ) : (
                      view.choices.map((c, ci) => (
                        <div
                          className={classesForChoiceCard(c, true)}
                          key={c.viewId ?? `unviewided ${ci}`}
                          id={"nonleaf" + (c.viewId ?? `unviewided ${ci}`)}
                          onClick={() =>
                            selectSubView(c.viewId ?? `unviewided ${ci}`)
                          }
                        >
                          <div className={`card-body`}>
                            {t(
                              c.labelTranslationId ?? `no_tid:${c.value}`,
                              c.label
                            )}
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </foreignObject>
              </g>
              {drawConnectiveLines ? (
                <g>
                  {view.choices
                    ?.filter((c, ci) => !hiddenChoices.includes(ci))
                    .map((r, i) =>
                      r.lineAnchor || r.shape ? (
                        <LinkLineOf
                          region={r}
                          index={i}
                          key={r.value}
                          sizes={sizes}
                          active={
                            view.isLeaf
                              ? activeRegionIds.includes(r.value)
                              : viewPath[viewIndex + 1] === r.viewId
                          }
                          isFemale={isFemale}
                        />
                      ) : null
                    )}
                </g>
              ) : null}
            </svg>
          </div>
        </div>
      </div>
      {/* <div style={{ position: "sticky", bottom: 0, left: 0, maxWidth: 250}}>
      {view.isLeaf ? (
        <UncertainButton
          style={{ marginLeft: "2em" }}
          onClick={() => toggleChoiceRegion(UNSURE_SIGNAL)}
          selected={activeRegionIds.includes(UNSURE_SIGNAL)}
        />
      ) : null}
    </div> */}
    </>
  );
}

export default AnatomicalModelResponse;
