import { useEffect, useMemo, useRef, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useMultiTouchHandler, useSize } from "../../../utils/hooks";
import { AudioButton } from "../AudioButton";
import { Page } from "../../../models/pages";
import { SubpageCount } from "../QuestionnaireFlow";
import { QuestionDefinition } from "../../../models/questions";
import { ProgressDots } from "../../UI/ProgressDots";
import "../../../styles/redesign/overlays.css";

const evergreen = "var(--evergreen)";

export type HeaderArtMode = "backdrop" | "bubble" | "no-art";
export interface ButtonState {
  active: boolean;
  visible: boolean;
  error?: boolean;
}
export interface HeaderButtonState {
  next: ButtonState;
  back: ButtonState;
  audio?: ButtonState & { lockToPlaying?: boolean };
  callToAction: string;
}

export const NavigationalOverlay = ({
  page,
  subpage,
  buttonState,
  activeQuestion = {},
  // activeAnswer,
  artOverride,
  mode,
  goBackExit,
  goForward,
  longPress,
  twinPress,
  // hideFade,
  useNeutralColor,
}: {
  page: Page;
  subpage: SubpageCount;
  buttonState: HeaderButtonState;
  mode: HeaderArtMode;
  activeQuestion: Partial<QuestionDefinition>;
  goBackExit: () => void;
  goForward: () => void;
  longPress: (e: MouseEvent) => void;
  twinPress: (e: TouchEvent) => void;
  // hideFade: boolean,
  useNeutralColor?: boolean;
  artOverride?: string | null;
}) => {
  const [failedArts, setFailedArts] = useState<Set<string | undefined>>(
    new Set()
  );
  const [mainArt, setMainArt] = useState<string | undefined>(undefined);
  const [addlArtLeft, setAddlArtLeft] = useState<string | undefined>(undefined);
  const [addlArtRight, setAddlArtRight] = useState<string | undefined>(
    undefined
  );

  const [svgSizingReference, vectorWidth, vectorHeight] = useSize(true);

  function setMainUnlessPastFailure(newArt: string | undefined) {
    if (failedArts.has(newArt)) {
      if (mainArt !== undefined) {
        setMainArt(undefined);
      }
    } else {
      setMainArt(newArt);
    }
  }

  useEffect(() => {
    if (artOverride) {
      if (artOverride !== mainArt) {
        setMainUnlessPastFailure(artOverride);
      }
    } else if (!activeQuestion) {
      // there is nowhere to get art without an active question
      return;
    } else if (activeQuestion.art !== mainArt) {
      setMainUnlessPastFailure(activeQuestion.art);
    }

    if (Array.isArray(activeQuestion?.additionalArt)) {
      // TODO: more advanced directional order logic here!
      // we will eventually support reading settings from the art objects
      // to control their positioning
      if (activeQuestion?.additionalArt[0] !== addlArtLeft) {
        setAddlArtLeft(activeQuestion?.additionalArt[0]);
      }
      if (activeQuestion?.additionalArt[1] !== addlArtRight) {
        setAddlArtRight(activeQuestion?.additionalArt[1]);
      }
    } else {
      setAddlArtLeft(undefined);
      setAddlArtRight(undefined);
    }
  }, [activeQuestion, artOverride]);

  function conditionalGoForward(event: KeyboardEvent) {
    if (event.shiftKey) {
      goForward();
    }
  }

  function artError(error: ErrorEvent) {
    if (typeof mainArt === "string") {
      failedArts.add(mainArt);
      setFailedArts(failedArts);
    }
    setMainArt(undefined);
    console.error(`Error loading art '${mainArt}'`, error);
  }

  const pressTimer = useRef<number | null>(null);
  const LONG_PRESS_MS = 1000;

  function waitForLongPress(event: React.MouseEvent) {
    // React attempts to conserve and reuse event objects after they've been
    // fired, so attempting to pass one into asynchronous code can lead to it
    // not having correct data or more generally being empty+marked as invalid.
    // In this case, our outer long press handler tends to care if it's a long
    // press on which button by looking at the target (yes it's kind of messy
    // and we might want a more expressive component interface here) so we only
    // pass the native browser event instead.
    const { nativeEvent } = event;
    console.warn("mousedown");
    pressTimer.current = window.setTimeout(() => {
      if (typeof longPress === "function") {
        longPress(nativeEvent);
      } else {
        console.warn(
          "Long press received, but longPress parameter was: ",
          longPress
        );
      }
    }, LONG_PRESS_MS);
    event.preventDefault();
  }
  function cancelLongPress(event?: MouseEvent) {
    console.warn("mouseup");
    if (pressTimer.current) {
      clearTimeout(pressTimer.current);
      pressTimer.current = null;
    }
  }
  function handleMobileContextMenu(event: MouseEvent) {
    console.warn("contextmenu");
    event.preventDefault();
    if (typeof longPress === "function") {
      longPress(event);
    }
  }

  /*
  This is a little dubious with the event timing, I'm not convinced it is even
  consistent enough. We should upgrade to a more thorough touch handler.
  */
  const dualTouchHandler = useMultiTouchHandler(2);
  function onBothPressed(e: TouchEvent, ids: string[]) {
    twinPress(e);
    // avoid also triggering the long press handler for back, if any
    cancelLongPress();
    e.stopImmediatePropagation();
    e.preventDefault();
  }

  const ADDL_ART_OFFSET = 90;


  return (
    <>
      <div
        className="backdrop-container"
        style={{
          position: "fixed",
          width: "max(100vw, 320px)",
          height: "25dvh",
          top: "0px",
          zIndex: "2",
          pointerEvents: "none",
        }}
      >
        {mode === "backdrop" ? (
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 1023 283"
            fill="none"
            preserveAspectRatio="none"
            style={{
              minHeight: "125px",
              position: "absolute",
              pointerEvents: "visible",
            }}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0H1023V260.658C1023 260.658 832.786 283 506.705 283C180.623 283 0 260.658 0 260.658V0Z"
              fill="url(#paint0_linear_758_1332)"
            />
            <path
              d="M0 0H1023V260.658C1023 260.658 832.786 283 506.705 283C180.623 283 0 260.658 0 260.658V0Z"
              fill="url(#paint1_linear_758_1332)"
            />
            <path
              d="M0 0H1023V260.658C1023 260.658 832.786 283 506.705 283C180.623 283 0 260.658 0 260.658V0Z"
              fill="var(--evergreen)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_758_1332"
                x1="511.5"
                y1="0"
                x2="511.5"
                y2="283"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FAF45F" />
                <stop offset="1" stopColor="#F8B300" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_758_1332"
                x1="511.5"
                y1="0"
                x2="511.5"
                y2="283"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#95E76E" />
                <stop offset="1" stopColor="#0A9150" />
              </linearGradient>
            </defs>
          </svg>
        ) : null}
        <div
          className="backdrop"
          style={{
            position: "relative",
            margin: "auto",
            height: "max(25dvh, 125px)",
          }}
          ref={svgSizingReference}
        >
          <svg
            width={"100%"}
            height={`max(25dvh, 125px)`}
            style={{
              background: false ? "rgba(0, 150, 250, 0.1)" : "transparent",
              position: "absolute",
            }}
          >
            <defs>
              <filter id="backdropShadow">
                <feDropShadow
                  dx="0"
                  dy="0"
                  stdDeviation="4"
                  floodColor="black"
                  floodOpacity="0.2"
                />
              </filter>
              <linearGradient id="fadeBack" x1="0" x2="0" y1="1" y2="0">
                <stop offset="0%" stopColor="gray" stopOpacity="0" />
                <stop offset="10%" stopColor="white" stopOpacity="0" />
                <stop offset="100%" stopColor="white" stopOpacity="0.9" />
              </linearGradient>
            </defs>
            {/* <rect x="0" y="0" width="100%" height="100%" fill="url(#fadeBack)" style={{pointerEvents: "none", opacity: hideFade ? 0 : 1}}/> */}
            {vectorWidth ? (
              <>
                <g>
                  {mode === "bubble" ? (
                    <circle
                      opacity={mode === "no-art" ? "0" : "1"}
                      cx={
                        mode === "backdrop"
                          ? vectorWidth / 2.0
                          : vectorWidth / 2.0
                      }
                      cy={mode === "backdrop" ? -512 : 48}
                      r={mode === "backdrop" ? 640 : 32}
                      fill={evergreen}
                      filter="url(#backdropShadow)"
                      style={{
                        /* transition: "cx 1s linear, cy 1s linear, r 1s linear, opacity 0.5s linear", */ filter:
                          "url(#backdropShadow)",
                      }}
                    />
                  ) : null}
                  {mainArt ? (
                    <image
                      href={mainArt}
                      opacity={mode === "no-art" ? "0" : "1"}
                      x={
                        mode === "backdrop"
                          ? (vectorWidth - vectorHeight) / 2
                          : vectorWidth / 2 - 28
                      } /* (vectorWidth / 2 - 64) */
                      y={mode === "backdrop" ? 0 : 16 + 4}
                      width={mode === "backdrop" ? vectorHeight : 56} /* 128 */
                      height={mode === "backdrop" ? vectorHeight : 56}
                      stroke="pink"
                      // style={{transition: "x 1s linear, y 1s linear, width 1s linear, height 1s linear, opacity 0.5s linear"}}
                      onError={artError}
                    />
                  ) : mode !== "no-art" ? (
                    <text
                      y="25"
                      x="50%"
                      style={{
                        textAnchor: "middle",
                        opacity: "0.6",
                        fill: "purple",
                      }}
                    >
                      missing image
                    </text>
                  ) : null}
                </g>
                {addlArtLeft || addlArtRight ? (
                  <span style={{ opacity: 0.4, color: "red" }}>
                    Additional art is unsupported!
                  </span>
                ) : null}
              </>
            ) : null}
          </svg>
          {mode === "backdrop" && subpage?.loopPageIndex !== -1 ? (
            <div className="header-loop-dots">
              <ProgressDots
                current={subpage?.loopPageIndex}
                max={Math.max(subpage?.loopPageCount, 1)}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div
        style={{
          position: "fixed",
          width: "max(100vw, 320px)",
          height: "100vh",
          top: "0px",
          zIndex: "5",
          pointerEvents: "none",
        }}
      >
        <div
          style={{ width: "100%", maxWidth: "100dvw", position: "relative" }}
        >
          {buttonState?.back?.visible ?? true ? (
            <div
              className="chrome-btn centered-btn"
              id="backward-button"
              style={{
                float: "left",
                margin: "20px",
                pointerEvents: "visible",
                WebkitUserSelect: "none",
                msUserSelect: "none",
                userSelect: "none",
                WebkitTouchCallout: "none",
                WebkitTapHighlightColor: "transparent"
              }}
              onClick={goBackExit}
              onPointerDown={waitForLongPress}
              onPointerUp={cancelLongPress}
            // {...dualTouchHandler("back", onBothPressed)}
            >
              {buttonState?.back?.icon === "back" ? (
                <img
                  src="/images/chevron.svg"
                  style={{ display: "block"}}
                  alt="navigational back arrow"
                />
              ) : null}
              {buttonState?.back?.icon === "quit" ? <FaTimes /> : null}
            </div>
          ) : null}

          {buttonState?.audio?.visible ?? true ? (
            <AudioButton
              style={{
                zIndex: 5,
                WebkitUserSelect: "none",
                msUserSelect: "none",
                userSelect: "none",
                WebkitTouchCallout: "none",
                WebkitTapHighlightColor: "transparent"
              }}
              lockPlaying={buttonState?.audio?.lockPlaying}
            // divProps={dualTouchHandler("audio", onBothPressed)}
            />
          ) : null}
        </div>
        <div
          className={`chrome-btn chrome-btn-lg centered-btn float-bottom-right ${buttonState.next.active ? "selected" : ""
            }`}
          style={{
            pointerEvents: "fill",
            zIndex: 5,
            // transition: "opacity 1s linear, bottom 1s linear",
            opacity: buttonState.next.visible ? 1 : 0,
            // bottom: buttonState.next.visible ? `` : -84,
            background: buttonState.next.error
              ? "var(--salmon-red)"
              : useNeutralColor
                ? "white"
                : buttonState.next.active
                  ? evergreen
                  : "#CCC",
            WebkitUserSelect: "none",
            msUserSelect: "none",
            userSelect: "none",
            WebkitTouchCallout: "none",
            WebkitTapHighlightColor: "transparent"
          }}
          onClick={(e) =>
            buttonState.next.active ? goForward() : conditionalGoForward(e)
          }
          onPointerDown={waitForLongPress}
          onPointerUp={cancelLongPress}
          onContextMenu={handleMobileContextMenu}
        >
          <img
            src="/images/arrow-right.svg"
            style={{
              filter:
                useNeutralColor || buttonState.next.active ? "invert(1)" : "",
            }}
            alt="navigational forward arrow"
          />
        </div>

        {buttonState?.callToAction ? (
          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100vw",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              className="btn btn-lg btn-light"
              style={{
                padding: "12px 48px",
                marginBottom: "12px",
                minWidth: "280px",
                background: "var(--evergreen)",
                fontWeight: "bold",
                pointerEvents: "fill",
              }}
              onClick={() => goForward()}
            >
              {buttonState.callToAction}
            </div>
          </div>
        ) : null}

        {/* <span className="text-muted" style={{opacity: 0.2}}>mode:{mode}</span> */}
      </div>
    </>
  );
};

/*
   The "additional art bubbles" as specified in the late 2023 redesign docs
   is no longer in active use, but the code is left here since we may reissue
   it at some point?
 */
// {addlArtLeft ? (
//   <g className="aux_art">
//     <circle
//       opacity={mode === "no-art" ? "0" : "1"}
//       cx={vectorWidth / 2.0 - ADDL_ART_OFFSET}
//       cy={mode === "backdrop" ? -512 : 48}
//       r={mode === "backdrop" ? 640 : 32}
//       fill={evergreen}
//       filter="url(#backdropShadow)"
//       style={{
//         transition:
//           "cx 1s linear, cy 1s linear, r 1s linear, opacity 0.5s linear",
//         filter: "url(#backdropShadow)",
//       }}
//     />
//     <image
//       href={addlArtLeft}
//       opacity={mode === "no-art" ? "0" : "1"}
//       x={
//         mode === "backdrop"
//           ? vectorWidth / 2 - 64 - ADDL_ART_OFFSET
//           : vectorWidth / 2 - 28 - ADDL_ART_OFFSET
//       }
//       y={mode === "backdrop" ? 0 : 16 + 4}
//       width={mode === "backdrop" ? 128 : 56}
//       height={mode === "backdrop" ? 128 : 56}
//       stroke="pink"
//       style={{
//         transition:
//           "x 1s linear, y 1s linear, width 1s linear, height 1s linear, opacity 0.5s linear",
//       }}
//     />
//   </g>
// ) : null}
// {addlArtRight ? (
//   <g className="aux_art">
//     <circle
//       opacity={mode === "no-art" ? "0" : "1"}
//       cx={vectorWidth / 2.0 + ADDL_ART_OFFSET}
//       cy={mode === "backdrop" ? -512 : 48}
//       r={mode === "backdrop" ? 640 : 32}
//       fill={evergreen}
//       filter="url(#backdropShadow)"
//       style={{
//         transition:
//           "cx 1s linear, cy 1s linear, r 1s linear, opacity 0.5s linear",
//         filter: "url(#backdropShadow)",
//       }}
//     />
//     <image
//       href={addlArtRight}
//       opacity={mode === "no-art" ? "0" : "1"}
//       x={
//         mode === "backdrop"
//           ? vectorWidth / 2 - 64 + ADDL_ART_OFFSET
//           : vectorWidth / 2 - 28 + ADDL_ART_OFFSET
//       }
//       y={mode === "backdrop" ? 0 : 16 + 4}
//       width={mode === "backdrop" ? 128 : 56}
//       height={mode === "backdrop" ? 128 : 56}
//       stroke="pink"
//       style={{
//         transition:
//           "x 1s linear, y 1s linear, width 1s linear, height 1s linear, opacity 0.5s linear",
//       }}
//     />
//   </g>
// ) : null}
