import {
  createEntityAdapter,
  EntityState,
  isAction,
  Middleware,
  PayloadAction,
} from "@reduxjs/toolkit";
import { storage } from "../../database";
import { AudioInfo, AudioLocation, AudioSequence } from "../../models/audio";
import { isInArrayRange, positiveModulo } from "../../utils";
import { createSliceWithThunks } from "../../utils/redux";
import type { AppDispatch, RootState } from "../store";
import { patientFlowSlice } from "./patient-flow";
import { nanoid } from "nanoid";

export type { AudioInfo, AudioSequence } from "../../models/audio";

const audioSourceAdapter = createEntityAdapter<
  AudioInfo & { src: string },
  string
>({
  selectId: (m) => m.filename ?? "",
});

export interface AudioState {
  audioSources: EntityState<AudioInfo & { src: string }, string>;
  activeAudio: AudioInfo;
  hasPlayedAudio: boolean;
  sequence: null | AudioSequence;
  autoplay: boolean;
}
const initialState: AudioState = {
  audioSources: audioSourceAdapter.getInitialState(),
  activeAudio: {
    filename: "/init/",
  },
  hasPlayedAudio: false,
  sequence: null,
  autoplay: true,
};

const NONCRITICAL_STORAGE_API_ERRORS = ["storage/object-not-found"];

export const audioSlice = createSliceWithThunks({
  name: "audio",
  initialState,
  reducers: (create) => ({
    toggleAutoplay: create.reducer((state, action) => {
      if (state.autoplay) {
        state.autoplay = false;
        state.sequence = null;
        state.activeAudio.playing = false;
      } else {
        state.autoplay = true;
      }
    }),

    /**
     * Play an audio file that already has a registered source
     */
    playKnownAudio: create.reducer((state, action: PayloadAction<string>) => {
      state.sequence = null;
      state.activeAudio = {
        filename: action.payload,
        playing: true,
      };
      const lookup = audioSourceAdapter
        .getSelectors()
        .selectById(state.audioSources, action.payload);
      if (lookup) {
        state.activeAudio.src = lookup.src;
      }
    }),
    /**
     * Play a series of audio files in a predefined order and spacing, possibly
     * looping after reaching the end.
     */
    playSequence: create.reducer(
      (
        state,
        action: PayloadAction<
          Partial<AudioSequence> & Pick<AudioSequence, "id">
        >
      ) => {
        if (!Array.isArray(action.payload.files)) {
          console.error(`playSequence was missing files array!`);
          return;
        }
        state.sequence = {
          loop: false,
          files: [],
          current: 0,
          gap: 1,
          loopGap: action.payload.loopGap ?? action.payload.gap ?? 1,
          ...action.payload,
        };

        const filename = action.payload.files?.[0] ?? "/empty sequence/";
        state.activeAudio = { filename, isInSequence: true, playing: true };
        const lookup = audioSourceAdapter
          .getSelectors()
          .selectById(state.audioSources, filename);
        if (lookup) {
          state.activeAudio.src = lookup.src;
        }
      }
    ),

    nextSequenceItem: create.reducer(
      (state, action: PayloadAction<{ id: string; timerId?: number }>) => {
        if (state.sequence && action.payload.id === state.sequence.id) {
          if ("timerId" in action.payload) {
            state.sequence.timerId = action.payload.timerId;
          }
          state.sequence.current++;
          if (state.sequence.current >= state.sequence.files.length) {
            if (state.sequence.loop) {
              state.sequence.current = 0;
            } else {
              state.sequence = null;
            }
          }
          const filename = state.sequence?.files?.[state.sequence?.current];
          if (filename) {
            state.activeAudio = {
              filename,
              isInSequence: true,
              playing: true,
            };
          } else {
            state.activeAudio = { filename: "/loop terminated/" };
          }
        }
      }
    ),

    stopAudio: create.reducer((state, action: PayloadAction<void>) => {
      state.sequence = null;
      state.activeAudio.playing = false;
    }),

    /**
     * Signal that the active audio has completed
     */
    audioFinished: create.reducer((state, action: PayloadAction<string>) => {
      if (state.activeAudio.filename === action.payload) {
        state.activeAudio.playing = false;
      } else {
        console.warn(
          `Tried to note audio ${action.payload} had finished, but wasn't active`
        );
      }
    }),
    /**
     * Play a sound effect, a hard-coded audio resource that doesn't need to
     * be registered.
     */
    playPredefinedSoundEffect: create.reducer(
      (state, action: PayloadAction<string>) => {
        state.activeAudio.playing = false;
      }
    ),
    /**
     * Update the details of an audio source.
     */
    audioStateChange: create.reducer(
      (state, action: PayloadAction<Partial<AudioInfo>>) => {
        audioSourceAdapter.updateOne(state.audioSources, {
          id: action.payload.filename ?? "",
          changes: action.payload,
        });
        if (action.payload.filename === state.activeAudio.filename) {
          Object.assign(state.activeAudio, action.payload);
          if (
            state.activeAudio.isInSequence &&
            state.sequence &&
            action.payload.status === "error"
          ) {
            // if the update was marking an error and the file is in a sequence,
            // remove it from the sequence
            const i = state.sequence.files.indexOf(state.activeAudio.filename);
            if (i > -1) {
              state.sequence.files.splice(i, 1);
              if (state.sequence.current >= i) {
                if (state.sequence.current === 0) {
                  // briefly back up to the last item so that our next-track
                  // movement brings us to the start
                  state.sequence.current = state.sequence.files.length - 1;
                } else {
                  state.sequence.current--;
                }
              }
            }
            console.warn(
              `Removed ${state.activeAudio.filename} (index ${i}) from the sequence as it had an error during playback.`
            );
          }
        }
      }
    ),
    /**
     * Register the audio source of an audio file whose URL is already known.
     * This is used to prep audio files that are stored in hosting rather than
     * Firebase Storage. The resulting audio will use the same filename and src.
     */
    registerLocalAudio: create.reducer(
      (state, action: PayloadAction<string[]>) => {
        audioSourceAdapter.addMany(
          state.audioSources,
          action.payload.map((filename) => ({ filename, src: filename }))
        );
      }
    ),
    /**
     * Look up a list of filenames in Firebase Storage and retrieve their access
     * URLs, using them to create corresponding audio source entries.
     */
    resolveStorageAudio: create.asyncThunk(
      async (
        {
          filenames,
          ignoreCache = false,
        }: { filenames: string[]; ignoreCache: boolean },
        thunkAPI
      ) => {
        if (!Array.isArray(filenames))
          throw new Error("Illegal argument: filenames should be array!");
        const filtered = ignoreCache
          ? filenames
          : filenames.filter(
              (f) =>
                !(
                  thunkAPI.getState() as RootState
                ).audio.audioSources.ids.includes(f)
            );
        if (filtered.length === 0 && filenames.length > 0) {
          console.log(`All ${filenames.length} audio files were already known`);
          return [];
        }
        let allowedErrorCount = 0;
        const urls: AudioInfo[] = await Promise.all(
          filtered.map((filename) =>
            storage
              .ref()
              .child(filename)
              .getDownloadURL()
              .then(
                (url) => ({
                  filename,
                  src: url,
                }),
                (error) => {
                  if (NONCRITICAL_STORAGE_API_ERRORS.includes(error?.code)) {
                    allowedErrorCount++;
                    return { filename, requestError: error };
                  } else {
                    throw error;
                  }
                }
              )
          )
        );
        console.warn({ audioUrls: urls, allowedErrorCount });
        return urls;
      },
      {
        fulfilled: (state, action: PayloadAction<AudioInfo[]>) => {
          audioSourceAdapter.addMany(
            state.audioSources,
            action.payload.filter((a) => typeof a.src == "string") as Array<
              AudioInfo & { src: string }
            >
          );
        },
      }
    ),
  }),
});

const knownSoundEffects = new Map(
  [
    [
      "TAP",
      "https://firebasestorage.googleapis.com/v0/b/literaseed-5b6b8.appspot.com/o/sound%2Fui_tap-variant-01.wav?alt=media&token=a976620d-5886-42d6-b363-eb94eb70494c",
    ],
    [
      "ALERT",
      "https://firebasestorage.googleapis.com/v0/b/literaseed-5b6b8.appspot.com/o/sound%2Falert_error-02.wav?alt=media&token=8e30e38d-b227-40a3-9c98-e7d9b5869ff0",
    ],
  ].map(([k, src]) => [k, new Audio(src)])
);

const USE_SINGULAR_AUDIO_OBJECT = true;
const SAFARI_AUDIO_INIT_KEY = "SILENT_MP3";

export function getSafariAudioElement(): HTMLAudioElement {
  return document.querySelector(`audio#safari_audio`)!! as HTMLAudioElement;
}

function logKeyAudioEvents(audioElement: HTMLAudioElement, targetArray: any[]) {
  const initTime = Date.now();
  const id = audioElement.id ?? `no_id_${nanoid(6)}`;
  function connect(eventName: string) {
    return (e: Event) => {
      targetArray.push({
        id,
        src: audioElement.src,
        time: Date.now() - initTime,
        eventName,
        event: e,
      });
      if (targetArray.length > 500) {
        targetArray.shift();
      }
    };
  }
  audioElement.oncanplay = connect("canplay");
  audioElement.oncanplaythrough = connect("canplaythrough");
  audioElement.onplay = connect("play");
  audioElement.onplaying = connect("playing");
  audioElement.onended = connect("ended");
  audioElement.onpause = connect("pause");
}

export function prepareSafari() {
  const safariAudioElement = getSafariAudioElement();
  (window as any).__safariAudioLog = [];
  logKeyAudioEvents(safariAudioElement, (window as any).__safariAudioLog);
  safariAudioElement.load();
  safariAudioElement.play();
}

export const audioPlayerMiddleware: Middleware = ({
  getState,
  dispatch,
}: {
  getState: () => RootState;
  dispatch: AppDispatch;
}) => {
  const audioContext = new AudioContext();
  console.log("sample rate", audioContext.sampleRate);
  const unknownFiles = new Set();
  const safariAudioElement = getSafariAudioElement();
  const recentAudios: Map<string, HTMLAudioElement> = new Map([
    [SAFARI_AUDIO_INIT_KEY, safariAudioElement],
  ]);
  const recentKeys: string[] = [SAFARI_AUDIO_INIT_KEY];
  const RECENT_AUDIO_LIMIT: number = USE_SINGULAR_AUDIO_OBJECT ? 1 : 16;
  function addToRecent(src: string, obj: HTMLAudioElement) {
    if (recentAudios.has(src)) return;
    if (recentKeys.length >= RECENT_AUDIO_LIMIT) {
      const toRemove = recentKeys.shift() as string;
      // make sure to pause it before removing it, otherwise we won't be able to
      // stop it later in response to events
      recentAudios.get(toRemove)?.pause();
      recentAudios.delete(toRemove);
    }
    recentKeys.push(src);
    recentAudios.set(src, obj);
  }

  /**
   * Create an <audio> element to host the sound we are trying to play.
   * @param src URI of the audio file
   * @param settings addl props to add to the element (UNUSED, NEEDS RETEST)
   * @returns the <audio> element
   */
  function buildAudio(
    src: string,
    settings?: Partial<HTMLMediaElement>
  ): HTMLAudioElement {
    if (recentAudios.has(src)) {
      return recentAudios.get(src) as HTMLAudioElement;
    }
    let audio: HTMLAudioElement;
    if (USE_SINGULAR_AUDIO_OBJECT) {
      const existing = recentAudios.values().next();
      if (recentAudios.size !== 1 || existing.value === undefined) {
        throw new Error(
          `Non-singleton audio found, cannot chain src transfers for Safari compatibility!`
        );
      }
      audio = existing.value;
      audio.pause();
      audio.src = src;
      audio.currentTime = 0.0;
    } else {
      audio = new Audio(src);
    }
    if (settings !== undefined) {
      // TODO: does this work? I forget if Object.assign trips setters or
      // sidesteps them...
      Object.assign(audio, settings);
    }
    addToRecent(src, audio);
    return audio;
  }

  /**
   * Build an <audio> element and play it, using the provided callbacks when we
   * hit an error or finish playing.
   */
  function playAudioSourceRaw(
    file: AudioInfo & { src: string },
    error: (err: any) => void,
    finished: () => void,
    stopOthers: boolean = true
  ): void {
    const audio = buildAudio(file.src);
    if (stopOthers) {
      recentAudios.forEach((a) => {
        if (a.duration > 0 && !a.paused) {
          a.pause();
        }
      });
    }
    audio.currentTime = 0;
    audio.play().then(() => {
      // we don't use the audio starting as a key threshold -- the store
      // doesn't keep track of which audios are "truly" playing vs had their
      // play initiated as we haven't yet had a need for it
    }, error);
    audio.onended = finished;
  }

  function stopAllAudio() {
    recentAudios.forEach((a) => {
      if (a.duration > 0 && !a.paused) {
        a.pause();
      }
    });
  }

  return (next: any) => (action: any) => {
    const rootState = getState() as RootState;
    const audioState = rootState.audio;
    if (isAction(action)) {
      if (
        audioSlice.actions.toggleAutoplay.match(action) &&
        audioState.autoplay
      ) {
        stopAllAudio();
        if (
          audioState.sequence &&
          Number.isInteger(audioState.sequence?.timerId)
        ) {
          window.clearTimeout(audioState.sequence.timerId);
        }
        next(action);
        return;
      }

      if (audioSlice.actions.stopAudio.match(action)) {
        stopAllAudio();
        if (
          audioState.sequence &&
          Number.isInteger(audioState.sequence?.timerId)
        ) {
          window.clearTimeout(audioState.sequence.timerId);
        }
        next(action);
        return;
      }

      // TODO: capture other nav events once ready,
      if (patientFlowSlice.actions.advancePage.match(action)) {
        stopAllAudio();
        next(action);
        next(audioSlice.actions.stopAudio);
        return;
      }

      if (audioSlice.actions.playKnownAudio.match(action)) {
        const knownFilename: string = action.payload as string;
        const singleStorageFile = audioSourceAdapter
          .getSelectors()
          .selectById(audioState.audioSources, knownFilename);
        if (singleStorageFile) {
          playAudioSourceRaw(
            singleStorageFile,
            (err) => {
              console.error(`Error playing audio ${action.payload}: ${err}`);
              next(
                audioSlice.actions.audioStateChange({
                  filename: action.payload as string,
                  status: "error",
                  playing: false,
                })
              );
            },
            () => {
              next(audioSlice.actions.audioFinished(knownFilename));
            }
          );
        } else {
          console.error(
            `Asked to play an audio file whose URL was not known: '${knownFilename}'`
          );
          unknownFiles.add(action.payload);
        }
        return next(action);
      }

      if (audioSlice.actions.playSequence.match(action)) {
        const originalSequenceId = action.payload.id;
        const firstFileName =
          (action.payload as Partial<AudioSequence>).files?.[0] ?? "--";
        const firstFile = audioSourceAdapter
          .getSelectors()
          .selectById(getState().audio.audioSources, firstFileName);
        if (firstFile) {
          playAudioSourceRaw(
            firstFile,
            (err) => {
              console.error(`Error playing audio ${firstFileName}: ${err}`);
              next(
                audioSlice.actions.audioStateChange({
                  filename: firstFileName,
                  status: "error",
                  playing: false,
                })
              );
              // const seqState = getState().audio.sequence;
              const timerId = window.setTimeout(
                () =>
                  dispatch(
                    audioSlice.actions.nextSequenceItem({
                      id: originalSequenceId,
                      timerId,
                    })
                  ),
                50
              );
            },
            () => {
              next(audioSlice.actions.audioFinished(firstFileName));
              // don't be tempted to use the audioState from the closure, that
              // will have expired by now!
              const activeState = getState().audio.activeAudio;
              const seqState = getState().audio.sequence;
              if (activeState.isInSequence && seqState) {
                if (isInArrayRange(seqState.files, seqState.current)) {
                  const gapLength =
                    seqState.current === seqState.files.length - 1
                      ? seqState.loopGap
                      : seqState.gap;
                  // NOTE THAT WE USE DISPATCH, NOT NEXT... IT'S CLEANER TO HAVE
                  // THE MIDDLEWARE RERUN THIS THAN TRYING TO THREAD...
                  const timerId = window.setTimeout(
                    () =>
                      dispatch(
                        audioSlice.actions.nextSequenceItem({
                          id: originalSequenceId,
                          timerId,
                        })
                      ),
                    gapLength
                  );
                } else {
                  console.error(
                    `Current sequence position ${seqState.current} is invalid for ${seqState.files.length}-file sequence`
                  );
                }
              }
            }
          );
        } else {
          console.error(
            `Asked to play an audio file whose URL was not known: '${firstFileName}'`
          );
          unknownFiles.add(firstFileName);
        }
        return next(action);
      }

      if (audioSlice.actions.nextSequenceItem.match(action)) {
        const originalSequenceId = action.payload.id;
        if (!audioState.sequence) {
          console.error(
            "Cannot advance to next sequence item when sequence is ended"
          );
          return next(action);
        }
        if (audioState.sequence.id !== action.payload.id) {
          console.warn(
            "Not advancing, nextSequenceItem called on outdated sequence"
          );
          return next(action);
        }
        if (
          !isInArrayRange(
            audioState.sequence.files,
            audioState.sequence.current
          )
        ) {
          console.error(
            "Cannot advance to next sequence item when current is outside of the array"
          );
          return next(action);
        }
        const index =
          (audioState.sequence.current + 1) % audioState.sequence.files.length;
        const filename = audioState.sequence.files[index];
        if (!filename) {
          console.error(
            `Either no valid tracks remain, or sequence index has broken. Sequence: ${audioState.sequence.id}#${index}`
          );
          next(
            audioSlice.actions.audioStateChange({
              filename,
              status: "error",
              playing: false,
            })
          );
          return; // we do NOT pass the original action onward!
        }
        const file = audioSourceAdapter
          .getSelectors()
          .selectById(getState().audio.audioSources, filename);
        playAudioSourceRaw(
          file,
          (err) => {
            console.error(`Error playing audio ${filename}: ${err}`);
            next(
              audioSlice.actions.audioStateChange({
                filename,
                status: "error",
                playing: false,
              })
            );
            // const seqState = getState().audio.sequence;
            const timerId = window.setTimeout(
              () =>
                dispatch(
                  audioSlice.actions.nextSequenceItem({
                    id: originalSequenceId,
                    timerId,
                  })
                ),
              50
            );
          },
          () => {
            next(audioSlice.actions.audioFinished(filename));
            // don't be tempted to use the audioState from the closure, that
            // will have expired by now!
            const activeState = getState().audio.activeAudio;
            const seqState = getState().audio.sequence;
            if (activeState.isInSequence && seqState) {
              if (isInArrayRange(seqState.files, seqState.current)) {
                const gapLength =
                  seqState.current === seqState.files.length - 1
                    ? seqState.loopGap
                    : seqState.gap;
                // TODO: do we want to ensure the sequence is the same??
                // NOTE THAT WE USE DISPATCH, NOT NEXT... IT'S CLEANER TO HAVE
                // THE MIDDLEWARE RERUN THIS THAN TRYING TO THREAD...
                const timerId = window.setTimeout(
                  () =>
                    dispatch(
                      audioSlice.actions.nextSequenceItem({
                        id: originalSequenceId,
                        timerId,
                      })
                    ),
                  gapLength
                );
              } else {
                console.error(
                  `Current sequence position ${seqState.current} is invalid for ${seqState.files.length}-file sequence`
                );
              }
            }
          }
        );
      }

      // if (audioSlice.actions.audioFinished.match(action)) {

      // }

      if (audioSlice.actions.playPredefinedSoundEffect.match(action)) {
        const soundEffectName = action.payload as string;
        let soundEffect = knownSoundEffects.get(soundEffectName);
        if (soundEffect !== undefined) {
          soundEffect.play().then(
            () => {},
            (err) =>
              console.error(
                `Error playing GLOBAL audio ${soundEffectName}: ${err}`
              )
          );
        } else {
          console.error(`Unknown global audio name ${soundEffectName}`);
        }
        return next(action);
      }
    } else {
      console.warn(`Audio middleware saw non-action`, action); // this might be normal? thunks are weird
    }
    // default case
    next(action);
  };
};

export function requestOrRegister(
  locationList: AudioLocation[],
  dispatch: AppDispatch
) {
  const locals = locationList.filter((l) => l.isLocal);
  const storages = locationList.filter((l) => !l.isLocal);
  if (locals.length > 0) {
    dispatch(
      audioSlice.actions.registerLocalAudio(locals.map((l) => l.filename))
    );
  }
  if (storages.length > 0) {
    dispatch(
      audioSlice.actions.resolveStorageAudio({
        filenames: storages.map((l) => l.filename),
        ignoreCache: false,
      })
    );
  }
}

export const {
  playPredefinedSoundEffect,
  playKnownAudio,
  registerLocalAudio,
  resolveStorageAudio,
} = audioSlice.actions;

export const audioSourceSelectors = audioSourceAdapter.getSelectors(
  (s: RootState) => s.audio.audioSources
);
export const getAudioSource = audioSourceSelectors.selectById;
