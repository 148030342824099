import { useTranslation } from "react-i18next";
import { NonconformingValueKind } from "../../../models/answers";
import { KeyTranslationIDs } from "../../../models/audio";
import {
  getUserDefinedValue,
  isNonconformingSelected,
  selectNonconformingChoice,
  setUserDefinedValueFor,
} from "../../../models/choices";
import { ResponseLayout } from "../../../models/layouts";
import { safeStringify } from "../../../utils";
import { UncertainButton } from "../../UI/buttons/UncertainResponse";
import AnatomicalModelResponse from "../Responses/AnatomicalModel/AnatomicalModelResponse";
import CalendarDateResponse from "../Responses/CalendarDateResponse";
import FreeTextResponse from "../Responses/FreeTextResponse";
import GridResponse from "../Responses/GridResponse";
import MeasurementResponse from "../Responses/MeasurementResponse";
import NumericResponse from "../Responses/NumericResponse";
import StackResponse, { ListChoiceCard } from "../Responses/StackResponse";

export default function QuestionResponse(props) {
  const { t } = useTranslation();

  const layoutType = props?.questionDefinition?.layout;
  if (props.questionDefinition.error) {
    return (
      <div>
        <h3 className="text-muted">Processing failure:</h3>;
        <pre
          className="p-2 mb-0"
          style={{
            whiteSpace: "pre-wrap",
            background: "#E0E0FF",
            borderRadius: "8px",
          }}
        >
          {safeStringify(props.questionDefinition)}
        </pre>
      </div>
    );
  }

  const nonconforming = props?.questionDefinition?.nonconformingResponses ?? [];
  const indicesOfUnsureishResponses = nonconforming
    .map((ncr, i) => {
      if (isUnsureLike(ncr)) {
        return i;
      } else {
        return -1;
      }
    })
    .filter((i) => i >= 0);

  function acceptNonconforming(item) {
    const updatedAnswerObj = selectNonconformingChoice(
      item,
      props.questionDefinition,
      props.answer
    );
    if (typeof props.updateAnswer === "function") {
      props.updateAnswer(updatedAnswerObj);
    }
  }

  function acceptOtherFreeResponse(event, i) {
    const updatedAnswerObj = setUserDefinedValueFor(
      i,
      event.target.value,
      props.questionDefinition,
      props.answer
    );
    if (typeof props.updateAnswer === "function") {
      props.updateAnswer(updatedAnswerObj);
    }
  }

  // TODO: this is bad practice for update/render lifecycles, hence why we
  // use below as {MainInput()} instead of <MainInput/> -- dfn. should be moved
  // outside this component to resolve issues with latter usage
  function MainInput() {
    const mainAnswer = props?.answer?.isExclusionary ? {} : props?.answer;
    switch (layoutType) {
      case ResponseLayout.GridCards:
        return <GridResponse answer={mainAnswer} {...props} />;
      case ResponseLayout.StackCards:
        return <StackResponse answer={mainAnswer} {...props} />;
      case ResponseLayout.ShortAnswer:
        return <FreeTextResponse {...props} answer={mainAnswer} />;
      case ResponseLayout.Calendar:
        return <CalendarDateResponse answer={mainAnswer} {...props} />;
      case ResponseLayout.Numeric:
        return <NumericResponse answer={mainAnswer} {...props} />;
      case ResponseLayout.Measurement:
        return <MeasurementResponse answer={mainAnswer} {...props} />;
      case ResponseLayout.PseudoMeasurement:
        return <MeasurementResponse answer={mainAnswer} {...props} />;
      case ResponseLayout.AnatomicalRegion:
        return <AnatomicalModelResponse answer={mainAnswer} {...props} />;
      case ResponseLayout.Empty:
        return (
          <span style={{ opacity: 0.2 }}>
            This question is deliberately empty
          </span>
        );
      // removed options
      // case "range":
      //   return <Slider answer={mainAnswer} {...props} />;
      // case "static range":
      //   return <StaticRangePicker answer={mainAnswer} {...props} />;

      // unimplemented below here:
      case ResponseLayout.Dropdown:
        console.warn(`Unimplemented response layout: ${layoutType}`);
        break;
      default:
        console.log(`Unknown response layout specified (${layoutType})`);
    }
    return (
      <div>
        <h3 className="text-muted">Question ({layoutType}) not available</h3>;
        <pre
          className="p-2 mb-0"
          style={{
            whiteSpace: "pre-wrap",
            background: "#E0E0FF",
            borderRadius: "8px",
          }}
        >
          {safeStringify(props.questionDefinition)}
        </pre>
      </div>
    );
  }

  function isUnsureLike(ncr) {
    return (
      ncr.nonconformingKind === NonconformingValueKind.Unsure ||
      ncr.value === "Unsure"
    );
  }

  /**
   * CLUNKY PATCH ALERT!
   * I don't get why this is a problem, but somehow the content of the CSS grid
   * will slowly start to overflow its actual container the more rows there are.
   * Once we get to about 4 rows, this will start to overlap with the
   * nonconforming inputs that may follow. Since we don't have time to analyze
   * carefully, for now we just add extra margin to prevent overlap.
   *
   * As far as I know, the one place this is a major concern is for Supplements.
   */
  const longListFixStyle =
    layoutType === ResponseLayout.GridCards &&
    props.questionDefinition?.choices?.length > 10
      ? { marginTop: "48px" }
      : {};

  return !!nonconforming?.length ? (
    <>
      {MainInput()}
      {/* the width of this needs to be similar to the regular card in stack */}
      <div className="exclusion-btn stack-of-cards" style={longListFixStyle}>
        {nonconforming.map?.((ncr, i) =>
          ncr.nonconformingKind === NonconformingValueKind.UserDefined ? (
            <>
              {t(ncr.descriptionTranslationId, ncr.description)}
              <textarea
                className={
                  layoutType === ResponseLayout.StackCards
                    ? "stack-height"
                    : "full-height"
                }
                value={getUserDefinedValue(props.answer)}
                onChange={(e) => acceptOtherFreeResponse(e, i)}
                placeholder={
                  t(
                    props.questionDefinition.placeholderTranslationId,
                    props.questionDefinition.placeholder
                  ) ||
                  t(
                    KeyTranslationIDs.Placeholder_FreeResponse,
                    "Type your answer here"
                  )
                }
              />
            </>
          ) : isUnsureLike(ncr) ? (
            // Nonconforming responses of the kind Unsure are given a special
            // display behavior by anchoring them to the bottom left of the 
            // screen with a special rendering component. In a perfect world
            // we might decouple the rendering behavior from the kind, but this
            // seems okay for the time being.
            <div
              style={{
                position: "absolute",
                bottom: (indicesOfUnsureishResponses.indexOf(i) ?? 0) * 98,
                left: 0,
                maxWidth: 250,
              }}
            >
              <UncertainButton
                style={{ marginLeft: "2em", marginBottom: "2em" }}
                onClick={() => acceptNonconforming(ncr)}
                selected={isNonconformingSelected(
                  i,
                  props.questionDefinition,
                  props.answer
                )}
                // label={ncr.label}
                art={ncr.art}
                translationId={ncr.labelTranslationId}
                instructionalTranslationId={ncr.instructionalTranslationId}
              />
            </div>
          ) : layoutType === ResponseLayout.GridCards ? null : (
            <ListChoiceCard
              key={ncr.id ?? `EXCLUSIONARY[${i}]`}
              item={ncr}
              handleClick={acceptNonconforming}
              isSelected={isNonconformingSelected(
                i,
                props.questionDefinition,
                props.answer
              )}
              style={{ marginBottom: "1rem" }}
              {...ncr}
            />
          )
        )}
      </div>
    </>
  ) : (
    <>{MainInput()}</>
  );
}
