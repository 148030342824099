/**
 * These flags are boolean constants derived from the environment variables set
 * during the build process.
 */

/**
 * The first three values, in effect, determine what command was run to get the
 * current build.
 *  - IS_DEVELOPMENT will be true iff the command was `npm start`
 *  - IS_TESTING will be true iff the command was `npm test`
 *  - IS_BUILD will be true iff the command was `npm run build[...]`
 * These are controlled by the behavior of create-react-app, NODE_ENV's behavior
 * cannot be overridden or modified.
 */
export const IS_DEVELOPMENT: boolean = process.env.NODE_ENV === "development";
export const IS_TESTING: boolean = process.env.NODE_ENV === "test";
export const IS_BUILT: boolean = process.env.NODE_ENV === "production";

/**
 * The remaining flags are designed to distinguish the "target environment"
 * for a build and are set directly by the commands in package.json. For the
 * `build` command, we specifically require either `build:qa` or `build:prod` to
 * force the user to select between them (same for `admin:`). `start` and `test`
 * meanwhile just default to QA.
 */
export const IS_QA: boolean = process.env.REACT_APP_STAGE === "qa";
export const IS_PROD: boolean = process.env.REACT_APP_STAGE === "prod";
